@import '../../assets/scss/variables.scss';

.agenda-pipelines-wrap-content {
    position: relative;
    .btn-group-events {
        position: absolute;
        right: 0;
        top: -36px;
        display: flex;
        justify-content: flex-end;
        padding-right: 1em;
        .btn-group-events-wrap {
            width: fit-content;
            transform: scale(0.9);
        }

        .MuiButtonBase-root {
            padding: 0;
            margin-right: 0.1em;
        }

    }
    .info-date {
        padding: 0.3rem 0.8rem;
        background-color: $highlight;
        border-radius: $radiusFull;
        color: $white;
    }
}


.pipeline{
    height: inherit;
    margin: 0 .25rem;
    min-width: 0px;
    flex: 1 1 0px;
    border-radius: $radiusMedium;

    .pipe-date{
        width: 90px !important;
        height: 34px !important;
        text-align: center;
        margin: 0 auto .3rem;
        border-radius: $radiusLarge;
        text-transform: capitalize;
        font-family: 'Barlow-Regular', sans-serif !important;
        font-size: 24px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;

        &.current-date{
            background: $secondaryColor;
            color: $white;
            font-size: 16px;
        }
    }

    .pipe{
        width: 100%;
        height: auto !important;
        margin: 0;
        border-radius: $radiusMedium;
        padding: 0 .25rem;
        box-sizing: border-box !important;
        overflow: visible;
        position: relative;
        border: 1px solid transparent;

        &.is-selected {
            border-color: #03cdbf;
        }

        .pipe-title-box{
            height: 50px;
            .add-event-btn{
                color: $secondaryColor;
                padding: 0;
                margin-top: .1rem;
                &:hover{
                    background: transparent;
                }
            }
            .add-event-text{
                text-transform: capitalize;
                font-size: 11px;
                font-family: 'Barlow-Regular', sans-serif;
            }
        }
        .pipe-content{
            height: auto !important;
            .event{
                cursor: pointer;
                margin-left: 2px;
                margin-right: 2px;
                &:last-child {
                    margin-bottom: 1em;
                }
            }
        }
        .more-icon{
            position: absolute;
            right: .75rem;
            top: -2.2rem;
            color: $secondaryColor;
            font-size: 28px;
            cursor: pointer;
        }
        &.is-dark-theme {
            .pipeline-show-past-events {
                border: 1px dashed rgba($gridMainLight, 0.5);
            }
        }
    }

    .pipeline-load-more {
        margin: 1.5em auto 0 auto;
        width: 100%;
        height: 32px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: rgba(#c1c1c1, 0.1);
        border-radius: $radiusSmall;
        &:hover {
            cursor: pointer;
            background-color: rgba(#c1c1c1, 0.25);
        }
        .loader{
            width: 20px !important;
            height: 20px !important;
            color: $gridMainDark;
        }
    }

    .pipeline-show-past-events-wrap-special {
        position: relative;
        .pipeline-show-past-events {
            position: absolute;
            left: 0;
            right: 0;
            z-index: 123;

        }
        .event {
            opacity: 0.05!important;
        }
        .bg-blurry {
            opacity: 0.25;
            position: absolute;
            width: 100%;
            height: 100%;
            z-index: 121;
            background-color: $gridMainDark;
            filter: blur(8px);
            -webkit-filter: blur(8px);
        }
    }

    .pipeline-show-past-events {
        margin: 1em auto;
        width: 80%;
        height: 32px;
        background-color: rgba(#c1c1c1, 0);
        border-radius: $radiusSmall;
        font-size: 0.75rem;
        display: flex;
        text-align: center;
        justify-content: center;
        align-items: center;
        border: 1px dashed rgba($gridMainDark, 0.5);

        &:hover {
            cursor: pointer;
            background-color: rgba(#c1c1c1, 0.25);
        }
    }

}
.popover-overlay{
    background: #11111190;
    position: fixed !important;

    .form{
        border-radius: $radiusRegular !important;
    }
    .cats-form{
        border-radius: $radiusLarge !important;
    }
}

@media only screen and (max-width: 600px){
    .pipeline{
        .pipe{
            .pipe-content{
                .scroller{
                    .scroller-content{
                        height: 100%;
                        .event-card{
                            margin: 1rem 0 0 0;
                        }
                    }
                    .scroller-action{
                        height: 0;
                        display: none;
                    }
                }
            }
        }
    }
    .popover-overlay{
        .form{
            border-radius: $radiusRegular !important;
            width: 70%;
        }
        .cats-form {
            width: 100%;
        }
    }
}

@media only screen and (min-width: 601px) and (max-width: 1080px){
    .popover-overlay{
        .form{
            border-radius: $radiusRegular !important;
            width: 28%;
        }
    }
}

@media only screen and (min-width: 1081px) and (max-width: 1440px){
    .popover-overlay{
        .form{
            border-radius: $radiusRegular !important;
            width: 12.5%;
        }
    }
}

@media only screen and (min-width: 1441px){
    .popover-overlay{
        .form{
            border-radius: $radiusRegular !important;
            width: 11.5%;
        }
    }
}
