@import '../../assets/scss/variables.scss';

.login-page{
    //min-height: calc(100vh - 60px);
    width: 100%;
    position: relative;

    .login-wrap {
        box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
        padding: 1rem 2rem;
    }

    .logo-box{
        height: 110px;
    }
    .forgot-pass{
        color: $white;
        text-transform: capitalize;
        color: $secondaryColor;
    }
}

@media only screen and (max-width: 767.98px){
    .login-page{
        min-height: calc(100vh - 90px);
        .login-wrap {
            width: 90%;

            .logo-box {
                display: none;
            }

        }
    }
}


@media only screen and (max-width: 1199.98px){
    .login-page{
        .login-wrap {

            .logo-box {
                display: none;
            }

        }
    }
}
