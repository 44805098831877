@import '../../../assets/scss/variables.scss';

.groups-form{
    width: 750px !important;
    padding: 1rem;
    box-sizing: border-box !important;
    position: relative;
    height: 650px;

    .form-close-icon{
        position: absolute;
        top: 1rem;
        right: 1.2rem;
        font-size: 24px;
        cursor: pointer;

        &.dark{color: $white;}
        &.light{color: $gridMainDark;}
    }

    .name-box, .user-groups-box, .user-groups-search-box, .groups-color-box, .empty-box{
        min-height: 80px;
        border-radius: $radiusLarge;
        padding: .5rem;
    }
    .name-box{
        .name-input {
            width: calc(100% - 80px);
            height: 46px;
            div{
                height: 46px;
            }
        }
        .icon-input {
            width: 60px;
            height: 60px;
            max-height: 60px;
            border-radius: $radiusLarge;

            &:before, &:after{
                display: none !important;
            }
            fieldset{
                border-color: transparent;
                display: none !important;
                background: transparent !important;
            }
            input+svg{
                display: none;
            }
            span{
                text-transform: capitalize;
                margin: .5rem 0;
            }
            img{
                max-width: 25px;
                float: right;
                &.default{
                    width: 25px;
                }
            }
            &>div{
                height: 60px;
                padding: 0 !important;
                border-radius: $radiusLarge !important;
                div{
                    padding: .75rem 1rem .5rem 1rem !important;
                    background-color: transparent;
                }
            }
            &.dark-select{
                background: $background;
            }
            &.light-select{
                background: $white;
            }
            &:active{
                div{
                    padding: 0 !important;
                }
            }
            svg{
                display: none !important;
            }
        }
    }
    .user-groups-box{
        height: 420px;
        border-radius: $radiusLarge;
        padding: 0 .5rem;

        .title-bar{
            height: 110px !important;
            width: 100%;

            .new-group-box{
                width: 90%;
                height: 50px;
                border: 2px dotted $secondaryColor;
                padding: .5rem;
                border-radius: $radiusRegular;
                margin: .8rem auto 0;

                .add-circle{
                    width: 30px;
                    height: 30px;
                    margin-right: .5rem;
                    cursor: pointer;
                }
                .subtitle{
                    font-size: 13px;
                }
            }
        }
        .user-groups-box-content{
            width: 100%;
            height: 310px;
        }
    }
    .user-groups-search-box{
        height: 420px;
        padding: 0 1rem;
        border-radius: 0;

        .search-box{
            height: 40px;
            margin-bottom: 0rem;
        }
        .content-box{
            height: 400px;
            .box{
                height: 70px;
                .user-card{
                    width: 88%;
                    border-radius: 50px $radiusFull $radiusFull 50px;
                    margin-top: 0;
                    .user-card-content{
                        width: calc(100% - (55px));
                        left: 55px;
                    }
                }
                .check-icon{
                    width: 15px;
                    height: 15px;
                    border-radius: 100%;
                    cursor: pointer;

                    &.green{background: $secondaryColor;}
                    &.grey{background: $gridContentLight;}
                }
            }
            .scroller{
                .scroller-action{
                    height: 30px;
                }
            }
        }
    }
    .groups-color-box{
        min-height: 80px;
        .subtitle{
            margin: 0;
        }
        .colors{
            margin: .5rem 0;
            &.dark{
                .selected-color{
                    &::before{
                        border: 2px solid $white;
                    }
                }
            }
            &.light{
                .selected-color{
                    &::before{
                        border: 2px solid $gridMainDark;
                    }
                }
            }
            label{
                width: 13px;
                height: 13px;
                display: block;
                border-radius: 100%;
                margin: .3rem;
                cursor: pointer;
                padding: 5px;

                &.none{
                    width: 30px;
                    height: 20px;
                    background: $gridContentLight;
                    color: $gridMainDark;
                    border-radius: 15%;
                    font-size: 10px;
                    padding: 3px;
                    margin: 0 .3rem .3rem .3rem;
                    text-transform: capitalize;
                }
            }
            input{
                display: none;
            }

            .selected-color{
                position: relative;
                &:before{
                    position: absolute;
                    content: "";
                    top: -4px; right: 0px; bottom: 0px; left: -4px;
                    width: 21px;
                    height: 21px;
                    border-radius: inherit;
                    border: 2px solid white;
                }
            }
            .selected-btn{
                background: $secondaryColor !important;
                color: $white !important;

                &::before{
                    display: none;
                }
            }
        }
    }
    .empty-box{
        height: 420px;
        h3{
            margin: 0 0 1rem;
            text-transform: capitalize;
            text-align: center;
        }
    }
    .group-form-actions{
        button{
            &:last-child{
                margin-right: 0 !important;
            }
        }
        .cancel-btn{
            background: $gridContentLight;
            color: $gridMainDark;
        }
        .submit-btn{
            background: $secondaryColor;
        }
    }

    .dummy-text {
        display: block;
        margin: 1.25em auto;
        text-align: center;
        color: #c1c1c1;
    }

}

.icon-list-item{
    width: 100%;
    span{
        text-transform: capitalize;
        font-size: 14px;
        margin-right: 1rem;
    }
    .group-icon{
        width: 22px !important;
        height: auto;
        float: right !important;
        display: block;
        &.default{
            width: 15px;
        }
    }
}
