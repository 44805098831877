@import '../../../../../assets/scss/variables.scss';
.is-dark-theme {
  .landing-articles-container {
    .item {
      background-color: $gridMainDark;
    }
  }
}

.landing-articles-container {
  .item {
    position: relative;
    padding: 1rem;
    border-radius: $radiusMedium;

    .link-el {
      cursor: pointer;
    }

    background-color: $gridMainLight;
    display: flex;
    flex-direction: column;

    .cover {
      object-fit: cover;
      height: 250px;
      width: 100%;
    }

    .intro {
      position: relative;
      overflow: hidden;
      max-height: 120px;
      line-height: 25px;
      margin: 0px;

      &:before {
        content: "";
        float: left;
        width: 5px;
        max-height: 120px;
      }

      blockquote, q{
        margin:0;
        padding:0;
        border:0;
        outline:0;
        font-size:100%;
        vertical-align:baseline;
        background:transparent;
        quotes:none;
      }

      blockquote:before, blockquote:after,
      q:before, q:after {
        content:'';
        content:none;
      }

      & > *:first-child {
        float: right;
        width: 100%;
        margin-left: -5px;
      }

      p:not(:first-child) {
        display: none;
      }

      p {
        margin: 0;
      }

      &:after {
        color: red;
        content: "...";
        box-sizing: content-box;
        -webkit-box-sizing: content-box;
        -moz-box-sizing: content-box;
        float: right;
        position: absolute;
        top: 100px;
        right: 0;
        margin-left: -3em;
        padding-right: 5px;
        text-align: right;
      }
    }

  &.item-placeholder {
    min-height: 210px;
    position: relative;

    &.is-dark {
      background-image: url("../../../../../assets/images/pattern-dark-128x128.png");
      background-repeat: repeat;
    }

    &.is-light {
      background-image: url("../../../../../assets/images/pattern-white-128x128.png");
      background-repeat: repeat;
    }

    justify-content: center;
    align-items: center;

    .animation {
      height: 200px;
      transform: translateY(-60px);
    }

    span {
      text-align: center;
      font-family: "Barlow-Bold", sans-serif;
      font-size: 1.25rem;
    }

  }

  figure.phrase-placeholder-component {
    width: 133.333333%; // 100 / 0.75 = 133,333
    transform: scale(0.75);
    display: flex;
    flex-direction: column;
    align-self: center;

    blockquote {
      text-align: center;
      font-family: Barlow-Italic, sans-serif;
      font-size: 1.5rem;
      margin-top: 0;
      margin-bottom: 0;
      padding: 2rem;
    }

    blockquote:before, blockquote:after {
      font-size: 3rem;
    }

    figcaption {
      margin-top: 0.75rem;
      font-family: Barlow-Italic, sans-serif;
      font-size: 0.87rem;
      align-self: flex-end;
    }
  }
}


}
