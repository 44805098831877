@import '../../assets/scss/variables.scss';

.loader-box{
    width: 100%;
    z-index: 10000;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &.without {
        position: relative;
        width: auto;
    }

    .loader {
        border-radius: 50%;
        border-top: 8px solid $secondaryColor;
        width: 100px;
        height: 100px;
        -webkit-animation: spin .8s linear infinite;
        animation: spin .8s linear infinite;
    }

    .logo-box {
      position: absolute;
      -webkit-animation: smoking-gun 6s ease infinite;
      animation: smoking-gun 6s ease infinite;
      opacity: 0;
    }

    .loader-error-message {
        text-align: center;
        position: fixed;
        font-size: 1.25rem;
        font-family: "Barlow-Regular", sans-serif;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        span {
            color: $darkHover;
            z-index: 1001;
        }

        &:after {
            z-index: 1000;
            content: " ";
            width: 100%;
            height: 100%;
            position: fixed;
            background: rgba($highlight, .5);
            opacity: .5;
        }

    }

    .navbar-logo{
        margin-top: 1rem;
    }
}
