@import '../../../assets/scss/variables.scss';


.notification-skeleton{
    margin: 0 1rem 1rem;
    border-radius: 50px 2rem 2rem 50px;
    height: 46px;
    width: auto;
    position: relative;

    &:first-child{
        margin-top: 1rem;
    }

    .circle{
        width: 46px;
        height: 46px;
        position: absolute;
        top: 0;
        left: 0;
        border-radius: 100%;
    }
    .dark{background: $gridContentDark !important;}
    .light{background: $gridContentLight !important;}
}
