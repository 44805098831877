@import '../../../assets/scss/variables.scss';

.user-group-events-agenda {
  margin-top: 1.5em;
  border-radius: 0px;

  &:hover {
    .user-card {
      -webkit-transition: background-color 100ms linear;
      -ms-transition: background-color 100ms linear;
      transition: background-color 100ms linear;
      background-color: $eventHoverLight;
    }
    &.is-dark-theme {
      .user-card {
        background-color: $eventHoverDark;
      }
    }
  }

  &:not(.is-open) {
    &.is-passed {
      opacity: .5;
    }
    &.is-reping {
      .user-card {
        border-color: $highlight;
      }
    }
  }

  &.is-open {
    border-top-left-radius: 33px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    background: #eeeeee;

    .user-card {
      background-color: #eee !important;
    }
  }

  &.is-open {
    &.is-dark-theme {
      background-color: $gridMainDark;

      .user-card {
        background-color: #09090a !important;
      }

      .events {
        .event {
          &:before {
            background-color: transparent; //#283654;
          }

          &:after {
            background-color: #03cdbf;
          }
        }
      }
    }
  }

  .user-card {
    border: 1px solid transparent;
    border-radius: 50px 0 0 50px !important;
    margin-top: 0 !important;
    height: 50px;

    .avatar {
      top: 0 !important;
      position: relative;
    }

    .name {
      font-family: "Barlow-SemiBold", sans-serif;
      font-size: 0.8rem;
      text-align: left;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .events-count {
      font-family: Barlow-Regular, sans-serif;
      font-size: 0.7rem;
    }

    .user-card-content {
      width: calc(100% - (48px + 30px));
      position: relative !important;
      top: 5px !important;
      left: 0px;
      margin-left: 5px;
    }

    .user-display-arrow {
      width: 30px;

      img {
        width: 14px;
        height: 14px;
        margin: auto;
      }
    }
  }

  .events {
    margin-right: 12px;
    margin-left: 32px;

    .event {
      position: relative;
      margin-right: 0 !important;
      margin-left: 0px !important;

      &:first-child {
        &:after {
          top: -10px;
        }
      }

      &:after {
        content: " ";
        z-index: 1;
        position: absolute;
        top: -16px;
        left: -8px;
        width: 2px;
        height: 72px;
        background-color: #ddd;
      }

      &:before {
        content: " ";
        z-index: 1;
        position: absolute;
        width: 12px;
        height: 49px;
        background-color: transparent; //rgb(246, 245, 245);
        border-top-left-radius: 12px;
        border-bottom-left-radius: 12px;
        left: -6px;
        top: 2px;
      }

      &:last-child {
        margin-bottom: 1.40em !important;

        &:after {
          height: 71px;
        }
      }

      .event-card {
        z-index: 2;
        border-radius: 0 !important;
        //border-radius: 12px 0 0 12px !important;

        .ping-tag {
          width: 100%;
        }

        .avatar {
          display: none !important;
        }

        &.destacado {
          &.is-calendar {
            border: 2px solid $highlight;
            &:after {
              left: 0;
            }
          }
        }


        .event-card-content {
          padding-left: 1em;
          width: 100% !important;
          left: 0;
        }

        &.is-premium-poster {
          .event-card-content {
            padding-right: 44px !important;
          }

          .is-notifiable {
            right: 2.8rem !important;
          }
        }

        .overlapGuests {
          width: auto;
          bottom: 10px !important;
          left: -15px !important;
        }
      }
    }
  }
}
