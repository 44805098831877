@import '../../assets/scss/variables.scss';

.image-cropper{
    &.header{
        width: 1050px;
        .crop-box{
            position: relative !important;
            width: 1050px !important;
            height: 400px !important;
        }
    }
    &.profile{
        width: 512px;
        .crop-box{
            position: relative !important;
            width: 500px !important;
            height: 400px !important;
        }
    }
    &.add-event{
        width: 380px;
        .crop-box{
            position: relative !important;
            width: 380px !important;
            height: 680px !important;

            .bg-poster {
                -webkit-filter: blur(5px) contrast(125%) brightness(75%);
                filter: blur(5px) contrast(125%) brightness(75%);
                background-position: center;
                background-repeat: no-repeat;
                background-size: cover;
                height: 100%;
            }

        }
    }
    .crop-actions{
        width: 300px;
        padding: .5rem;
        .crop-btn, .cancel-btn{
            background: $secondaryColor;
            color: $white;
            padding: .25rem 1rem;
            text-transform: capitalize;
            border-radius: $radiusLarge;
        }
        .cancel-btn{
            background: $gridContentLight;
            color: $gridMainDark;
            margin-right: 1rem;
        }
    }
}

@media only screen and (max-width: 600px){
    .image-cropper{
        &.profile{
            width: 100%;
            .crop-box{
                position: relative !important;
                width: 100% !important;
                height: 350px !important;
            }
        }
        &.header{
            width: 100%;
            .crop-box{
                position: relative !important;
                width: 100% !important;
                height: 350px !important;
            }
        }
        &.add-event{
            width: 100%;
            .crop-box{
                position: relative !important;
                width: 100% !important;
                height: 350px !important;
            }
        }
        .crop-box{
            position: relative !important;
            width: 100% !important;
            height: 350px !important;
        }
    }
}

@media only screen and (min-width: 601px) and (max-width: 1080px){
    .image-cropper{
        &.profile{
            width: 384px;
        }
        &.header{
            width: 500px;
        }
        &.add-event{
            width: 380px;
        }
        .crop-box{
            position: relative !important;
            width: 100% !important;
            height: 350px !important;
        }
    }
}
