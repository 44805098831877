@import '../../assets/scss/variables.scss';

.cookies-terms{
    z-index: 9999;
    width: 770px;
    position: fixed;
    bottom: 4%;
    left: 50%;
    transform: translate(-50%, 0);
    padding: 0 1rem;
    box-shadow: 1px 1px 3px rgb(15, 15, 15);
    border-radius: $radiusRegular;

    .content{
        width: calc(100% - 160px);
        .cookies-link{
            font-size: 14px;
            margin: 0 .5rem;
            text-transform: capitalize;

            &.active{
                border: none !important;
            }
        }
    }

    .actions{
        min-width: 160px;
        .submit-btn{
            min-width: 130px;
            max-width: 100%;
            margin: 0 0 0 1rem
        }
    }
}

@media only screen and (max-width: 600px){
    .cookies-terms{
        width: 90%;
        padding: .5rem;
        flex-wrap: wrap;
        bottom: 8%;

        .content{
            width: 100%;
            p{margin: .5rem 0;}
            .cookies-link{
                font-size: 13px;
                font-family: 'Barlow-Medium' !important;
                margin-left: 0;
            }
        }

        .actions{
            justify-content: center;
            width: 100%;
            .submit-btn{
                margin: 0 !important;
            }
        }
    }
}

@media only screen and (min-width: 601px) and (max-width: 1080px){
    .cookies-terms{
        width: 600px;
        padding: 0 .5rem;

        .content{
            p{margin: .5rem 0;}
            .cookies-link{
                font-size: 13px;
                font-family: 'Barlow-Medium' !important;
                margin-left: 0;
            }
        }

        .actions{
            .submit-btn{
                margin: 0 !important;
            }
        }
    }
}
