@import '../../../assets/scss/variables.scss';


.group-skeleton{
    margin: 0 .5rem 0 0;
    width: 200px !important;
    height: 240px !important;
    border-radius: $radiusRegular;
    position: relative;

    .rect{
        width: 56px;
        height: 56px;
    }
    .dark{background: $gridContentDark;}
    .light{background: $gridContentLight;}
}