@import '../../../assets/scss/variables.scss';

.user-bio{
    *{font-family: 'Barlow-Regular' !important;}
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    width: 100%;

    .expansion{
        width: 100%;
        margin: 0 0 .5rem 0;
        background-color: transparent;
        &>div{
            min-height: 40px;
        }
        .button{
            background: transparent !important;
            text-align: center !important;
            min-height: fit-content;
            color: $secondaryColor;
            display: flex;
            flex-direction: row;
            justify-content: center;
            margin: 0;
            p{
                text-align: center !important
            }
        }
        .data{
            padding: 8px;
        }
    }

    .profile-description{
        width: 50%;
        box-sizing: border-box !important;
        .personal-data{
            width: 25%;
            min-height: fit-content;
            display: flex;
            flex-wrap: wrap;
            .location, .dob, .website, .website-wrap {
                width: 100%;
                text-align: right;
                text-transform: capitalize;
                margin: .1rem;
                font-size: 14px;
                text-overflow: ellipsis !important;
                white-space: nowrap !important;
                overflow: hidden !important;

                display: flex;
                align-items: center;
                justify-content: flex-end;

                .icon {
                    margin-left: 0.25rem;
                }
            }
            .website-wrap {
                display: flex;
                flex-wrap: nowrap;
                .website {
                    display: flex;
                    flex-wrap: nowrap;
                }
            }
            .website{
                text-transform: lowercase;
                cursor: pointer;
                text-align: right;
                &:hover{
                    color: $secondaryColor;
                    text-decoration: underline;
                }
            }
            .location {
                white-space: break-spaces !important;
            }
        }
        .bio{
            width: 70%;
            min-height: 150px;
            .biography{
                margin: 0 .3rem .3rem;
                font-size: 14px;
            }
        }
    }
    .user-interests{
        width: 30%;
        margin-top: .5rem;
    }

    .top-categories{
        width: 30%;
        margin-top: .5rem;
    }
}

@media only screen and (max-width: 600px){
    .user-bio{
        margin-bottom: .5rem;
        .profile-description{
            width: 100%;
            .personal-data{
                width: 100%;
                min-height: fit-content;
                display: flex;
                flex-wrap: wrap;
                .location, .dob, .website, .website-wrap {
                    width: 100%;
                    text-align: left;
                    text-transform: capitalize;
                    margin: .1rem;
                    font-size: 14px;
                    text-overflow: ellipsis !important;
                    white-space: nowrap !important;
                    overflow: hidden !important;
                    flex-direction: row-reverse;
                }
                .website-wrap {
                    display: flex;
                    flex-wrap: nowrap;
                    .website {
                        display: flex;
                        flex-wrap: nowrap;
                    }
                }
                .website{
                    text-transform: lowercase;
                    cursor: pointer;
                    &:hover{
                        color: $secondaryColor;
                        text-decoration: underline;
                    }
                }
            }
            .bio{
                width: 100%;
                min-height: 50px;
                height: auto;
                .biography{
                    margin: 0 .3rem .3rem;
                    font-size: 14px;
                }
            }
        }
        .user-interests{
            width: 100%;
            margin-bottom: 1rem;
        }
    }
}

@media only screen and (min-width: 601px) and (max-width: 1080px) {
    .user-bio{
        width: 100%;
        //height: 370px;
        flex-direction: column;
        justify-content: flex-end;
        .profile-description{
            width: 95%;
            justify-content: flex-end;
            margin-bottom: .5rem;
            align-items: flex-end;
            flex-direction: column;
            .personal-data{
                width: 100%;
                margin-right: .5rem;
                display: flex;
                flex-wrap: wrap;
                .location, .dob, .website, .website-wrap{
                    width: 100%;
                    text-align: right;
                    text-transform: capitalize;
                    margin: .1rem;
                    font-size: 14px;
                    text-overflow: ellipsis !important;
                    white-space: nowrap !important;
                    overflow: hidden !important;
                }
                .website-wrap {
                    display: flex;
                    flex-wrap: nowrap;
                    .website {
                        display: flex;
                        flex-wrap: nowrap;
                    }
                }
                .website{
                    text-transform: lowercase;
                    cursor: pointer;
                    &:hover{
                        color: $secondaryColor;
                        text-decoration: underline;
                    }
                }
            }
            .bio{
                width: 100%;
                min-height: 80px;
                .biography{
                    margin: 0 .3rem .3rem;
                    font-size: 14px;
                }
            }
        }
        .user-interests{
            width: 95%;
            margin-bottom: .5rem;
        }
    }
}
