@import '../../../assets/scss/variables.scss';

.contact-form{
    .form-box{
        width: 500px;
        .name, .email, .subject, .message{
            border-radius: $radiusLarge !important;
            width: 100% !important;
            margin: 10px;
            border-color: $secondaryColor;

            label{
                color: $secondaryColor;
                letter-spacing: 1.3px;
                font-size: 14px !important;
            }
            label+div{
                border-radius: $radiusLarge;
                height: 50px;
            }
            fieldset{
                border-color: $secondaryColor !important;
            }
            input{
                color: $secondaryColor;
                font-size: 14px !important;
            }
        }
        .subject, .message{
            width: 100% !important;
        }
        .message{
            height: 200px;
            label+div{
                border-radius: $radiusLarge;
                height: 200px;
                padding: 1rem;
            }
            textarea{
                color: $white;
                height: 100%;
            }
        }

        .submit-btn{
            min-width: 150px;
            display: block !important;
            margin: 1.5rem auto !important;
        }
    }
    .wrap-tos {
        display: flex;
        align-items: center;
        margin-bottom: 0.5em;
        font-size: 0.75rem;
        width: 100%;
        justify-content: center;
        color: $textSecondaryColor;
        a {
            color: $secondaryColor;
        }
        &.is-error {
            color: $red;
        }
        .error {
            color: $red;
            margin-left: 6px;
            font-size: 0.75rem;
        }
    }
}

@media only screen and (max-width: 600px){
    .contact-form{
        .form-box{
            width: 100%;
            padding: .5rem;
        }
    }
}
