@import './app.scss';
@import './fonts.scss';
@import './variables.scss';
@import '../../../node_modules/animate.css/animate.css';
@import './archivements_modal';

*{
    box-sizing: border-box;
}
body{
    margin: 0;
    padding: 0;
    font-size: 16px;
    font-family: 'Barlow-Regular';
}
.box-title, .subtitle{
    font-size: 24px;
    font-family: 'Barlow-Medium' !important;
    text-transform: uppercase;
    width: 100% !important;
    text-align: center;
    display: block;
    clear: both;
}
.subtitle{
    font-size: 16px;
}
.link{
    font-family: 'Barlow-Medium' !important;
    font-size: 15px !important;
    text-transform: uppercase !important;
    color: $white !important;
}
.pointer{
    cursor: pointer;
}
input{
    font-family: 'Barlow-Regular' !important;
    font-size: 14px !important;
}
p{
    font-family: 'Barlow-Regular' !important;
    font-size: 14px !important;
}

.green{background: #84D754;}
.sky{background: #54D7D3;}
.blue{background: #5491D7;}
.purple{background: #9654D7;}
.violet{background: #D754B4;}
.red{background: #D75454;}
.orange{background: #D79E54;}
.yellow{background: #C6D754;}
.none{background: transparent;}

.MuiPickersCalendarHeader-dayLabel{
    color: #bbbbbb !important;
}


// form input fields centralized styling
.custom-input {
    margin-bottom: 24px !important;
    label {
        position: absolute;
        top: -10px;
    }
    label + .MuiInput-formControl {
        margin: 0;
    }

    &.input-textarea {
        //padding-left: 1em;
        & > div {
            font-family: Barlow-Light, sans-serif !important;
        }
    }

    fieldset {
        top: 0px;
        legend {
            display: none;
        }
    }

    &.select-input {
     & > div {
         padding: 0 !important;
         & > div {
             padding: 0 !important;
             padding-right: 1rem !important;
             padding-left: 1rem !important;
             padding-top:1rem !important;
         }
     }
        svg {
            top: calc(50% - 10px);
            right: 10px !important;
        }
    }

    &.select-input > * {
        font-family: Barlow-Light, sans-serif !important;
    }

    & .MuiAutocomplete-input {
        padding: 0 !important;
        padding-left: 0.5rem !important;
    }

}

.custom-input-2 {
    &.MuiInput-underline {
        &:before {
            visibility: hidden;
        }
        &:after {
            visibility: hidden;
        }
    }

    fieldset {
        top: 0px;
        legend {
            display: none;
        }
    }
}

.input-dark{
    width: 100%;
    margin: 0;
    background: $background;
    border-radius: $radiusMedium;
    height: 40px;

    &.grey{background: $gridMainDark;}

    label, label.Mui-disabled {
        color: $secondaryColor;
    }
    fieldset{
        border-color: transparent;
    }
    input{
        color: $gridContentLight;
        padding: 0 1rem;
        &.Mui-disabled {
            opacity: 0.5 !important;
            cursor: not-allowed;
        }
    }
    div{
        border-radius: $radiusMedium;
        height: 40px;
        &:before, &:after{
            display: none !important;
        }
    }
    svg{
        color: $secondaryColor;
        font-size: 18px;
    }
    .MuiFormHelperText-root.Mui-error {
        position: absolute;
        bottom: -17px;
        font-size: 0.7rem!important;
    }
    p{display: none;}
}
.input-light{
    width: 100%;
    margin: 0;
    background: $white;
    border-radius: $radiusMedium;
    height: 40px;

    &.grey{background: $gridMainLight !important;}

    //label{color: $gridContentDark;}

    fieldset{
        border-color: transparent;
    }

    input{
        color: $gridMainDark;
        padding: 0 1rem;
        &.Mui-disabled {
            opacity: 0.5 !important;
            cursor: not-allowed;
        }
    }
    div{
        border-radius: $radiusMedium;
        height: 40px;
        &:before, &:after{
            display: none !important;
        }
    }
    svg{
        color: $secondaryColor;
        font-size: 18px;
    }
    .MuiFormHelperText-root.Mui-error {
        position: absolute;
        bottom: -17px;
        font-size: 0.7rem!important;
    }
}
.input-textarea{
    height: 150px;
    border-radius: $radiusRegular !important;
    textarea{
        font-size: 14px;
    }
    label{
        font-size: 14px;
    }
    div{
        border-radius: $radiusMedium;
        height: 150px;
        max-height: 150px !important;
        &:before, &:after{
            display: none !important;
        }
    }
}
.select-input{
    div{
        border-radius: $radiusMedium;
        height: 40px;
        max-height: 40px !important;
        padding-left: .5rem !important;
        padding-top: .6rem !important;
        padding-bottom: 0 !important;
        padding-right: 0 !important;

        &:before, &:after{
            display: none !important;
            border: none !important;
        }
    }
    &:active{
        div{
            padding: 0 !important;
        }
    }
}

.tag-select-dark{
    width: 100%;
    margin: 0;
    background: $backgroundDark;
    border-radius: $radiusMedium;
    border: 1px solid transparent;

    &.owner{background: $gridMainDark !important;}

    &:hover {
        &:not(.Mui-focused) {
            border-color: $white;
        }
        &.Mui-focused {
            border-width: 2px;
            border-color: $highlight;
        }
    }
}

.tag-select-light{
    width: 100%;
    margin: 0;
    background: $white;
    border-radius: $radiusMedium;
    &.owner{background: $gridMainLight !important;}
    &:hover {
        &:not(.Mui-focused) {
            border-color: $textColor;
        }
        &.Mui-Focused {
            border-width: 2px;
            border-color: $highlight;
        }
    }
}

.city-popper {
    &.city-popper-dark {
        .empty, .loading {
            color: white !important;
        }
        .textSecondary {
            color: white !important;
        }
    }
}

.quick-tag-select-dropdown{
    margin-left: 5px;
    .static{
        color: inherit;
    }
    ul{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: flex-start;
        min-height: 20px !important;
        padding: .3rem;
        li{
            width: fit-content !important;
            display: inline-block !important;
            width: fit-content !important;
            padding: .1rem .25rem !important;
            margin: 0 .1rem 0 0 !important;
            font-size: 11px;
            border-radius: $radiusSmall;

            .part-list-option{
                display: flex;
                align-items: center;
                justify-content: center;
                .color-box{
                    width: 8px;
                    height: 8px;
                    background: $highlight;
                    margin-right: 3px;
                    border-radius: 100%;

                    &.green{background: #84D754;}
                    &.sky{background: #54D7D3;}
                    &.blue{background: #5491D7;}
                    &.purple{background: #9654D7;}
                    &.violet{background: #D754B4;}
                    &.red{background: #D75454;}
                    &.orange{background: #D79E54;}
                    &.yellow{background: #C6D754;}
                    &.none{background: #dddddd;}
                }
                .avatar {
                    width: 14px;
                    height: 14px;
                    margin-right: 3px;
                    img {
                        width: 14px;
                        height: 14px;
                        border-radius: 100%;
                    }
                }
                .text{
                    width: fit-content !important;
                    height: 14px;
                    font-family: 'Barlow-Regular';

                    &.bold{
                        font-family: 'Barlow-Medium';
                    }
                }
            }
        }
    }
}

.full-tag-select-dropdown{
    margin-left: 5px;
    .static{
        color: inherit;
    }
    ul{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: flex-start;
        min-height: 20px !important;
        padding: .3rem;
        li{
            width: fit-content !important;
            display: inline-block !important;
            padding: .1rem .25rem !important;
            margin: 0 .1rem 0 0 !important;
            font-size: 12px;
            border-radius: $radiusSmall;
            .part-list-option{
                display: flex;
                align-items: center;
                justify-content: center;
                .color-box{
                    width: 8px;
                    height: 8px;
                    margin-right: 3px;
                    border-radius: 100%;
                    background: $highlight;

                    &.green{background: #84D754;}
                    &.sky{background: #54D7D3;}
                    &.blue{background: #5491D7;}
                    &.purple{background: #9654D7;}
                    &.violet{background: #D754B4;}
                    &.red{background: #D75454;}
                    &.orange{background: #D79E54;}
                    &.yellow{background: #C6D754;}
                    &.none{background: transparent;}
                }
                .avatar {
                    width: 20px;
                    height: 20px;
                    margin-right: 3px;
                    img {
                        width: 20px;
                        height: 20px;
                        border-radius: 100%;
                    }
                }
                .text{
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: fit-content !important;
                    height: 20px !important;
                    font-family: 'Barlow-Regular';

                    &.bold{
                        font-family: 'Barlow-Medium';
                    }
                }
            }
        }
    }
}

.upload-btn{
    display: block;
    min-width: 80px;
    height: 30px;
    font-size: 12px;
    padding: .5rem .75rem;
    margin: 0;
    background: $secondaryColor;
    text-transform: capitalize;
    cursor: pointer;
    text-align: center;
    border-radius: $radiusMedium;
}

//custom designed chips
.chip{
    padding: .3rem 1rem;
    width: fit-content;
    display: block;
    float: left;
    margin: .3rem;
    cursor: pointer;
    border-radius: $radiusLarge;
}

.chip-dark{
    background: $gridContentDark;
}
.chip-light{
    background: $gridContentLight;
    color: $gridMainDark;
}
.checked{
    background: $secondaryColor;
    color: $white;
    border: none;
}

.overlay{
    width: 100%;
    height: 100%;
    border-radius: $radiusLarge 0 0 $radiusLarge;
    position: absolute;
    top: 0;
    left: 0;
    background: rgb(0, 3, 3);
    opacity: .45;
    z-index: 101;
}

a{
    text-decoration: none;
    color: inherit;
}

.switch-mode-wrapper {
    position: relative;
    border-radius: 100% !important;
    .switch-mode-sun {
        position: absolute;
        z-index: 1;
        top: 4px;
        left: 0px;
        cursor: pointer !important;
        border-radius: $radiusFull;
        .sun-icon{
            font-size: 19px;
            margin-top: 1.3px;
            margin-left: 11px;
            &.green{
                color: $secondaryColor !important;
                background: transparent !important;
            }
            &.black{
                color: $contrastActionDark;
                background: transparent !important;
            }
        }
    }
    .switch-mode-moon {
        position: absolute;
        z-index: 1;
        top: 4px;
        right: 0px;
        cursor: pointer;
        .moon-icon{
            font-size: 18px;
            margin-top: 1.5px;
            margin-right: 11px;
            transform: rotateY(180deg) rotateZ(30deg);
            &.green{
                color: $secondaryColor !important;
                background: transparent !important;
            }
            &.black{
                color: $theme-color;
                background: transparent !important;
            }
        }
    }
}


//hide scrollbar without lusing the scroll functionality
// .hide-scroll::-webkit-scrollbar {
//     display: none !important;  /* Safari and Chrome */
// }
// .hide-scroll {
//     -ms-overflow-style: none !important;  /* Internet Explorer 10+ */
//     scrollbar-width: none !important;  /* Firefox */
// }

.hide-scroll {
    overflow-y: scroll;
    overflow-x: hidden;
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
}
.hide-scroll::-webkit-scrollbar { /* WebKit */
    width: 0;
    height: 0;
}

input[type="time"]::-webkit-clear-button {
    display: none !important;
    width: 0 !important;
    height: 0 !important;
}
input[type="time"]::-ms-clear {
    display: none !important;
    width: 0 !important;
    height: 0 !important;
}

html{
    overflow-y: scroll;
    overflow-x: hidden;
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
}
html::-webkit-scrollbar { /* WebKit */
    width: 0;
    height: 0;
}

.dialog-description {
    &.is-dark {
        color: $white;
    }
}

.pinging-divider {
    width: 100%;
    display: flex;
    align-items: center;
    margin: 1em -1em;

    color: rgba(white, 0.25);

    &:before,
    &:after {
        content: "";
        flex: 1;
        height: 1px;
        margin: 0 1em;
        background: rgba(white, 0.25);
    }
}


.refElSuggestions {
    &.is-dark{
        .comment-input__suggestions {
            background-color: $gridMainDark !important;

            .comment-input__suggestions__item {
                &.comment-input__suggestions__item--focused {
                    background-color: $highlight;
                }
            }

        }
    }
    &.is-light{
        .comment-input__suggestions {
            background-color: $white !important;

            .comment-input__suggestions__item {
                &.comment-input__suggestions__item--focused {
                    background-color: $highlight;
                }
            }

        }
    }

    .comment-input__suggestions {
        border-radius: $radiusSmall;
        padding: 0.1rem;

        .comment-input__suggestions__item {
            margin: 0.15rem;
            padding: 0.20rem 0.45rem;
        }

    }

    .comment-input__highlighter {
        &.comment-input__highlighter--singleLine {
            top: -1px !important;
        }
    }
}

.user-event-pipe-item {
    cursor: pointer;
}


.is-live {
    margin-top: 0.15rem;
    width: fit-content;
    background-color: rgba(190,0,0,1);
    padding: 0.05rem 0.4rem 0.05rem 0.2rem;
    border-radius: 0px 12px 12px 0px;
    font-size: 0.55rem;
    .is-live-circle {
        vertical-align: middle;
        width: 6px;
        height: 6px;
        border-radius: 100%;
        margin: 0 auto;
        -webkit-animation: live 1.4s infinite ease-in-out;
        animation: live 1.4s infinite ease-in-out;
        -webkit-animation-fill-mode: both;
        animation-fill-mode: both;
        &:nth-child(1) {
            background-color:rgba(255,255,255,0.3);
            background-color:rgba(255,255,255,1);
            -webkit-animation-delay: -0.1s;
            animation-delay: -0.1s;
        }
    }
    .is-live-label {
        color: rgba(255,255,255,1);
        margin-left: 0.15rem;
    }
    @-webkit-keyframes live {
        0%, 80%, 100% { -webkit-transform: scale(0.6) }
        40% { -webkit-transform: scale(1.0) }
    }
    @keyframes live {
        0%, 80%, 100% {
            transform: scale(0.6);
            -webkit-transform: scale(0.6);
        } 40% {
              transform: scale(1.0);
              -webkit-transform: scale(1.0);
          }
    }
}

