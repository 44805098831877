.privacy-policy-page{
    min-height: calc(100vh - 60px) !important;
    .static-content {
        margin: 0 auto;
        max-width: 960px;
        h2 {
            font-family: "Barlow-Bold", sans-serif;
        }
    }
}
