@import '../../assets/scss/variables.scss';

.latency-user-search {
    padding: 0 .5rem 0 .2rem;
    border-radius: $radiusLarge;
    width: 100%;
    height: 32px;

    .search-input {
        width: 90%;
        margin: 0;
        padding: .1rem;
        div{
            &:before, &:after{
                display: none !important;
            }
        }
    }
    .input-loader{
        width: 18px !important;
        height: 18px !important;
        color: $secondaryColor !important;
    }
}