@import '../../../assets/scss/variables.scss';

.interests-form{
    height: fit-content;
    position: relative;
    min-height: 200px;

    .parent-chips{
        display: grid;
        grid-template-columns: repeat( auto-fit, minmax(100px, 1fr) );
        grid-row-gap: 0.5em;
        grid-column-gap: 0.5em;

        .parent-chip{
            border-radius: $radiusSmall;
            text-transform: capitalize;
            width: 100%;
            height: 120px;
            overflow: hidden;
            padding: 0.5em;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            position: relative;
            cursor: pointer;
            transition: opacity 0.2s;
            -webkit-transition: opacity 0.2s;

            &:not(.is-selected) {
                opacity: 0.75;
            }

            .light{
                font-family: 'Barlow-Regular' !important;
            }

            .bold{
                font-family: 'Barlow-SemiBold' !important;
            }

            .see-more-btn{
                text-transform: capitalize;
                font-size: 10px;
                margin-top: 0rem;
                padding: 0;
                &.empty {
                    visibility: hidden;
                }
            }
            .interest-icon{
                width: 50px;
                height: 50px;
                margin: 5px;
            }
            .subtitle{
                margin-top: 5px;
            }
        }
    }
    .children-chips{
        min-height: 100px;
        overflow: auto;

        .parent-chip{
            cursor: pointer;
            .interest-icon{
                width: 50px;
                height: 50px;
                margin: 5px;
            }
        }
    }
    .back-btn{
        position: absolute;
        top: 0rem;
        left: 1rem;
        background: transparent;
        text-transform: capitalize;
        font-size: 14px;

        .icon{
            margin-right: .5rem;
            font-size: 16px;
        }
    }
    .bottom{
        width: 100%;
        margin-top: 1rem;

        .submit-btn{
            float: right;
        }
    }
}
