@import '../../../assets/scss/variables.scss';

.profile-header{
    position: relative;
    min-height: 300px;
    width: calc(100% - 380px);
    float: left;
    margin-right: 1rem;
    border-radius: $radiusLarge;
    box-sizing: border-box;
    box-shadow: none;
    padding: 0;

    .header-img{
        width: 100%;
        height: 300px;
        object-fit: cover;
        border-radius: $radiusFull;
    }
    .user-info{
        position: absolute;
        top: 210px;
        left: 80px;
        z-index: 101;
    }
    .followers-info{
        position: absolute;
        top: 270px;
        left: 180px;
        z-index: 100;
    }
    .edit-icon{
        position: absolute;
        top: 256px;
        right: 1.4rem;
        color: $secondaryColor;
        border: 1px solid $secondaryColor;
        border-radius: 4px;
        padding: .4rem;
        font-size: 30px;
        cursor: pointer;
        border-radius: $radiusMedium;
    }
    .chip-isFollowingMe{
        position: absolute;
        right: 20px;
        bottom: 20px;
        font-size: .60rem;
        font-style: italic;
        background: $gridMainLight;
        border-radius: 10px;
        text-transform: capitalize;
        padding: .19rem !important;
        color: $white;
        width: fit-content !important;
        font-family: 'Barlow-Medium';
        float: right;
        margin-left: 1rem;
        &.isLight {
            color: $darkHover;
        }
        &.isDark {
            color: $darkHover;
        }
    }
}

@media only screen and (max-width: 600px){
    .profile-header{
        position: relative;
        min-height: 330px;
        width: 100%;
        margin-right: 0;

        .header-img{
            height: 200px;
        }
        .user-info{
            height: 200px;
            top: 130px;
            left: 50%;
            transform: translate(-50%, 0);
            .extra-data{
                min-height: 60px;
            }
        }
        .edit-icon{
            top: 156px;
        }
        .chip-isFollowingMe{
            position: absolute;
            right: 16px;
            top: .5rem;
        }
    }
}

@media only screen and (min-width: 600px) and  (max-width: 1080px) {
    .profile-header{
        position: relative;
        min-height: 320px;
        width: 100%;
        margin-right: 0;

        .header-img{
            height: 200px;
        }
        .user-info{
            top: 130px;
            left: 50px;
        }
        .followers-info{
            left: 140px;
            top: 180px;
        }
        .edit-icon{
            top: 156px;
        }
        .chip-isFollowingMe{
            position: absolute;
            right: 20px;
            top: 1rem;
        }
    }
}
