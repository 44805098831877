@import '../../../assets/scss/variables.scss';


$buttonDefaultBgColor: $gridContentLight;
$buttonDefaultBorderColor: $gridContentLight;
$buttonDefaultTextColor: $gridContentLight;

$buttonStatusOnBgColor: $gridContentLight;
$buttonStatusOnBorderColor: $gridContentLight;
$buttonStatusOnTextColor: black;

$buttonStatusOffNormalBgColor: $highlight;
$buttonStatusOffNormalBorderColor: $highlight;
$buttonStatusOffNormalTextColor: white;

$buttonStatusOnNormalBgColor: $gridContentLight;
$buttonStatusOnNormalBorderColor: $gridContentLight;
$buttonStatusOnNormalTextColor: #a1a1a1;


$buttonStatusOnOutlineBgColor: transparent;
$buttonStatusOnOutlineBorderColor: $highlight;
$buttonStatusOnOutlineTextColor: $highlight;
$buttonStatusOffOutlineBgColor: transparent;
$buttonStatusOffOutlineBorderColor: #a1a1a1;
$buttonStatusOffOutlineTextColor: #a1a1a1;

.advanced-button {
  min-width: auto !important;
  height: 27px !important;
  padding: 0 !important;
  padding-left: 0.5em !important;
  padding-right: 0.5em !important;
  border-radius: $radiusLarge !important;
  background-color: $buttonDefaultBgColor !important;
  border: 1px solid $buttonDefaultBorderColor !important;
  line-height: 1.5 !important;

  .label {
    font-family: "Barlow-Medium", sans-serif;
    font-size: 0.9rem;
    letter-spacing: 0;
    text-transform: initial;
    padding: 0 0.50em;
    color: $buttonDefaultTextColor !important;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .icon {
    line-height: 0;
    img {
      width: 24px;
      height: 24px;
    }
    &.icon-left {
      margin-left: 0.5em;
    }
    &.icon-right {
      margin-right: 0.5em;
    }
  }

  &.is-status-off {
    background-color: $buttonStatusOffOutlineBgColor !important;
    border: 1px solid $buttonStatusOffOutlineBorderColor !important;
    .label {
      color: $buttonStatusOffOutlineTextColor !important;
    }

    &:not(.is-outline) {
      background-color: $buttonStatusOffNormalBgColor !important;
      border: 1px solid $buttonStatusOffNormalBorderColor !important;
      .label {
        color: $buttonStatusOffNormalTextColor !important;
      }
    }

    &.is-text {
      border-color: transparent !important;
      background-color: transparent !important;
      .label {
        color: $buttonStatusOnOutlineTextColor !important;
      }
    }

  }



  &.is-status-on {
    background-color: $buttonStatusOnOutlineBgColor !important;
    border: 1px solid $buttonStatusOnOutlineBorderColor !important;
    .label {
      margin-top: -2px;
      font-size: 1rem;
      color: $buttonStatusOnOutlineTextColor !important;
    }

    &:not(.is-outline) {
      background-color: $buttonStatusOnNormalBgColor !important;
      border: 1px solid $buttonStatusOnNormalBorderColor !important;
      .label {
        color: rgba($buttonStatusOnNormalTextColor, 1) !important;
      }
    }

    &.is-text {
      border-color: transparent !important;
      background-color: transparent !important;
    }

  }

  &.is-outline {
    background-color: transparent !important;
  }

  &.has-icon-left {
    .label {
      padding-left: 0.25em !important;
    }
  }

}
