@import '../../../assets/scss/variables.scss';

.edit-profile-form {
    padding: 2rem 0;

    .MuiTextField-root {
        &.input-error {
            margin-bottom: 2.5em !important;
        }
        &.withHelperText {
            margin-bottom: 1.25em;

            .MuiFormHelperText-root {
                position: absolute;
                bottom: -1.4em;
                -webkit-touch-callout: none; /* iOS Safari */
                -webkit-user-select: none; /* Safari */
                -khtml-user-select: none; /* Konqueror HTML */
                -moz-user-select: none; /* Old versions of Firefox */
                -ms-user-select: none; /* Internet Explorer/Edge */
                user-select: none;
                /* Non-prefixed version, currently
                                                 supported by Chrome, Opera and Firefox */
            }
        }

        &.input-dark {
            &.withHelperText {
                .MuiFormHelperText-root {
                    display: block;
                    color: $textSecondaryColor;
                }
            }
        }
    }

    .fullFlex {
        flex: 1;
    }

    .icon {
        margin-top: 8px;
        width: 24px;
        height: 24px;
        -webkit-touch-callout: none; /* iOS Safari */
        -webkit-user-select: none; /* Safari */
        -khtml-user-select: none; /* Konqueror HTML */
        -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
        user-select: none;
        /* Non-prefixed version, currently
                                         supported by Chrome, Opera and Firefox */
    }

    .input-dark {
        background: $gridContentDark !important;
    }

    .input-light {
        background: $gridContentLight;
    }

    .submit-btn {
        margin-top: 1rem;
    }

    .force-form-control {
        border-radius: $radiusRegular;
        &.input-dark {
            .MuiInputAdornment-positionStart {
                p {
                    color: $borderLight;
                }
            }
        }
        p {
            display: initial;
        }
        .MuiInputAdornment-positionStart {
            margin-right: 4px;
        }
        input {
            padding-left: 0;
        }
    }

}
