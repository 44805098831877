.top-categories {
  .event-category {
    &.is-filtered {
      .event-category_image-box {
        img {
          height: 80px;
          vertical-align: bottom;
        }
      }
      .event-category_wrap-filtered {
        display: none;
      }
    }
    .event-category_image-style {
      width: 100%;
      height: 100px;
      object-fit: cover;
      transform: scale(1)
    }
  }
}


.event-category {
  position: relative;

  &.is-filtered {
    .event-category_image-box {
      border: 4px solid #54d7d3;
    }
    .event-category_wrap-filtered {
      display: none;
    }
  }

  &:hover {
    &:not(.is-filtered) {
      .event-category_image-box {
        border: 4px solid darkgrey;
      }
    }
    &.is-filtered {
      .event-category_image-box {
        border: 4px solid darkred;
      }
    }
  }

  .event-category_wrap-filtered {
    margin-left: 0.5rem;
    position: absolute;
    width: calc(100% - 1rem);
    bottom: 54px;
    left: 0;
    z-index: 123;

    background-color: black;

    text-align: center;

    .event-category_wrap-filtered--action {
      color: white;
    }
  }

  .event-category_image-box {
    border: 4px solid transparent;
    img {
      vertical-align: bottom;
    }
  }

  .event-category_image-style {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transform: scale(1)
  }

  .event-category_title {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    font-size: 14px;
    font-family: "Barlow-SemiBold", sans-serif;
  }

  .event-category_subtitle {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    font-size: 12px;
    font-family: "Barlow-Medium", sans-serif;
    text-transform: lowercase;
  }
}
