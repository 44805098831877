@import '../../../assets/scss/variables.scss';

.complete-signup-form {
    height: fit-content;
    position: relative;
    min-height: 200px;
    //height: 100vh;

    display: flex;
    flex-direction: column;

    &.is-mobile {
        height: 100%;
    }

    .MuiStepper-root {
        width: 100%;
        .MuiStepLabel-label {
            font-size: $fontSmall;
            text-transform: uppercase;
            font-family: 'Barlow-Medium', serif !important;
            &.MuiStepLabel-active {
                color: $highlight;
            }
        }
    }

    &.is-dark-theme {
        .MuiStepper-root {
            .MuiStepLabel-label {
                &.MuiStepLabel-alternativeLabel {
                    &:not(.MuiStepLabel-active) {
                        &:not(.MuiStepLabel-completed) {
                            color: $lightHover;
                        }
                    }
                }
            }
        }

        .MuiFormLabel-root {
            &.MuiInputLabel-root {
                color: $lightHover;
            }
        }

        .MuiInputAdornment-root {
            .MuiTypography-colorTextSecondary {
                color: $lightHover;
            }
        }

    }

    .complete-signup-wrapper {
        flex: 1;
        width: 80vw;
        font-family: 'Barlow-Light', serif !important;
        .complete-signup-upload_avatar {
            div:first-child {
                margin: 0 auto;
            }

            .avatar {
                margin: 0 auto;
                width: 256px;
                height: 256px;
                border-radius: 50%;
                transition: opacity 0.15s linear;
                &:hover {
                    cursor: pointer;
                    opacity: .5;
                }
            }

            .dropzone-HelperText {
                font-size: 1.15rem !important;
            }
        }

        .complete-signup-user_details {
            width: 50%;
            margin: 0 auto;
            &.is-mobile {
                width: 100%;
            }
            .user_details-paper {
                padding: 1em;
            }

            .MuiSelect-icon, .MuiIconButton-label .MuiSvgIcon-root {
                color: $theme-color !important;
            }
        }

    }

    .complete-signup-footer {
        width: 90%;
        margin: 1em auto;

        display: grid;
        grid-template-columns: 100px 100px;
        grid-template-areas: "buttonBack buttonNext";

        .submit-btn {
            &.button-back {
                grid-area: buttonBack;
            }
            &.button-next {
                grid-area: buttonNext
            }
            &:disabled {
                background-color: $gridContentLight;
                cursor: not-allowed !important;
            }
        }

    }
}

.autoCompleteCityItems {
    .cityItemIcon {
        width: 14px;
        height: 14px;
        margin-left: 0;
        margin-right: 0.25em;
    }

    .cityItemTitle {

    }

}
