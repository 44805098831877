@import '../../assets/scss/variables.scss';

.custom-button {
    font-family: Barlow-Light, sans-serif !important;
    height: 30px !important;
    font-size: 12px;
    min-width: 80px;
    text-transform: capitalize !important;
    border-radius: $radiusLarge !important;
    cursor: pointer !important;
    padding: .25rem .75rem !important;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;

    .loader{
        width: 20px !important;
        height: 20px !important;
        color: $gridMainDark;
    }

    &.submit-btn, .cancel-btn, &.delete-btn{
        background: $secondaryColor;
        color: $white;
        &:hover{background: darken($secondaryColor, 10);}
    }

    &.delete-btn {
        color: $white;
        background-color: $red;
        &:hover {
            background-color: darken($red, 10);
        }
    }

    &.white-btn {
        color: $secondaryColor;
        background-color: $white;
        &:hover {
            background-color: darken($white, 2);
        }
    }

    &.e-small{
        min-width: 80px !important;
        font-size: 12px !important;
        height: 24px !important;
        padding: .15rem .5rem !important;
    }

    &.e-hidebtn {
        min-width: 32px !important;
        width: 32px !important;
        height: 24px !important;
    }

    .label-btn{
        background: $secondaryColor;
        color: $white;
        &:hover{background: $secondaryColor;}
        &+input{
            display: none !important;
        }
    }

    &.cancel-btn{
        background: $gridContentLight;
        color: $eventCount;
        &:hover{background: $gridContentLight;}
    }

    &.ml{
        margin-left: 1rem;
    }

    &.mr{
        margin-right: 1rem;
    }

    &.mt{
        margin-top: 1rem;
    }

    &.mb{
        margin-bottom: 1rem;
    }

    &.wide{
        min-width: 100px;
    }
    &.large{
        min-width: 120px;
        height: 36px !important;
        font-size: 14px !important;
    }
    &.full-width {
        width: 100% !important;
    }
}
