@import '../../../assets/scss/variables.scss';

.modal {

  .loader-box {
    position: absolute;
    opacity: .9;
  }

  &.is-desktop {
    .add-event-form-container {
      min-width: 640px;
    }
  }

  &.is-mobile {
    .date-component {
      .date-wrapper {
        grid-template-columns: 1fr !important;
        grid-row-gap: 1rem;
      }
      .abs-picker {
        width: fit-content;
        margin: 0 auto;
        left: 0;
        right: 0;
      }
    }
    .form-field {
      &.is-container {
        &.r-mobile {
          grid-template-columns: 1fr !important;
          grid-row-gap: 1rem;
        }
      }
    }

  }

  .scroller-content{
    height: 100% !important;
  }

  .add-event-form-container {
    width: 100%;
    max-width: 640px;
    //min-height: 700px;
    padding: 1rem;
    overflow: hidden;
    position: relative;

    .top-header {
      .title {
        text-align: center;
        font-family: Barlow-Light,sans-serif;
        font-size: 1.8rem;
        text-transform: uppercase;
      }

      margin-bottom: 0.5rem;
      .close-btn {
        cursor: pointer;
        position: absolute;
        top: 18px;
        right: 18px;
        &:hover {
          color: $highlight;
        }
      }
    }

    .scroller {
      height: 500px;
      min-height: calc(800px - 28px);
    }

    .form-field {
      &.mt {
        margin-top: 0.75rem;
      }

      .input-helper {
        font-style: italic;
        padding: 0.1rem;
        color: #ccc;
        border-bottom: 1px dashed #ccc;
        font-size: 0.75rem;
        margin-left: 1rem;
      }

      &.is-container {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-column-gap: 1rem;

        &:not(.container-1-2) {
          .field-label {
            font-size: 1rem;
            padding-bottom: 0.1rem;
          }
        }

        & + .is-container {
          margin-top: 0.75rem;
        }

        &.container-1-2 {
          grid-template-columns: 72px 1fr;
          grid-column-gap: 2rem;
        }
      }

      .poster-placeholder {
        margin-left: 1rem;
        width: 64px;
        height: 80px;
        border-radius: 6px;
        border: 0px dashed rgba(255, 255, 255, .25);
        padding: 2px;
        position: relative;
        img.poster-display {
          object-fit: cover;
          border-radius: 6px;
          opacity: .5;
          width: 100%;
          height: 80px;
          &:hover {
            cursor: pointer;
            opacity: 1;
          }
        }

        .absolute {
          width: 100%;
          height: 80px;
          margin: auto;
          position: absolute;
          top: 0; left: 0; bottom: 0; right: 0;
          cursor: pointer;
          img {
            width: 32px;
            height: 32px;
          }
          input {
            display: none;
          }
        }

      }

      .periodicty-input {
        width: 100%;
        margin-top: 0.75rem;
        padding-left: 14px;
        justify-self: flex-start;

        .MuiFormControlLabel-root {
          transform: scale(0.9);
          .MuiButtonBase-root {
            &.Mui-checked {
              .MuiIconButton-label {
                .MuiSvgIcon-root {
                  fill: $highlight;
                }
              }
            }
            .MuiIconButton-label {
              .MuiSvgIcon-root {
                fill: white;
              }
            }
          }
        }

        .MuiCheckbox-root {
          padding: 0;
          padding-right: 6px;
        }

        .MuiTypography-body1 {
          font-size: 1.05rem;
        }
      }

      .field-label-w-action {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-column-gap: 1rem;

        .date-switcher-mode {
          justify-self: flex-end;
          height: 30px;
          padding-right: 0.15rem;

          .MuiFormControlLabel-root {
            .MuiButtonBase-root {
              &.Mui-checked {
                .MuiIconButton-label {
                  .MuiSvgIcon-root {
                    fill: $highlight;
                  }
                }
              }
              .MuiIconButton-label {
                .MuiSvgIcon-root {
                  fill: white;
                }
              }
            }
          }

          .MuiCheckbox-root {
            padding: 0;
            padding-right: 6px;
          }

          .MuiTypography-body1 {
            font-size: 1.05rem;
          }
        }

      }

      .field-label {
        text-transform: uppercase;
        font-weight: initial;
        font-family: 'Barlow-Light', sans-serif;
        font-size: 1.10rem;
        padding-left: 1rem;
        text-align: left;
        padding-bottom: 0.25rem;

        &.is-disabled {
          opacity: .5;
        }
      }

      .tag-select {
        border: 2px solid transparent;

        border-radius: $radiusMedium;

        &.tag-select-dark {
          background-color: $gridMainDark;
        }

        &.tag-select-light {
          background-color: $gridMainLight;
        }

        &:hover {
          &:not(.Mui-focused) {
            border-color: $calendarRow;
          }
        }

        &.Mui-focused {
          border-color: $highlight;
        }

        .Mui-disabled {
          cursor: not-allowed;
          color: #54d7d3;
        }

        input {
          padding-left: 1rem !important;
        }

        & > div {
          border-radius: $radiusMedium;

          div {
            padding: 0 !important;
            border-radius: $radiusMedium;
          }
        }

        svg {
          color: $secondaryColor;
        }

        fieldset {
          border-color: transparent;
        }

        .chip {
          padding: 0rem !important;
          font-size: 12px;
          height: 30px;
          background: $secondaryColor;

          svg {
            color: $eventCount;
            width: 18px;
          }

          .MuiChip-avatar {
            width: 24px;
            height: 24px;
            padding: 0 !important;

            img {
              border-radius: 100%;
              width: 24px;
              height: 24px;
            }
          }

        }
      }

      .box-bg {
        padding: 1rem 1rem;
        border-radius: $radiusFull;

        &.is-sm {
          padding: 0 0 0 1.5rem;
          height: 40px;
        }

        &.date-component {
          background-color: transparent;
          padding: 0;
          align-items: center;
          display: flex;
          flex-direction: column;

          &.is-dark-theme {
            .abs-picker {
              background-color: $gridMainDark;
              border-color: $gridContentDark;

              .MuiPickersDay-daySelected {
                color: white;
              }

              .MuiPickersDay-dayDisabled {
                color: rgba(255, 255, 255, .20);
              }

              .time-wrap {
                input {
                  color: white;
                }
                /* Hour */
                input[type=time]::-webkit-datetime-edit-hour-field {
                  background-color: $gridContentDark;
                }

                /* Minute */
                input[type=time]::-webkit-datetime-edit-minute-field {
                  background-color: $gridContentDark;
                }
                input[type="time"]::-webkit-calendar-picker-indicator{
                  //filter: invert(48%) sepia(13%) saturate(3207%) hue-rotate(130deg) brightness(95%) contrast(80%);
                  filter: brightness(0) invert(1);
                }

              }


            }
          }

          &.is-light-theme {
            .abs-picker {
              background-color: $gridMainLight;
              border-color: $gridContentLight;

              .MuiPickersDay-daySelected {
                background-color: $highlight;
              }

              .time-wrap {
                input {
                  color: $primaryColor;
                }
                /* Hour */
                input[type=time]::-webkit-datetime-edit-hour-field {
                  background-color: white;
                }

                /* Minute */
                input[type=time]::-webkit-datetime-edit-minute-field {
                  background-color: white;
                }
              }
            }
          }

          .abs-picker {
            z-index: 9999;
            padding-top: 0px;
            padding-bottom: 12px;
            position: absolute;
            border: 4px solid transparent;
            border-radius: $radiusMedium;

            .MuiPickersStaticWrapper-staticWrapperRoot {
              border-radius: $radiusMedium;
              //zoom: 0.9;
              .MuiPickersBasePicker-pickerView {
                min-height: 180px;
                justify-content: flex-start;

                .MuiPickersCalendarHeader-switchHeader {
                  padding-top: 12px;
                  padding-bottom: 6px;
                  padding-left: 24px;
                  padding-right: 24px;
                  .MuiIconButton-root {
                    padding: 0;
                  }
                }

              }
              .MuiPickersCalendar-transitionContainer {
                margin-top: 0px;
                min-height: 180px;
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: center;
                & > * {
                  position: static;
                }
                & > div[class^="MuiPickersSlideTransition"] {
                  height: 180px;
                  overflow: hidden;
                  position: absolute;
                }
              }

            }

            .footer-picker {
              width: 100%;
              padding-top: 12px;
              padding-left: 24px;
              padding-right: 24px;
              display: flex;
              flex-direction: row;
              align-items: center;
              justify-content: center;
              &.is-spaced {
                padding-top: 12px;
                justify-content: space-between;
              }



              .submit-btn {

              }

              .time-wrap {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: center;
                .quick-time-icon {
                  margin-right: 1rem;
                }
                .quick-time {
                  position: relative;
                  overflow: hidden;

                  .MuiInput-underline {
                    &:after {
                      border-bottom-width: 0px!important;
                    }
                  }

                  input {
                    font-size: 1.25rem !important;
                    display: flex;
                    flex-direction: row-reverse;
                    height: auto;

                    border: none;
                    width: 132px;
                    padding: 0;
                  }

                  /* Wrapper around the hour, minute, second, and am/pm fields as well as
  the up and down buttons and the 'X' button */
                  input[type=time]::-webkit-datetime-edit-fields-wrapper {
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    padding-left: 32px;
                  }

                  /* The space between the fields - between hour and minute, the minute and
                  second, second and am/pm */
                  input[type=time]::-webkit-datetime-edit-text {
                    padding: 0.75rem 0.5rem;
                  }

                  /* The naming convention for the hour, minute, second, and am/pm field is
                  `-webkit-datetime-edit-{field}-field` */

                  /* Hour */
                  input[type=time]::-webkit-datetime-edit-hour-field {
                    border-radius: 15%;
                    padding: 0.55rem 0.5rem;
                  }

                  /* Minute */
                  input[type=time]::-webkit-datetime-edit-minute-field {
                    border-radius: 15%;
                    padding: 0.5rem 0.5rem;
                  }

                  /* AM/PM */
                  input[type=time]::-webkit-datetime-edit-ampm-field {
                    background-color: #7155d3;
                    border-radius: 15%;
                    color: #fff;
                    padding: 0.75rem 0.5rem;
                  }

                  input[type="time"]::-webkit-calendar-picker-indicator{
                    left: 0;
                    margin-left: 0;
                    margin-right: 6px;
                    width: 24px;
                    height: 24px;

                    position: absolute;
                    padding-right: 100px;
                  }

                  .MuiInput-underline {
                    &:before {
                      display: none;
                    }
                  }
                }
              }




            }



          }

          .date-wrapper {
            width: 100%;
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-column-gap: 1rem;
            align-items: center;

            .date-block {
              border-radius: 24px;
              width: 100%;
              display: flex;
              flex-direction: row;
              align-items: center;
              justify-content: center;
              padding: 0.75rem;
              border: 1px solid transparent;

              &:hover {
                border-color: white;
                cursor: pointer;
                //background-color: rgba(0, 0, 0, .1);
              }

              div.date-wrap {
                flex: 1;
                margin-left: 0.75rem;
                display: flex;
                flex-direction: column;

                .date-top-label {
                  font-family: 'Barlow-Light', sans-serif;
                  font-size: 1rem;
                }

                .date-time {
                  display: grid;
                  grid-template-columns: 18px auto 18px auto;
                  grid-column-gap: 4px;
                  align-items: center;
                  //justify-items: center;

                  .MuiSvgIcon-root {
                    width: 18px;
                    height: 18px;
                  }

                  .date {
                    font-weight: initial;
                    font-size: 1.15rem;
                  }

                  .time {
                    font-size: 1.15rem;
                  }

                }

              }


            }

          }

          .date-switcher-mode {
            margin-top: 1rem;
            font-size: 1.25rem;
          }
        }

        &.visibility-component {
          padding: 0;

          &.is-light-theme {
            .option-label {
              color: $primaryColor;
            }
          }

          .switch {
            .MuiSwitch-switchBase {
              color: $highlight;
            }

            .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track {
              opacity: 0.38;
              background-color: #000;
            }
          }

          .option-label {
            cursor: pointer;
            color: whitesmoke;

            &.option-selected {
              font-weight: bold;
              color: $highlight;
            }
          }
        }
      }

      .description-box {
        .quill {
          border: none;

          border-radius: $radiusFull;

          &.is-input-dark {
            background-color: $gridMainDark;
            .ql-toolbar {
              background-color: $theme-dark;
            }
          }

          &.is-input-light {
            background-color: $gridMainLight;
            .ql-snow {
              .ql-stroke {
                stroke: $primaryColor;
              }

              .ql-fill {
                fill: $primaryColor;
              }
              &.ql-toolbar {
                background-color: $gridContentLight;
              }

              &.ql-container {
                .ql-editor.ql-blank::before {
                  color: rgba(0, 0, 0, .35);
                }
              }

            }
          }

          .ql-snow {
            border-width: 0px;

            .ql-stroke {
              stroke: white;
            }

            .ql-fill {
              fill: whitesmoke;
            }

            &.ql-toolbar {
              padding-left: 1rem;
              padding-right: 1rem;
              padding-top: 0.5rem;
              border-top-left-radius: 12px;
              border-top-right-radius: 12px;
            }

            &.ql-container {
              min-height: 60px;

              .ql-editor.ql-blank::before {
                font-style: normal;
                color: rgba(255, 255, 255, .35);
              }
            }
          }
        }
      }

      .category-field {
        padding-left: 1rem;
        display: grid;
        grid-template-columns: 48px 1fr;
        grid-column-gap: 0.5rem;
        align-items: flex-start;

        .category-selected-poster {
          width: 48px;
        }

        .category-select {
          border: 2px solid transparent;
          background-color: $gridMainDark;
          border-radius: $radiusMedium;

          &.category-select-dark {
            background-color: $gridMainDark;
          }

          &.category-select-light {
            background-color: $gridMainLight;
          }

          &:hover {
            &:not(.Mui-focused) {
              border-color: $calendarRow;
            }
          }

          &.Mui-focused {
            border-color: $highlight;
          }

          input {
            padding-left: 1rem !important;
          }

          & > div {
            border-radius: $radiusMedium;

            div {
              padding: 0 !important;
              border-radius: $radiusMedium;
            }
          }

          svg {
            color: $secondaryColor;
          }

          fieldset {
            border-color: transparent;
          }

          .chip {
            padding: 0rem !important;
            font-size: 12px;
            height: 30px;
            background: $secondaryColor;

            svg {
              color: $eventCount;
              width: 18px;
            }

            .MuiChip-avatar {
              width: 24px;
              height: 24px;
              padding: 0 !important;

              img {
                border-radius: 100%;
                width: 24px;
                height: 24px;
              }
            }

          }

          .down-btn {
            display: none !important;
          }
        }


        .recently-used {
          padding-left: 1rem;
          margin-top: 0.25rem;

          span {
            font-size: 0.85rem;
          }

          span + span {
            font-size: 0.8rem;
            color: $highlight;
            text-decoration: underline;
            margin-left: 0.25rem;

            &:hover {
              cursor: pointer;
              font-weight: bold;
            }
          }
        }
      }

    }
  }


}


.event-category-list--item {
  .category-option-img {
    width: 48px;
    margin-right: 0.5rem;
  }
}


