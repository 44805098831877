@import '../../assets/scss/variables.scss';

.agenda-page {
  flex: 1;

  &.is-experimental-mode {
    .agenda-pipelines {
      flex: 1;
    }
  }
}

@media only screen and (max-width: 600px) {
  .agenda-page {
    padding: .5rem;
    box-sizing: border-box !important;

    .agenda-calendar,
    .agenda-pipelines {
      box-sizing: border-box;
      box-shadow: none;
      padding: 0;
    }

    .agenda-calendar {
      width: 100%;
      height: 350px;
      margin-bottom: 1rem;
    }

    .agenda-pipelines {
      width: 100%;
      padding: .5rem .3rem;
    }
  }
}

@media only screen and (min-width: 601px) and (max-width: 1080px) {
  .agenda-page {
    padding: 1rem;
    box-sizing: border-box !important;

    .agenda-calendar,
    .agenda-groups,
    .agenda-recommendations,
    .agenda-pipelines {
      min-height: 350px;
      box-sizing: border-box;
      box-shadow: none;
      padding: 0;

      h2 {
        margin: 0;
      }
    }

    .agenda-calendar {
      width: 345px;
      height: 350px;
      margin-right: 1rem;
    }

    .agenda-pipelines {
      width: 100%;
      padding: .5rem .3rem;
    }

    .agenda-recommendations {
      height: 350px;
      width: 350px;
    }

    .agenda-groups {
      height: 350px;
      width: 100%;
      margin: 0 0 .5rem 0;
    }
  }
}

@media only screen and (min-width: 1081px) and (max-width: 1440px) {
  .agenda-page {
    padding: 1rem;
    box-sizing: border-box !important;

    .agenda-calendar,
    .agenda-groups,
    .agenda-recommendations,
    .agenda-pipelines {
      min-height: 350px;
      box-sizing: border-box;
      box-shadow: none;
      padding: 0;

      h2 {
        margin: 0;
      }
    }

    .agenda-calendar {
      width: 330px;
      height: 350px;
    }

    .agenda-pipelines {
      width: 100%;
      flex-grow: 1;
      padding: .5rem .3rem;
    }

    .agenda-recommendations {
      height: 350px;
      width: 330px;
      flex-grow: 0;
    }

    .agenda-groups {
      height: 350px;
      width: calc(100% - 678px);
      margin: 0 .5rem .5rem .5rem;
    }
  }
}

@media only screen and (min-width: 1441px) {
  .agenda-page {
    padding: 1rem !important;
    box-sizing: border-box !important;

    .agenda-calendar,
    .agenda-groups,
    .agenda-recommendations,
    .agenda-pipelines {
      min-height: 350px;
      box-sizing: border-box;
      box-shadow: none;
      padding: 0;

      h2 {
        margin: 0;
      }
    }

    .agenda-calendar {
      width: 345px;
      height: 350px;
    }

    .agenda-pipelines {
      width: 100%;
      flex-grow: 1;
      padding: .5rem .3rem;
    }

    .agenda-recommendations {
      height: 350px;
      width: 350px;
      flex-grow: 0;
    }

    .agenda-groups {
      height: 350px;
      width: calc(100% - 712px);
      margin: 0 .5rem .5rem .5rem;
    }
  }
}


.agenda-page {
  padding: 0.25rem;

  .agenda-calendar,
  .agenda-groups,
  .agenda-recommendations {
    height: 280px;
    max-height: 280px;
    overflow: hidden;
    min-height: 280px;
  }

  .agenda-calendar {
    .calendar {
      display: flex;
      flex-direction: column;
      align-items: stretch;
      -webkit-transform: scale(0.9);
      -moz-transform: scale(0.9);
      -ms-transform: scale(0.9);
      -o-transform: scale(0.9);
      transform: scale(0.9);
      -webkit-transform-origin: 0 0;
      width: 111.111%;
      max-width: 111.111%;
      padding: 0;

      &>div:first-child {
        -webkit-transform: scale(0.9);
        -moz-transform: scale(0.9);
        -ms-transform: scale(0.9);
        -o-transform: scale(0.9);
        transform: scale(0.9);
        -webkit-transform-origin: 0 0;
        width: 111.111%;
        max-width: 111.111%;
      }

      .c-header {
        margin-top: 0.5em;
      }
    }
  }

  .agenda-groups {
    .groups {
      height: 280px;

      .groups-data {
        height: 180px;

        .group-card {
          width: 95%;
          height: 150px;
          margin: 0 auto;
          padding: 0.25em;

          img:not(.member-avatar) {
            width: 25px;
          }

          .group-card-content {
            margin-top: .5rem;
            margin-bottom: .25rem;
          }

          .card-members {
            margin-top: 0;

            img {
              width: 24px;
              height: 24px;
            }
          }

        }
      }
    }
  }

  .agenda-recommendations {
    .recommendations {
      height: 250px;

      .box-content {
        height: 220px;
      }
    }
  }
}
