@import '../../assets/scss/variables.scss';

.edit-profile{
    padding: 1rem;

    .header{
        position: relative;
        height: 300px !important;

        .header-img{
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: $radiusMedium;
        }
        .head-up-mobile{
            display: none;
            position: absolute;
            left: 1rem;
            bottom: .5rem;
            opacity: 1;
            transition: opacity .35s;
            input{
                display: none;
            }
            label{
                cursor: pointer;
                .icon{
                    color: $secondaryColor;
                    font-size: 24px;
                }
            }
        }
        .head-up-laptop{
            position: absolute;
            left: 1rem;
            bottom: 1rem;
            opacity: 0;
            transition: opacity .35s;
            input{
                display: none;
            }
            label{
                cursor: pointer;
                background: $secondaryColor;
                color: $white;
                height: 40px;
                border-radius: $radiusLarge;
                padding: .3rem 1rem;
            }
        }
        &:hover{
            .head-up-laptop{
                opacity: 1;
            }
        }

    }
    .profile{
        margin-top: 2.5em;
        position: relative;
        width: fit-content !important;
        display: flex;
        flex-direction: column;
        align-items: center;

        .profile-img{
            width: 150px;
            height: 150px;
            border-radius: 100%;
            object-fit: cover;
        }

        .profile-up-mobile{
            display: none;
            position: absolute;
            top: 35%;
            right: -4%;
            opacity: 1;
            transition: opacity .35s;
            input{
                display: none;
            }
            label{
                cursor: pointer;
                .icon{
                    color: $secondaryColor;
                    font-size: 24px;
                }
            }
        }
        .profile-up-laptop{
            position: absolute;
            top: 50%;
            left: 50%;
            opacity: 0;
            transform: translate(-50%, -50%);
            transition: opacity .35s;
            input{
                display: none;
            }
            label{
                cursor: pointer;
                background: $secondaryColor;
                color: $white;
                height: 40px;
                border-radius: $radiusLarge;
                padding: .3rem 1rem;
            }
        }
        &:hover{
            .profile-up-laptop{
                opacity: 1;
            }
        }

        .profile-default-cover {
            .poster-box {
                .poster-display {
                    width: 100%;
                    height: 400px;
                }
            }
        }
    }

    .interests{
        height: 300px !important;
        .scroller{
            .scroller-content{
                height: calc(100% - 32px);
            }
            .scroller-action{
                height: 32px;
                img{
                    height: 10px;
                }
            }
        }
        .interests-form{
            padding: 0;
        }
    }


    .edit-profile-section-title {
        font-size: 1.20rem !important;
        font-weight: bold;
        margin: 0;
        text-transform: uppercase;
        &.isLight {
            color: $textColor;
        }
        &.isDark {
            color: $secondaryColor;
        }
    }

    .poster-row {
        width: 80%;
        margin: 1.5em auto 0;
        display: flex;
        flex-direction: column;

        .edit-profile-section-title {
            text-align: center;
        }

        .user-default-poster {
            zoom: 0.88;
            border-radius: $radiusLarge;
            margin-top: 0.5rem;
            position: relative;
            height: 680px;
            overflow: hidden;

            .bg-poster {
                -webkit-filter: blur(5px) contrast(125%) brightness(75%);
                filter: blur(5px) contrast(125%) brightness(75%);
                background-position: center;
                background-repeat: no-repeat;
                background-size: cover;
                width: 100%;
                height: 100%;
                border-radius: 50px;
            }
            .group-poster {
                position: absolute;
                width: 100%;
                height: 680px;

                .upload-icon{
                    width: 85%;
                    height: 430px;
                    border: 3px solid $highlight;
                    border-radius: 50px;

                    img{
                        width: 50px;
                        height: 50px;
                        cursor: pointer;
                    }
                    input{
                        display: none;
                    }
                }

                .absolute{
                    border-radius: $radiusLarge;
                    background-color: rgba($primaryColor, 0.7);
                    width: 100%;
                    height: 680px;
                    position: absolute;
                    opacity: 0.25;
                    transition: opacity .35s;
                    z-index: 1021 !important;
                    color: $highlight;
                    img{
                        width: 40px;
                        height: 40px;
                        cursor: pointer;
                    }
                    input{
                        display: none;
                    }
                }

                &:hover{
                    .upload-icon{
                        opacity: 1;
                    }
                    .absolute {
                        opacity: 1;
                    }
                }

                .poster-display {
                    border-radius: $radiusLarge;
                    width: 100%;
                    height: 680px;
                    object-fit: cover;
                }

            }
        }

    }
    .custom-social-network-field {
        margin-bottom: 8px;
    }
}

@media only screen and (max-width: 600px){
    .edit-profile{
        //padding: .5rem;
        .header{
            height: 200px !important;
            .header-img{
                width: 100%;
                height: 100%;
            }
            .head-up-mobile{
                display: initial;
                input{
                    display: none;
                }
                label{
                    cursor: pointer;
                    .icon{
                        color: $secondaryColor;
                        font-size: 24px;
                    }
                }
            }
            .head-up-laptop{
                display: none;
            }
        }
        .profile{
            height: 150px;
            margin: 1rem 0;

            .profile-up-mobile{
                display: initial;
                top: 55%;
                input{
                    display: none;
                }
                label{
                    cursor: pointer;
                    .icon{
                        color: $secondaryColor;
                        font-size: 24px;
                    }
                }
            }
            .profile-up-laptop{
                display: none;
            }
        }
        .interests{
            margin-top: 1rem;
            height: 360px !important;
            .scroller-content{
                height: 100% !important;
            }
            .scroller-action{
                display: none;
            }
            .interests-form{
                padding: 0;
                .parent-chips{
                    .parent-chip{
                        width: 100%;
                    }
                }
            }
        }
    }
}

@media only screen and (min-width: 600px) and (max-width: 1080px) {
    .edit-profile{
        padding: 1rem;
        .header{
            height: 200px !important;
            .header-img{
                width: 100%;
                height: 100%;
            }
            .head-up-mobile{
                display: initial;
                input{
                    display: none;
                }
                label{
                    cursor: pointer;
                    .icon{
                        color: $secondaryColor;
                        font-size: 24px;
                    }
                }
            }
            .head-up-laptop{
                display: none;
            }
        }
        .profile{
            height: 150px;

            .profile-up-mobile{
                display: initial;
                top: 45%;
                input{
                    display: none;
                }
                label{
                    cursor: pointer;
                    .icon{
                        color: $secondaryColor;
                        font-size: 24px;
                    }
                }
            }
            .profile-up-laptop{
                display: none;
            }
        }
        .interests{
            height: 220px !important;
            .interests-form{
                padding: 0;
                .parent-chips{
                    .parent-chip{
                        width: 80px;
                        .interest-icon{
                            width: 40px;
                            height: 40px;
                        }
                        .subtitle{
                            font-size: 12px;
                        }
                    }
                }
            }
        }
    }
}
