@import '../../assets/scss/variables.scss';

.add-new-acc-form, .manage-multi-acc-form {
  padding: 1em;

  &.manage-multi-acc-form {
    width: 380px;
  }

  h1 {
    font-size: 1.5rem;
    font-family: 'Barlow-Bold', sans-serif !important;
    text-transform: capitalize;
  }

  .login-form {
    padding-left: 1em;
    padding-right: 1em;
  }

  .multi-account-list {
    width: 100%;
    padding-bottom: 2em;
    .custom-multi-account-list {
      width: auto;
      display: flex;
      flex-direction: row;
      align-items: center;
      align-content: center;
      flex: 1;
      padding: 0.5em 0.5em;
      margin: 0.5em;
      border-radius: $radiusMedium;
      border: 1px solid $secondaryColor;

      .multi-account-item {
        flex: 1;
      }

      .close-session-btn {
        flex: 0.5;
        background: $secondaryColor;
        border-radius: $radiusLarge;
        text-transform: capitalize;
        padding: 0 !important;
        color: $white;
        min-width: 60px !important;
        height: 26px !important;
        font-family: 'Barlow-Medium', sans-serif !important;
        float: right;
      }


    }

    .custom-multi-account-list + .custom-multi-account-list {
      margin-top: 0.75em;
    }
  }


}
