@import '../../assets/scss/variables.scss';

.snackbar{
    &>div{
        border-radius: $radiusFull;
        height: 34px;
        padding: 0 1.5rem;
        display: flexbox;
        flex-direction: row;
        justify-content: center;
        min-width: 0 !important;
        width: fit-content !important;
        &>div{
            border-radius: $radiusFull;
            height: 34px;
            padding: 5.5px !important;
        }
    }
    &.success{
        div{
            background: $secondaryColor;
            color: $white;
            font-size: 14px;
            text-transform: initial;
        }
    }

    &.info{
        div{
            background: $orange;
            color: $white;
            font-size: 14px;
            text-transform: initial;
        }
    }

    &.error{
        div{
            background: $red;
            color: $white;
            font-size: 14px;
            text-transform: initial;
        }
    }
}