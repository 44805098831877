@import '../../../assets/scss/variables.scss';


.recommendation-skeleton{
    margin: 0 0 1.5rem 0;
    border-radius: 50px 2rem 2rem 50px;
    height: 46px;
    width: 100%;
    position: relative;

    .circle{
        width: 56px;
        height: 56px !important;
        position: absolute;
        top: -7px;
        left: 10%;
        border-radius: 100%;
    }
    .content{
        height: 46px;
        width: 55%;
        position: absolute;
        top: 2px;
        left: 30%;

    }
    .dark{background: $gridContentDark !important;}
    .light{background: $gridContentLight !important;}
}