@import '../../assets/scss/variables.scss';

.multi-account-item {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;


  .avatar {
    width: 32px;
    height: 32px;
    object-fit: cover;
    border-radius: 50%;
  }

  .nickname {
    margin-left: 0.75rem;
    font-family: "Barlow-Medium", sans-serif;
    font-size: 1rem;
  }
}
