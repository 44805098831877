@import '../../../assets/scss/variables.scss';
@import '../../../assets/scss/autofill-inputs.scss';

.login-form{
    width: 300px;
    .email, .password{
        border-radius: $radiusMedium !important;
        margin: .7rem 0;
        border-color: $secondaryColor;
        width: 100%;
        height: 50px;
        label{
            color: $secondaryColor;
            letter-spacing: 1.3px;
            font-size: 14px !important;
        }
        label+div{
            border-radius: $radiusMedium;
            height: 50px;
        }
        fieldset{
            border-color: $secondaryColor !important;
        }
        input{
            color: $secondaryColor;
            font-size: 14px !important;
        }
    }

    .Mui-error {
        color: #f44336 !important;
    }

    .error {
        padding: .5rem;
        text-align: center;
        width: fit-content;
        border-radius: 4px;
        background: crimson;
        font-size: 14px;
        margin: 1rem 0 0;
    }
    .submit-btn{
        margin: 1.5rem 0 !important;
        color: $theme-dark !important;
    }
}

@media only screen and (max-width: 600px){
    .login-form{
        width: 100%;
    }
}
