@import '../../../assets/scss/variables.scss';

.pipe-skeleton{
    width: 100%;
    height: 670px;
    margin: 0;
    border-radius: $radiusMedium;
    padding: 1rem .25rem;
    box-sizing: border-box !important;
    float: left;

    .recommendation-skeleton{
        .content{
            width: 45%;
            left: 45%;
        }
    }
}

@media only screen and (max-width: 600px){
    .pipe-skeleton{
        .recommendation-skeleton{
            .circle{
                width: 56px;
                height: 56px !important;
                position: absolute;
                top: -7px;
                left: 5%;
                border-radius: 100%;
            }
            .content{
                height: 46px;
                width: 55%;
                left: 30%;
            }
        }
    }
}