@import '../../assets/scss/variables.scss';

.recommendations{
    padding: .5rem 0 0 0;
    height: 350px;
    box-sizing: border-box !important;

    .box-title{
        margin: 0 0 1.5rem;
    }
    .close-btn{
        color: $red;
        font-size: 20px;
        cursor: pointer;
    }
    .box-content{
        height: 300px;
        padding: 0 .5rem;

        .box-card{
            margin-top: .5rem;
            margin-bottom: 1.5rem;
            border-radius: 50px 2rem 2rem 50px;
            .user-card{
                margin: 0;
            }
        }
        .scroller-action{
            height: 44px !important;
        }
    }
    .recommendation-skeleton{
        &:first-child{
            margin-top: 1rem;
        }
    }
}