@import '../../../assets/scss/variables.scss';

.profile-event-card{
    width: 100%;
    height: 56px;
    border-radius: 50px $radiusRegular $radiusRegular 50px;
    margin: 1.5rem 0 0 0;
    position: relative;

    .avatar{
        background-color: $gridMainDark;
        width: 64px;
        height: 64px;
        border-radius: 100%;
        position: absolute;
        left: 0px;
        top: -3px;
        z-index: 3;
    }

    .high-date{
        background: $secondaryColor;
        width: 70px;
        height: 100%;
        position: absolute;
        top: 0;
        left: 35px;
        border-radius: 0 $radiusRegular $radiusRegular 0;
        z-index: 2;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;

        span{
            width: 1%;
            font-size: 14px;
            color: $white;
            font-family: 'Barlow-Regular';
        }
    }

    .event-card-content{
        width: calc(100% - 120px);
        height: 100%;
        padding: .2rem;
        position: absolute;
        top: 0;
        left: 120px;

        .title, .date{
            margin: 0;
            text-transform: capitalize;
            font-size: 11px;
            font-family: 'Barlow-Medium', sans-serif;
        }
        .title{
            width: 100%;
            font-size: 1.10em;
            height: fit-content !important;
            line-height: 1.2;
            font-weight: bold;
        }
        .date {
            font-size: 0.85em;
            font-family: 'Barlow-Regular', sans-serif;
            font-weight: bold;
        }
        .bell-icon{
            width: 14px;
            height: 14px;
            margin: 2px 2px 0 0;
        }
        .count-data{
            *{font-size: 0.92em;}
            .follow-icon{
                width: 14px;
                height: 14px;
                margin-right: 0.5em;
            }
            .count{
                color: $secondaryColor;
                margin-right: 3px;
            }
            .text{
                text-transform: lowercase;
                font-family: 'Barlow-Regular', sans-serif;
                margin-right: 0.5em;
            }
        }
        .chat{
            width: 55%;
            padding-top: .15rem;
            margin-right: .2rem;
            .chat-count{
                margin-right: .2rem;
                color: $secondaryColor;
                font-size: 8px;
            }
            .chat-icon{
                width: 11px;
                height: 11px;
                float: right;
                margin-bottom: 2px;
                color: $secondaryColor;
            }
        }
    }
    .ping-tag{
        border-radius: $radiusLarge;
        width: 80%;
        height: 12px;
        position: absolute;
        top: -12px;
        right: 0px;
        padding: 0 .3rem;

        &.dark{background: $pingDark;}
        &.light{background: $pingLight;}

        .ping-user{
            margin-right: 3px;
            text-decoration: none;
            text-transform: capitalize;
            overflow: visible;
        }
        *{
            font-size: 9px;
            color: $white;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
        }
    }
}
