.archivements-modal {
  background-color: red !important;
  position: inherit !important;
  width: 100%;
  border-radius: 24px;

  .title {
    text-align: center;
    font-family: Barlow-Light,sans-serif;
    font-size: 1.5rem;
    text-transform: uppercase;
    margin-bottom: 1rem;
  }

  .archivements-modal--fields {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 2rem;

    &.is-special {
      margin-top: 1rem;
      grid-template-columns: 1fr;
      justify-items: center;
      & > div {
        width: 50%;
        .date-block-component {
          .date-block {
            max-height: 35px;
            border-radius: 24px;
            width: 100%;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            padding: 0.5rem;
            border: 1px solid transparent;
            &:hover {
              border-color: $highlight;
              cursor: pointer;
              //background-color: rgba(0, 0, 0, .1);
            }
          }
          div.date-wrap {
            flex: 1 1;
            margin-left: 0.75rem;
            display: flex;
            flex-direction: column;
            .date-top-label {
              display: none;
              visibility: hidden;
              text-transform: uppercase;
              font-weight: initial;
              font-family: 'Barlow-Light', sans-serif;
              font-size: 1.10rem;
              padding-left: 0rem;
              text-align: left;
              padding-bottom: 0.25rem;
            }
            .date-time {
              display: grid;
              grid-template-columns: 18px auto 18px auto;
              grid-column-gap: 8px;
              align-items: center;
              .MuiSvgIcon-root {
                color: $secondaryColor;
                width: 18px;
                height: 18px;
              }
              .date {
                font-weight: 400;
                font-size: 1rem;
              }
            }
          }
        }
      }
    }

    .field-label {
      text-transform: uppercase;
      font-weight: initial;
      font-family: 'Barlow-Light', sans-serif;
      font-size: 1.10rem;
      padding-left: 1rem;
      text-align: left;
      padding-bottom: 0.25rem;
    }

    .tag-select {
      border: 2px solid transparent;

      border-radius: $radiusMedium;

      &.tag-select-dark {
        background-color: $gridMainDark;
      }

      &.tag-select-light {
        background-color: $gridMainLight;
      }

      &:hover {
        &:not(.Mui-focused) {
          border-color: $calendarRow;
        }
      }

      &.Mui-focused {
        border-color: $highlight;
      }

      .Mui-disabled {
        cursor: not-allowed;
        color: #54d7d3;
      }

      input {
        padding-left: 1rem !important;
      }

      & > div {
        border-radius: $radiusMedium;

        div {
          padding: 0 !important;
          border-radius: $radiusMedium;
        }
      }

      svg {
        color: $secondaryColor;
      }

      fieldset {
        border-color: transparent;
      }

      .chip {
        padding: 0rem !important;
        font-size: 12px;
        height: 30px;
        background: $secondaryColor;

        svg {
          color: $eventCount;
          width: 18px;
        }

        .MuiChip-avatar {
          width: 24px;
          height: 24px;
          padding: 0 !important;

          img {
            border-radius: 100%;
            width: 24px;
            height: 24px;
          }
        }

      }
    }

    .abs-picker {
      z-index: 9999;
      padding-top: 0px;
      padding-bottom: 12px;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      border: 4px solid transparent;
      border-radius: $radiusMedium;

      .MuiPickersStaticWrapper-staticWrapperRoot {
        border-radius: $radiusMedium;
        //zoom: 0.9;
        .MuiPickersBasePicker-pickerView {
          min-height: 180px;
          justify-content: flex-start;

          .MuiPickersCalendarHeader-switchHeader {
            padding-top: 12px;
            padding-bottom: 6px;
            padding-left: 24px;
            padding-right: 24px;
            .MuiIconButton-root {
              padding: 0;
            }
          }

        }
        .MuiPickersCalendar-transitionContainer {
          margin-top: 0px;
          min-height: 180px;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          & > * {
            position: static;
          }
          & > div[class^="MuiPickersSlideTransition"] {
            height: 180px;
            overflow: hidden;
            position: absolute;
          }
        }

      }

      .footer-picker {
        width: 100%;
        padding-top: 12px;
        padding-left: 24px;
        padding-right: 24px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        &.is-spaced {
          padding-top: 12px;
          justify-content: space-between;
        }



        .submit-btn {

        }

      }
    }
  }
}
