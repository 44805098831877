@import '../../../../../assets/scss/variables.scss';

.is-dark-theme {
  .landing-highlights-container {
    .item {
      &.item-thumbnail {
        border: 6px solid $gridMainDark;
      }
    }
  }
}

.landing-highlights-container {
  display: grid;
  grid-template-columns: repeat(2, minmax(100px, 1fr));
  grid-column-gap: 1rem;
  grid-row-gap: 1rem;

  .item {
    border-radius: 12px;
    height: 210px;
    background-color: $gridMainLight;

    &.is-dark-theme {
      background-color: $gridMainDark;
    }

    &.item-thumbnail {
      overflow: hidden;
      background-color: black;
      position: relative;
      border: 6px solid $gridMainLight;

      .play-icon {
        background-color: rgba(0, 0, 0, 0.65);
        z-index: 222;
        position: absolute;
        display: flex;
        width: 100%;
        height: 100%;
        justify-content: center;
        align-items: center;
        cursor: pointer;

        svg {
          color: white;
          opacity: .8;
          font-size: 8rem;

          &:hover {
            opacity: 1;
          }
        }
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        transform: scale(2);
      }
    }

    &.item-placeholder {
      height: auto;
      min-height: 214px;
      display: flex;
      position: relative;
      flex-direction: column;

      &.is-dark-theme {
        background-image: url("../../../../../assets/images/pattern-dark-128x128.png");
        background-repeat: repeat;
      }

      &.is-light-theme {
        background-image: url("../../../../../assets/images/pattern-white-128x128.png");
        background-repeat: repeat;
      }

      justify-content: center;
      align-items: center;
      padding-left: 1rem;
      padding-right: 1rem;

      .animation {
        height: 96px;
        transform: translateY(0px);
      }

      span {
        text-align: center;
        font-family: "Barlow-Italic", sans-serif;
        font-size: 1.15rem;
      }
    }

  }
}

.mySwiper, .mySwiperCats {
  &.swiperCats {
    position: initial;
    .swiper-button-next {
      right: -2.5rem;
    }
    .swiper-button-prev {
      left: -2.5rem;
    }
  }

  .swiper-button-prev, .swiper-button-next, {
    color: white;
    opacity: 0.65;
    &:hover {
      opacity: 1;
      color: $highlight;
    }
  }

  span.swiper-pagination-bullet {
    &.swiper-pagination-bullet-active {
      background-color: $highlight;
    }

    &:not(.swiper-pagination-bullet-active) {
      background-color: #ddd;
      opacity: 0.65;
    }
  }
  &.mySwiperCats {
    padding-bottom: 32px;
    .swiper-pagination {
      &.swiper-pagination-clickable {
        &.swiper-pagination-bullets {
          bottom: 0px;
        }
      }
    }
  }
}

@media only screen and (max-width: 768px){
  .mySwiper {
    &.swiperCats {
      position: relative;

      .swiper-button-next {
        right: 0rem;
      }

      .swiper-button-prev {
        left: 0rem;
      }
    }
  }
}

