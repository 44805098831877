@import '../../assets/scss/variables.scss';

.foot {
    min-height: 50px;
    width: 100%;
    left: 0;
    bottom: 0;
    padding-bottom: 1em;

    .foot-link {
        text-transform: uppercase;
        font-family: "Barlow-Light", sans-serif;
        text-align: center;
        font-size: 12px;
        color: $white !important;

        &.active {
            border: none;
            color: $secondaryColor;
        }
    }

    .version {
        font-size: 0.6rem;
        color: $highlight;
    }

    .footer-links {
        width: auto;
        grid-template-columns: repeat(5, minmax(150px, 1fr));
    }

    .footer-section-apps {
        margin-bottom: 1em;
        text-transform: uppercase;
        color: $highlight;
        font-family: "Barlow-Light", sans-serif;
    }
}

@media only screen and (max-width: 767.98px) {
    .foot {
        height: auto;

        .version {
            margin: 1em 0;
        }

        .footer-links {
            width: 100%;
            grid-row-gap: 0.5em;
            grid-template-columns: repeat(3, minmax(80px, 1fr));
            margin-bottom: 0.5em;
            overflow: scroll;
        }

        .foot-link {
            font-size: 10px;
            color: $white !important;

            &.active {
                border: none;
                color: $secondaryColor;
            }

            &:last-child {
                margin: 0;
            }
        }
    }
}


.footer-extended {
    width: 90%;
    margin: 0 auto;
    display: grid;
    padding: 2rem 1rem 1rem;
    grid-template-columns: repeat(4, minmax(80px, 1fr));
    grid-template-areas:
        "second second second third";
    grid-row-gap: 0.5em;
    grid-column-gap: 0.5em;

    &.is-experimental-mode {
        padding: 2rem 1rem 1rem;
        grid-template-columns: repeat(1, minmax(80px, 1fr));
        grid-template-areas: "third""second";
    }

    .footer-section {
        &.footer-section-first {
            grid-area: first;
        }

        &.footer-section-second {
            grid-area: second;

            .wrap-links {
                padding-bottom: 1em;
                display: inline-flex;

                .footer-section-item {

                    &:nth-child(2),
                    &:nth-child(3),
                    &:nth-child(4),
                    &:nth-child(5) {
                        padding-left: 0.75em;
                    }
                }
            }

            .foot-copyright {
                font-size: 0.75rem;
                color: $highlight;
            }
        }

        &.footer-section-third {
            grid-area: third;
        }

        .footer-section-title {
            font-size: 1.20rem;
            color: $highlight;
            font-family: "Barlow-Medium", sans-serif;
            margin-bottom: 0.5rem;
        }

        .footer-section-item {
            text-transform: uppercase;
            color: $white;
            font-family: "Barlow-Light", sans-serif;

            a {
                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }

    @media (min-width: 768px) and (max-width: 1024px) {
        & {
            grid-template-columns: repeat(2, minmax(80px, 1fr));
        }

        .footer-section-second {
            .wrap-links {
                display: block !important;

                .footer-section-item {
                    padding-left: 0 !important;
                }
            }
        }

        .footer-section-third {
            .wrap-buttons {
                display: block !important;
            }
        }
    }

    @media (min-width: 0px) and (max-width: 767px) {
        & {
            width: 95% !important;
            padding-top: 1em !important;
            grid-template-columns: repeat(1, minmax(80px, 1fr));
            grid-template-areas:
                "third"
                "second";
        }

        .footer-section-second {
            .wrap-links {
                display: grid !important;
                grid-column-gap: 0.5em;
                grid-row-gap: 0.5em;
                grid-template-columns: repeat(2, minmax(80px, 1fr)) !important;
                padding-bottom: 2em !important;

                .footer-section-item {
                    padding-left: 0 !important;

                    a {
                        font-size: 0.80rem;
                    }
                }
            }

            .foot-copyright {
                text-align: center;
            }
        }

        .footer-section-third {
            padding-bottom: 1em !important;

            .footer-section-title {
                text-align: left;
            }

            .wrap-buttons {
                justify-content: left !important;
            }
        }
    }

    .slogan-box{
        height: 20px;

        .version{
            font-size: 9px;
            color: $gridContentLight !important;
        }
    }
}
