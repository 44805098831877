@import './variables.scss';

body {
    &.bg-transparent {
        background-color: transparent !important;
        min-height: 100vh;
    }
}

.app{
    min-height: 100vh;
    .navbar-container{
        width: 100%;
        max-height: 90px;
    }
    .app-content {
        background-color: $appBgLight;
        &.is-dark {
            background-color: $appBgDark;
        }
        margin: 0 auto;
        max-width: 1920px;
        width: 100%;
        //height: calc(100% - 60px);
        //las min-height: calc(100vh - 60px);
        flex: 1;
        display: flex;
        justify-content: center;
        flex-direction: column;

        .page-content {
            display: flex;
            flex: 1;
        }

        @media (max-width: 400px) {
            //max-width: fit-content;
            width: 100%;
        }

    }
    .coming-soon-page {
        flex: 1;

        .central-sign {
            transform: skewY(-10deg);
            border: 2px solid rgba($highlight, 1);
            border-radius: $radiusLarge;
            padding: 2.5em 2em 3em;
            position: relative;

            -moz-box-shadow:    inset 0 0 10px rgba($highlight, 0.25);
            -webkit-box-shadow: inset 0 0 10px rgba($highlight, 0.25);
            box-shadow:         inset 0 0 10px rgba($highlight, 0.25);

            h3 {
                font-family: "Barlow-Light", sans-serif !important;
            }
            .logo-box {
                position: absolute;
                top: -56px;
                left: 50%;
                transform: translateX(-50%);
                z-index: 25;
                .login-logo {
                    width: 72px;
                }
            }

            &:after {
                border-radius: $radiusLarge;
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background-image: linear-gradient(120deg, $gridMainDark, $highlight);
                opacity: .1;
            }

        }

    }
    @media (max-width: 960px) {
        .coming-soon-page {
            margin-top: 4em;
            .central-sign {
                margin-bottom: 2em;
            }
        }
    }
    @media (max-width: 600px) {
        .coming-soon-page {
            margin-top: 5em;
            .central-sign {
                transform: skewY(-10deg) scale(0.8);
                margin-bottom: 1em;
            }
        }
    }

    .phrase-placeholder-component {
        blockquote {
            margin: 1.5em 0 1.5em;
            padding: 0 1em 0 2.5em;
            position:relative;
        }
        blockquote:before
        {
            color: #ccc;
            content: "\201C";
            font-size: 5em;
            position:absolute;
            left:5px;
            top: 0.3em;
            line-height: 0.1em;
        }

        blockquote:after
        {
            color: #ccc;
            content: "\201D";
            font-size: 5em;
            position:absolute;
            right:3px;
            bottom: 0em;
            line-height: 0.1em;
        }
    }

}

@-webkit-keyframes AnimationName {
    0%{background-position:51% 0%}
    50%{background-position:50% 100%}
    100%{background-position:51% 0%}
}
@-moz-keyframes AnimationName {
    0%{background-position:51% 0%}
    50%{background-position:50% 100%}
    100%{background-position:51% 0%}
}
@keyframes AnimationName {
    0%{background-position:51% 0%}
    50%{background-position:50% 100%}
    100%{background-position:51% 0%}
}


@keyframes animateBorder {
    0% {
        transform:scaleX(0);
        transform-origin: left;
    }
    50%
    {
        transform:scaleX(1);
        transform-origin: left;
    }
    50.1%
    {
        transform:scaleX(1);
        transform-origin: right;

    }

    100%
    {
        transform:scaleX(0);
        transform-origin: right;

    }


}


#portal--tutorial-overlay {
    display: contents;

    .suscriptor-tutorial-overlay {
        -webkit-touch-callout: none; /* iOS Safari */
        -webkit-user-select: none; /* Safari */
        -khtml-user-select: none; /* Konqueror HTML */
        -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
        user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
        top: 0;
        left: 0;
        z-index: 85;
        position: fixed;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.60);
        backdrop-filter: blur(1px);

        .custom-button {
            position: absolute;
            top: 90%;
            left: 50%;
            transform: translate(-50%, -50%);
            z-index: 88;
            opacity: .90;

            &:hover {
                opacity: 1;
            }

        }

    }
}

.suscriptor-tutorial-container {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */

    z-index: 86;
    top: 64px;
    left: 0px;
    position: absolute;


    .suscriptor-tutorial-wrapper {
        min-width: 100px;
        max-width: 324px;

        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: center;

        img {
            top: -24px;
            left: 54px;
            position: absolute;
        }

        span {
            font-size: 1rem;
            font-family: 'Barlow-Medium', sans-serif;
            color: white;
            padding: 0.5rem 0rem;
            text-align: center;
            border-radius: 6px;
            border: 0px solid $highlight;
            background-color: transparent;
            line-height: 1.10;

            &.heading {
                color: $highlight;
                top: -24px;
                left: 84px;
                padding: 0.15rem 0.25rem;
                border-radius: initial;
                background-color: transparent;
                border: none;
                position: absolute;
                font-family: Barlow-SemiBold, sans-serif;
                font-size: 1.25rem;
                text-shadow: inherit;
                box-shadow: inherit;
            }

        }
    }
}
