@import '../../../assets/scss/variables.scss';

.user-info{
    width: 150px;
    min-height: 230px;
    height: auto;
    border-radius: 100px 100px 100px 100px;
    position: relative;

    .picture-wrap{
        border-radius: 100%;
        margin: 0;
        position: absolute;
        left: 4px;
        top: 4px;
        .picture{
            width: 142px;
            height: 142px;
            border-radius: 50%;
        }
    }
    .extra-data{
        position: absolute;
        width: 100%;
        left: 0;
        top: 160px;
        min-height: 135px;
        border-radius: 0 0 100px 100px;
        padding: 0 1rem;
        display: flex;
        flex-direction: column;
        align-items: center;

        &.wide{
            width: 250px;
            left: -50px;
        }

        .wrapUserNickname {
            width: 100%;
        }

        .nick{
            margin: 0;
            width: fit-content !important;
            text-transform: capitalize;
            text-align: center;
            font-size: 24px;
            font-family: 'Barlow-Medium' !important;
            text-overflow: ellipsis !important;
            white-space: nowrap !important;
            overflow: hidden !important;
        }
        .check-icon{
            color: $secondaryColor;
            margin-left: .5rem;
            fill: $secondaryColor;
            //position: absolute;
            right: 0;
        }
        .name{
            margin: 0;
            width: 100%;
            text-transform: capitalize;
            text-align: center;
            font-size: 14px;
            font-family: 'Barlow-Regular' !important;
            text-overflow: ellipsis !important;
            white-space: nowrap;
            overflow: hidden;
        }
        .social-icons{
            width: 100%;
            .icon{
                margin: .3rem;
                width: 15px;
                height: 15px;
                &.iconGrey {
                    path {
                        fill: rgba(0,0,0,.3);
                    }
                }
            }
        }
        .follow-btn, .unfollow-btn{
            font-size: 11px;
            background: $secondaryColor;
            border-radius: $radiusLarge;
            text-transform: capitalize;
            padding: 0 !important;
            color: $white;
            min-width: 60px !important;
            height: 26px;
            font-family: 'Barlow-Medium' !important;
        }
        .unfollow-btn{
            background: $gridContentLight;
            color: $red;
        }
    }
}

@media only screen and (max-width: 600px){
    .user-info{
        width: 122px;
        min-height: 180px;

        .picture-wrap{
            left: 5px;
            top: 5px;
            .picture{
                width: 112px;
                height: 112px;
            }
        }
        .extra-data{
            left: 0;
            top: 125px;
            padding: 0 .5rem;

            .nick{
                font-size: 18px;
            }
            .check-icon{
                margin-left: .1rem;
                font-size: 16px;
            }
        }
    }
}

@media only screen and (min-width: 600px) and (max-width: 1080px){
    .user-info{
        width: 110px;
        min-height: 150px;

        .picture-wrap{
            .picture{
                top: 2px;
                width: 102px;
                height: 102px;
            }
        }
        .extra-data{
            min-height: 100px;
            left: 0;
            top: 110px;
            padding: 0 .5rem;

            &.wide{
                width: 250px;
                left: -65px;
                min-height: 50px !important;
            }

            .nick{
                font-size: 18px;
            }
            .check-icon{
                margin-left: .1rem;
                font-size: 16px;
            }
            .social-icons{
                width: 100%;
                .icon{
                    margin: .2rem;
                    width: 13px;
                    height: 13px;
                }
            }
        }
    }
}
