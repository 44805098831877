@import '../../../assets/scss/variables.scss';

.event-info-wrapper {
  width: 960px;
  height: 680px;



  .event-info {
    display: grid;
    grid-template-columns: minmax(0, 1fr) 380px;

    height: 100%;

    .info {
      position: relative;
      padding: 1em;
      display: grid;
      grid-template-rows: 0.25fr minmax(185px, 230px) 1fr;
      grid-row-gap: 1em;
      justify-items: stretch;

      .floating-action-buttons {
        width: 100%;
        position: absolute;
        top: 0.8em;
        left: 0;
        display: flex;
        justify-content: space-between;
        padding-left: 1.5em;
        padding-right: 1em;

        .close-icon {
          //position: absolute;
          right: 0.5em;
        }

      }

      .header {
        padding: 0.5em 0 0 1em;
        align-items: flex-start;

        .title {
          line-height: 1.25;
          text-align: left;

          .event-title-container {
            margin-right: 0.25em;
          }
        }

        .subtitle {
          font-size: 0.9rem;
          text-align: left;
          text-transform: initial;
          font-family: "Barlow-Light", sans-serif !important;
        }

        .share-dropdown {
          align-self: center;

          .MuiButtonBase-root {
            padding: 4px !important;
          }

          svg {
            width: 18px;
            height: 18px;
            padding-bottom: 2px;

            & > g {
              & > * {
                fill: $highlight;
              }
            }
          }
        }

        .event-btn-actions {
          display: flex;
          flex-direction: row;
          margin-top: 1.5em;
          zoom: 0.85;

          button + button {
            margin-left: 0.5em;
          }
        }

        .tz-switcher-button {
          margin-left: 0.25rem;
          position: relative;
          cursor: pointer;
          background: #e4e4e4;
          border-radius: 20px;
          font-size: 0.7rem;
          padding: 0.15rem 0.55rem;
          padding-right: 2em;
          opacity: .5;

          &:hover {
            opacity: 1;
          }

          &.isDarkMode {
            color: black;
          }

          .MuiSvgIcon-root {
            position: absolute;
            width: 18px;
            height: 18px;
            top: 1px;
            right: 4px;

            path {
              fill: lighten(black, 25);
            }
          }

        }

        .tz-switcher-popover {
          height: 300px;
        }

        .event-sub-icon {
          margin: .15rem 0 0 1rem;
          height: 28px;
          cursor: pointer;
        }

        .ping-icon {
          margin: .3rem .5rem 0 0;
          cursor: pointer;
        }

        .custom-date-countdown {
          border-radius: 12px;
          border-top-left-radius: 0;
          padding: 0.15rem 0.45rem;

          margin-top: 0.20rem;
          font-size: 0.65rem;
          font-family: Barlow-Light, sans-serif;

          .countdown {
            font-weight: 600;
          }

          &.is-light {
            background-color: #f1f1f1;
          }

          &.is-dark {
            color: black;
            background-color: #838997;
          }
        }

      }

      .info-middle {
        min-height: 100px;
        display: grid;
        grid-template-columns: minmax(0, 0.65fr) minmax(0, 1fr) minmax(0, 0.60fr);
        grid-gap: 1em;
        grid-template-areas: "tags desc profile";
        align-items: center;

        .poster {
          grid-area: poster;
          display: none;
        }

        .tags {
          grid-area: tags;
          min-height: 150px;
          font-size: 0.70rem;
          justify-content: center;

          .icon {
            width: 16px;
            height: 16px;
            margin-left: .25rem;

            &.eye {
              min-height: 12px;
              height: 12px;
            }
          }

          .parent {
            font-family: "Barlow-Light", sans-serif;
            font-size: .9rem;
            display: flex;
            align-items: center;

            &.clickable {
              cursor: pointer;
            }

            & > * {
              min-height: 16px;
            }

            .text {
              font-family: "Barlow-Light", sans-serif;
              font-size: 0.88rem;
              margin-left: 0.25em;
              margin: .1rem;
            }

            .text_more {
              font-family: "Barlow-Light", sans-serif;
              font-size: 0.68rem;
              margin: .1rem;
            }

            .count {
              font-family: "Barlow-Medium", sans-serif;
              font-weight: bold;
              font-size: 0.9rem;
              color: $highlight;
              margin: .1rem;
            }

            .icons {
              width: fit-content;
              float: left;
              padding-right: .2rem;
              height: fit-content;

              display: flex;
              flex-wrap: nowrap;
              justify-content: center;
              align-items: center;
              flex-direction: row-reverse;

              .guest-icon {
                width: 16px;
                height: 16px;
                border-radius: 100%;
                margin-left: -6px;
                object-fit: cover;

                &:hover {
                  zoom: 1.2;
                  z-index: 0;
                }

                &.with-background{
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  background-color: $highlight;
                  background-position: center;
                  background-size: cover;
                  background-repeat: no-repeat;
                  color: black;
                  font-weight: bolder;
                  font-size: 0.6rem;
                }
              }

              .fake-user-circle {
                display: inline-block;
                margin-left: -5px;
                width: 16px;
                height: 16px;
                border-radius: 100%;
                background-color: rgba(black, 0.25);
                font-size: 11px;
                line-height: 1;
                padding-left: 3px;
              }

            }

            .location-label {

            }
          }

          .wrapCats {
            cursor: pointer;
            margin: .1rem;
            width: 95%;
            text-overflow: ellipsis !important;
            white-space: nowrap !important;
            overflow: hidden !important;
            text-align: right;

            span {
              margin-right: 0.25em;
              border-bottom: 1px dotted transparent;

              &:hover {
                border-bottom-color: $highlight;
              }
            }
          }

          .cats {
            font-family: "Barlow-Light", sans-serif;
            font-size: 0.88rem;
            width: 100%;
            flex-wrap: nowrap;

            .icon {
              cursor: pointer;
            }
          }
        }

        .description {
          grid-area: desc;
          min-height: 120px;
          border-radius: $radiusMedium;
          padding: .6rem .5rem .5rem;
          font-size: 0.75rem;
          display: flex;
          align-items: center;

          &.isEmpty {
            display: flex;
            align-items: center;
            text-align: center;
            justify-content: center;

          }

          .description-content {
            padding: .25em;
            line-height: 1.5;
            margin: 0;
            font-size: 0.8rem !important;
            text-align: justify;
            word-break: break-word;

            span.read-more {
              cursor: pointer;
              text-decoration: underline;
              color: #40cdbf;
            }
          }

          .description-empty {
            width: 70%;
            color: darkgray;
            font-style: italic;
            font-size: 0.8rem;
            text-align: center;
            -webkit-touch-callout: none;
            -webkit-user-select: none;
            -khtml-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
          }

        }

        .user-info {
          border-radius: 0;
          width: 100%;
          height: auto;
          min-height: 150px;
          grid-area: profile;
          //top: -45px;
          align-self: self-start;

          .picture-wrap {
            width: 100%;
            text-align: center;
            //background-color: rgb(40, 54, 84);;
            top: 0;
            left: 0;
            position: relative;
            border-radius: 0;
            border-top-left-radius: 100%;
            border-top-right-radius: 100%;
            padding-top: 5px;

            .picture {
              width: 128px;
              height: 128px;
            }
          }

          .extra-data {
            //background-color: rgb(40, 54, 84);;
            top: 0;
            padding: 0;
            position: relative;
            min-height: auto;
            padding-bottom: 0.5em;
            border-bottom-left-radius: 33px;
            border-bottom-right-radius: 33px;

            .wrapUserNickname {
              width: 80%;
              height: 24px;
            }

            .nick {
              font-size: 1.25rem;
              letter-spacing: .05rem;
              line-height: 1.5;
            }

            .name {
              height: 12px;
              font-size: .8rem;
              letter-spacing: .025rem;
              line-height: 1;
            }

            .social-icons {
              display: grid;
              height: 24px;
              grid-template-columns: repeat(6, 12px);
              grid-column-gap: 0.4em;
              align-content: center;

              a {
                height: 12px;
              }

              .icon {
                margin: 0;
                width: 12px;
                height: 12px;
              }
            }

            .userextra-button {
              font-size: 0.65rem;
              background: #03CDBF;
              border-radius: 33px;
              text-transform: capitalize;
              padding: 0 !important;
              color: #ffffff;
              min-width: 40px !important;
              height: 26px;
              font-family: 'Barlow-Medium', sans-serif !important;

              &.is-you {
                opacity: 0.8;
                background-color: rgba($gridContentLight, 0.25);
              }

            }

          }
        }

      }

      .tabs {
        min-width: 0;

        .menu {
          min-height: 40px;
          max-height: 40px;

          .MuiTabs-centered {
            display: grid;
            grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));

            button {
              padding: 0;
              min-height: 40px;
              max-height: 40px;
              text-transform: capitalize;
              border-radius: 23px 23px 0 0;
              font-size: 1em !important;
              width: 100%;
              min-width: 100%;
              font-family: "Barlow-Regular", sans-serif !important;

              &.none{
                opacity: 0.7;
              }

              &.select-dark {
                background: $gridMainDark;
                color: $white;
              }

              &.select-light {
                background: $gridMainLight;
                color: $eventCount;
              }
            }

          }

          .indicator {
            display: none !important;
          }
        }

        .section {
          font-family: 'Barlow-Regular', sans-serif !important;
          border-radius: $radiusMedium;
          border-top-right-radius: 0;
          border-top-left-radius: 0;
          height: calc(100% - 40px);
          padding: 1rem;

          .user-card {
            &:first-child {
              margin: .5rem 0 0 !important;
            }
          }

          .scroller-action {
            height: 34px;
          }

          .tab-empty-content {
            opacity: .5;
            -webkit-touch-callout: none;
            -webkit-user-select: none;
            user-select: none;
          }


          .wrap-users-guest {
            display: flex;
            flex-direction: column;
            margin-bottom: 1em;

            .invitation-status {
              margin: 0 auto;
              text-align: center;
              font-size: 0.75rem;
              padding: 0.25rem 0.75rem;
              border-bottom-left-radius: $radiusSmall;
              border-bottom-right-radius: $radiusSmall;

              .status-ok {
                font-size: 0.80rem;
                color: $highlight;
              }

              .status-pending {
                font-size: 0.80rem;
                color: $red;
              }

            }
          }
        }

      }

      .input-box {
        max-width: 100%;
        overflow: hidden;

        .comment-form {
          border-radius: $radiusMedium;
          justify-content: space-between;

          .comment-input {
            width: 100%;
            letter-spacing: normal;
            font-size: 0.8rem !important;
            font-family: 'Barlow-Regular', sans-serif;
            padding: .5rem 0 .5rem 1rem;

            .comment-input__suggestions {
              background-color: red !important;
              padding: 0.25rem;
            }

            .comment-input__highlighter {
              &.comment-input__highlighter--singleLine {
                top: -1px !important;
              }
            }


            input {
              padding: .5rem 0 .5rem 1rem;
              width: 100% !important;
              margin: 0;
              background: transparent !important;
              font-size: 0.8rem !important;
              border: none !important;
              border-radius: $radiusMedium;

              &:focus {
                outline: none;
              }
            }

            &.isDark {
              input {
                color: $white !important;
              }
            }

            &.isLight {
              input {
                color: $gridMainDark !important;
              }
            }

          }

          .send-btn {
            font-size: 12px;
            margin-right: .25rem;
            cursor: pointer;
            background: $secondaryColor;
            color: $white;
            padding: .35rem .7rem;
            height: 30px;
            border-radius: $radiusMedium;
            text-transform: capitalize;
          }
        }

        .refElSuggestions {
          @keyframes lds-dual-ring {
            0% {
              transform: rotate(0deg);
            }
            100% {
              transform: rotate(360deg);
            }
          }

          .comment-input__suggestions__loadingIndicator {
            margin-top: 4px;
            margin-bottom: 4px;

            width: 100%;
            height: 8px;

            text-align: center;
            font-size: 11px;

            .comment-input__suggestions__loadingIndicator__spinner {
              display: inline-block;

              background-color: #999;

              height: 100%;
              width: 2px;

              margin-left: 3px;
              margin-right: 3px;

              animation: lds-dual-ring 1.2s linear infinite;
            }

          }
        }
      }

    }

    .poster {
      position: relative;
      overflow: hidden;
      background-color: black;

      .bg-poster {
        -webkit-filter: blur(5px) contrast(125%) brightness(75%);
        filter: blur(5px) contrast(125%) brightness(75%);
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        height: 100%;
      }

      .poster-overlay {
        position: absolute;
        width: 100%;
        height: 100%;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }

        .floating-action-buttons-2 {
          width: 100%;
          position: absolute;
          display: flex;
          flex-direction: row-reverse;
          justify-content: space-between;
          padding-right: 0.15em;

          .upload-btn, .submit-btn {
            margin-top: 1em;
            margin-right: 1em;
            height: fit-content;
            color: $white;
            line-height: 0;

            &.label {
              padding: .35rem .5rem .24rem;
            }
          }

          .poster-input {
            display: none !important;
          }

          .cancel-btn {
            margin-left: 1em;
            margin-top: 1em;
            line-height: 0;
          }

        }

        .floating-action-buttons {
          min-width: 30%;
          position: absolute;
          top: 3em;
          right: 1em;
          display: flex;
          flex-direction: column;
          justify-content: space-between;

          button {
            font-family: "Barlow-Light", sans-serif !important;
            display: flex;
            flex-direction: column;
            padding-right: 0.35em !important;
            margin-bottom: 0.5em;

            .MuiButton-Label {
              justify-content: flex-start;
            }

            &.delete-btn {
              background-color: $red;
            }

            .icon {
              width: 14px;
              height: 14px;
              margin-right: 0.2em;
            }
          }

        }
      }

    }


    &.is-dark {
      .info {
        .info-middle {
          .user-info {
            .picture-wrap, .extra-data {
              background-color: $gridMainDark;
            }

            .extra-data {
              .userextra-button.is-you {
                color: $gridMainDark;
                background-color: rgba($gridMainLight, 0.7);
              }
            }
          }
        }
      }
    }

    &.is-light {
      .info {
        .floating-action-buttons {
          button.delete-btn {
            color: white;
          }
        }

        .info-middle {
          .user-info {
            .picture-wrap, .extra-data {
              background-color: $gridMainLight;
            }

            .extra-data {
              .userextra-button.is-you {
                color: $gridMainLight;
                background-color: rgba($gridMainDark, 0.7);
              }
            }
          }
        }
      }
    }

  }

}


.event-info-wrapper {
  .event-info {
    &.is-mobile {
      grid-template-areas: "header" "poster" "info";
      grid-template-rows: 64px minmax(0, 200px) minmax(0, 1fr);

      .modal-header {
        grid-area: header;
        padding: 1em 0em;

        .close-icon {
          font-size: 48px;
          color: $highlight;
          width: 48px;
          height: 64px;
        }

        .user-info {
          width: 100%;
          height: 64px;
          min-height: 100%;
          overflow: hidden;
          border-radius: 0;
          flex-direction: row;
          justify-content: flex-start;

          .picture-wrap {
            position: relative;
            top: 0;
            left: 0;
            background-color: transparent;
            width: 48px;
            height: 48px;

            .picture {
              width: 48px;
              height: 48px;
            }
          }

          .extra-data {
            position: relative;
            top: 0;
            min-height: auto;
            background-color: transparent;
            border-radius: 0;
            text-align: left;

            .wrapUserNickname {
              justify-content: flex-start;
            }

            .name {
              text-align: left;
            }

            .social-icons {
              display: none;
            }

            .custom-button {
              display: none;
            }
          }
        }
      }

      .poster {
        grid-area: poster;
        position: relative;
        display: flex;
        width: 100%;
        height: 280px;
        justify-content: center;
        align-items: center;

        .icon {
          position: absolute;
          top: 40%;
          left: 50%;
          transform: translate(-40%, -50%);
          z-index: 2;
          color: white;

          &:hover {
            svg {
              font-size: 200%;
            }
          }

        }

        &:after {
          z-index: 0;
          content: ""; // ::before and ::after both require content
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-image: linear-gradient(0deg, $darkHover, $background);
          opacity: .7;
        }
      }

      .info {
        grid-area: info;
        grid-template-rows: 0.25fr 0.5fr 1fr 1fr;
        border-top-left-radius: 48px;
        border-top-right-radius: 48px;
        width: 100%;
        grid-row-gap: 0.5em;
        padding: 0;

        .event-header {
          &.is-light {
            .title-container {
              .box-title {
                .share-dropdown {
                  align-self: center;

                  .MuiButtonBase-root {
                    padding: 4px !important;
                  }

                  svg {
                    width: 16px;
                    height: 16px;
                    padding-bottom: 2px;

                    & > g {
                      & > * {
                        fill: $highlight;
                      }
                    }
                  }
                }
              }
            }
          }

          &.is-dark {
            .title-container {
              .box-title {
                .share-dropdown {
                  align-self: center;

                  .MuiButtonBase-root {
                    padding: 4px !important;
                  }

                  svg {
                    width: 16px;
                    height: 16px;
                    padding-bottom: 2px;

                    & > g {
                      & > * {
                        fill: $highlight;
                      }
                    }
                  }
                }
              }
            }
          }
        }

        .header {
          padding: 1.75em 2em 0;

          .event-btn-actions {
            display: none;
          }

          .event-sub-icon {
            display: none;
          }
        }

        .info-middle {
          height: 100%;
          min-height: auto;
          grid-template-columns: minmax(0, 1fr) minmax(0, 0.60fr);
          align-items: flex-start;
          grid-auto-rows: minmax(min-content, max-content);

          .description {
            display: none;
          }

          .event-btn-actions {
            position: relative;
            min-height: 130px;
            justify-content: center;
            align-items: stretch;

            .suscriptor-tutorial-actionable {
              z-index: 86;
              & > button {
                width: 100%;
                box-shadow: 0px 0px 5px 5px #00d1bf40;
              }
            }

            .suscriptor-tutorial-container {
              top: 84px;
              left: -140px;

              .suscriptor-tutorial-wrapper {
                img {
                  top: -32px;
                  left: initial;
                  right: 24px;
                  -moz-transform: scaleX(-1);
                  -webkit-transform: scaleX(-1);
                  -o-transform: scaleX(-1);
                  transform: scaleX(-1);
                  width: 32px;
                  height: 32px;
                  object-fit: cover;
                }
                span.heading {
                  font-size: 1.75rem;
                  left: initial;
                  right: 54px;
                  top: -32px;
                }
                span {
                  font-size: 1.5rem;
                }
              }
            }

            .advanced-button + .advanced-button {
              margin-top: 0.75em;
            }

            .custom-button + .custom-button {
              margin-top: 0.5em;
            }

            .custom-button {
              width: 100%;
              height: auto !important;
              line-height: 1;
              background-color: transparent;
              border: 1px solid $highlight;
              padding: 0 0.5em !important;
              padding-left: 1em !important;

              & > span:first-child {
                justify-content: flex-start;

                img {

                }

                span {
                  white-space: nowrap;
                  text-overflow: ellipsis;
                  overflow: hidden;
                  display: inherit;
                  align-items: center;
                  height: 30px;
                  font-size: 0.85rem;
                  font-weight: bold;
                  font-family: "Barlow-Regular", sans-serif;
                  color: $highlight;
                }

              }

              .icon {
                margin-right: 0.4em;
                width: 24px;
                height: 24px;
              }
            }
          }

        }

        .tabs {
          height: 100%;

          .menu .MuiTabs-centered {
            grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
          }

          .section {
            min-height: 300px;
            word-break: break-word;
            border-radius: 0;
          }
        }

        .input-box {
          min-height: 50px;
          padding-bottom: 1em;

          .comment-form {
            border-radius: 0;
            height: 100%;
          }

        }

      }

    }

    &.is-light {
      .header {
        .title-container {
          .share-dropdown {
            svg {
              padding-bottom: 2px;

              & > g {
                & > * {
                  fill: $highlight !important;
                }
              }
            }
          }
        }
      }
    }
  }
}

.tzSearchForm {
  margin-bottom: 0.15em;
  border-bottom: 1px solid $primaryColor;

  .search-input {
    width: 100%;
    padding: 0.5em 1em;
  }
}

.tzList {
  .tzItem {
    transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

    display: flex;
    align-items: center;
    padding: 0rem 1.25rem;

    &:hover {
      background-color: rgba(0, 0, 0, 0.04);
    }

    &.tzItemSelected {
      background-color: rgba(0, 0, 0, 0.04);
    }
  }
}

.event-modal-poster {
  display: flex;
  align-items: center;
  justify-content: center;

  .close-icon {
    position: fixed;
    color: white;
    top: 0.25em;
    right: 0.25em;
    font-size: 2.5em;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

@media only screen and (max-width: 768px) {
  .event-info-wrapper {
    width: 100%;

    .event-info {
      grid-template-columns: minmax(0, 1fr);

      .info {

      }

      .poster {
        display: none;
      }

    }
  }
}

@media only screen and (max-width: 600px) {
  .event-info-wrapper {
    width: 100%;
    height: 100%;
  }
}


.content-popover-share {
  padding: 0em;

  .MuiList-root {
    .MuiListSubheader-root {
      line-height: 32px;
      font-family: "Barlow-Regular", sans-serif;
      background-color: rgba(black, 0.05);
    }

    .MuiListItemIcon-root {
      min-width: 36px !important;
    }

    .MuiListItemText-root {
      .MuiTypography-root {
        font-family: "Barlow-Regular", sans-serif;
      }
    }

    .react-share__ShareButton {
      display: block;
      width: 100%;
    }
  }

  &.is-light {
    .MuiList-root {
      .MuiListItemIcon-root {
        svg {
          & > g, & > path {
            fill: $primaryColor;

            & > * {
              fill: $primaryColor;
            }
          }
        }
      }
    }
  }

  &.is-dark {
    .MuiList-root {
      .MuiListItemIcon-root {
        svg {
          & > g, & > path {
            fill: white;

            & > * {
              fill: white;
            }
          }
        }
      }
    }
  }

}

.cat-modal {
  background: transparent !important;
  box-shadow: none !important;
  padding: 0 !important;

  .all-cats {
    position: relative;
    border-radius: $radiusSmallRegular;
    //background-color: darken($gridContentDark, 20);
    padding: 0.5em;
    width: 200px;
    border: 1px solid rgba(darken($gridContentDark, 20), 0.25);

    span {
      line-height: 1;
      font-size: 0.88em;
      font-family: "Barlow-Light", sans-serif;
      margin: 0.15em;
      padding: 0.05em;
      border-bottom: 1px dotted $highlight;
      cursor: pointer;
    }
  }

  .event-private-list-users {
    width: 200px;
    padding: 0.5em;
    border-radius: $radiusSmallRegular;
    border: 1px solid rgba(darken($gridContentDark, 20), 0.25);

    .subtitle {
      color: $highlight;
      font-size: 0.75rem;
      margin-top: 0.25rem;
      padding-left: 0.25rem;
      padding-right: 0.25rem;
      margin-bottom: 0.5rem;
    }

    .user-card {
      height: 33px;
      margin-top: 0;

      .avatar {
        position: relative;
        width: 32px;
        height: 32px;
        top: initial;
        left: initial;
      }

      .user-card-content {
        width: calc(100% - 38px);
        height: 32px;
        padding-left: 0.75em;
        position: relative;
        left: 0;
      }
    }

    .user-card + .user-card {
      margin-top: 0.40em;
    }
  }
}

.video_modal {
  display: flex;
  align-items: center;
  justify-content: center;

  .video_wrapper {
    width: 60%;
    margin: auto;
    color: $white;

    &.mobile{
      width: 100%;

      .close-icon{
        left: calc(100% - 2rem);
        svg{
          font-size: 2rem;
        }
      }
    }

    .close-icon{
      position: relative;
      left: 100%;
      svg{
        font-size: 2rem;
      }
    }

    .iframe_wrapper {
      position: relative;
      padding-bottom: 56.25%;
      height: 0;
      display: flex;
      justify-content: center;
      align-items: center;

      iframe {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
      }
    }
  }
}
