@import '../../../../../assets/scss/variables.scss';

.landing-teams-cover-component {
  margin-bottom: 1rem;
  .landing-teams-wrap {
    padding-left: 0.15rem;
    padding-right: 0.15rem;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr;
    grid-row-gap: 0.5rem;
    grid-column-gap: 0.5rem;

    .landing-teams__item-special-paragraph {
      width: 100%;
      padding: 2px 12px;
      font-size: 0.90rem;
      font-style: italic;
      text-align: center;
    }

    .landing-teams__item-special {
      width: 100%;
      padding: 2px 12px;
      border-radius: $radiusMedium;

      font-size: 0.90rem;
      font-family: 'Barlow-Light', sans-serif;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      &:hover {
        & > div {
          cursor: pointer;
          color: $highlight;
          border-color: $highlight;
        }
      }
      & > div {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        border-bottom: 1px dashed white;
      }
    }

    .landing-teams__item {
      padding: 4px;
      padding-right: 6px;
      border-radius: $radiusMedium;
      background-color: $gridContentLight;

      &.dark{
        background-color: $gridContentDark;
      }

      height: 100%;
      display: grid;
      grid-template-columns: 48px 1fr auto;
      grid-template-rows: auto;
      grid-template-areas:
            "avatar user btn";

      .landing-teams__item-first-column {
        grid-area: avatar;
        flex: 1;
        width: 100%;

        a {
          display: flex;
          align-self: center;
          img {
            width: 48px;
            height: 48px;
            border-radius: 100%;
            object-fit: cover;
          }
        }


      }
      .landing-teams__item-second-column {
        margin-left: 0.75rem;
        grid-area: user;
        line-height: 1.35;
        h2 {
          margin: 0px;
          font-family: 'Barlow-Medium', sans-serif;
          font-size: 1rem;
        }
        p {
          margin: 0px;
          font-size: 0.75rem;
        }
      }
      .landing-teams__item-third-column {
        grid-area: btn;
        justify-content: center;
        width: 100%;
        button {
          line-height: 0;
          background: $highlight;
          color: $white;
          padding: .25rem .75rem;
          border-radius: 33px;
          width: 100%;

          &.unfollow{
            background: $pingLight;
          }
        }
      }
    }

  }
}

.embed-teams {
  .embed-teams-container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(64px, 1fr) ) ;
    gap: 1rem;
  }
  .landing-teams__item {
    position: relative;
    width: fit-content;
    border: 2px solid transparent;
    background-color: $gridMainLight;

    display: flex;
    align-items: center;
    justify-content: center;

    &.is-following {
      opacity: 1;
      border-color: $highlight;
      .campanita {
        .triangle {
          border-bottom-color: rgba(0, 0, 0, .70) !important;
        }
        img {

        }
      }
    }
    &.not-following {
      opacity: .5;
      border-color: black;
      .campanita {
        .triangle {
          border-color: transparent !important;
        }
        background-color: rgba(0, 0, 0, .5);
        img {
          filter: brightness(0) invert(1);
        }
      }
    }
    &:hover {
      opacity: 1;
      .campanita {
        opacity: .25;
      }
    }

    a {
      display: inherit;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .campanita {
      position: absolute;
      width: 100%;
      height: 100%;
      right: 0px;
      bottom: 0px;

      .inside {
        width: 100%;
        height: 100%;
        position: relative;
        .triangle {
          position: absolute;
          top: 0;
          left: 0;
          width: 0;
          height: 0;
          border-bottom: 66.48px solid red;
          border-left: 66.48px solid transparent;
        }
      }


      .campana {
        position: absolute;
        bottom: 6px;
        right: 6px;
      }

      img {
        width: 18px;
        height: 18px;
      }
    }

  }


  &.valorant-champions2021 {
    .landing-teams__item {
      padding: 8px 6px !important;
      .landing-teams__item-first-column {
        img {
          width: 54px;
          height: 54px;
        }
      }

      .landing-teams__item-second-column {
        h2 {
          font-size: 1.15rem;
        }
        p {
          display: none;
        }
      }

    }
  }

}
