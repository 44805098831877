@import '../../../assets/scss/variables.scss';

.explore-featured-users--main--item {
  position: relative;

  .explore-featured-users--main--item-actions {
    position: absolute;
    z-index: 999;
    top: 0.25rem;
    right: 0.25rem;
    .follow-btn, .unfollow-btn {
      font-size: 12px;
      background: $secondaryColor;
      border-radius: $radiusLarge;
      text-transform: capitalize;
      color: $white;
      min-width: 60px !important;
      height: 26px !important;
      font-family: 'Barlow-Medium', sans-serif !important;
      padding: 0 0.5em !important;

      &:hover {
        background: $secondaryColor;
        color: $white;
      }
    }

    .unfollow-btn{
      background: $gridContentLight;
      color: $red;
      &:hover{
        background: $gridContentLight;
        color: $red;
      }
    }

  }

  .explore-featured-users--main--item-content {
    width: 100%;
    height: 224px;
    border-radius: 18px;
    background-color: black;
    position: relative;

    &:hover {
      .explore-featured-users--main--item-background {
        opacity: 0.75;
      }
    }

    .explore-featured-users--main--item-background {
      width: 100%;
      height: 224px;
      border-radius: 18px;
      opacity: 0.50;
    }

    .explore-featured-users--main--item-info {
      position: absolute;
      left: 0.65rem;
      bottom: 0.65rem;


      display: flex;
      flex-direction: row;
      align-items: center;
      .explore-featured-users--main--item-info-avatar {
        img {
          width: 64px;
          height: 64px;
          border-radius: 50%;
        }
      }
      .explore-featured-users--main--item-info-inline {
        margin-left: 0.75rem;
        color: white;
        line-height: 1.2;
        .explore-featured-users--main--item-info-title {
          margin-top: 0;
          margin-bottom: 0;
          font-size: 24px;
          font-family: Barlow-SemiBold, sans-serif;
          .check-icon {
            color: $secondaryColor;
            margin-left: .25rem;
            vertical-align: center;
            fill: $secondaryColor;
          }
        }
        .explore-featured-users--main--item-info-tagline {
          font-size: 18px;
          font-family: Barlow-Light, sans-serif;
        }
      }
    }
  }
}
