@import '../../../assets/scss/variables.scss';


.landing-upcoming-events-container {
  display: grid;
  grid-template-columns: repeat(1, minmax(100px, 1fr));
  grid-column-gap: 1rem;
  grid-row-gap: 1rem;

  .event-score-card {
    width: 100%;
    &.destacado {
      border-color: $highlight;

      &:after {
        content: "";
        position: absolute;
        //left: 1em;
        width: 100%;
        height: 100%;
        background-color: rgba(#00D1BF, 0.1);
        animation: animateBorder 4s linear infinite;
        z-index: -55;
      }
    }

    position: relative;
    border-radius: 8px;
    display: grid;
    grid-template-columns: 0.65fr 1fr 0.15fr 1fr;
    padding: 0.5rem 0.75rem;
    align-content: center;
    border: 2px solid transparent;

    &.v-new {
      grid-template-columns: 0.15fr 1fr;
      .event-score-info-col {
        display: flex;
        flex-direction: column;
        .tournament--name {
          font-size: 0.8rem;
          padding: 0.1rem 0.4rem;
        }
        .event-score--wrapped {
          display: grid;
          grid-template-columns: 1fr 0.15fr 1fr;
        }
      }
    }

    &:hover {
      cursor: pointer;
    }

    &.is-dark-theme {
      background-color: $gridContentDark;
      background-repeat: repeat;

      &:hover {
        background-color: rgba($gridContentDark, 0.5);
      }
    }

    &.is-light-theme {
      background-color: $gridContentLight;

      &:hover {
        background-color: rgba($gridContentLight, 0.5);
      }
    }

    .event-score-team {
      display: inline-flex;
      justify-content: flex-start;
      align-items: center;

      span {
        flex: 0.9;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      img {
        margin-right: 0.5rem;
        margin-left: 0.5rem;
        width: 24px;
        height: 24px;
      }

      font-family: Barlow-Regular, sans-serif;
      font-size: 1rem;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      &.is-team-guest {
        justify-content: flex-end;
        text-align: right;

        img {
          margin-left: 0.5rem;
        }
      }
    }

    .event-score-center {
      font-size: 1rem;
      background-color: rgba($eventCount, 0.1);
      border-radius: 6px;
      text-align: center;
      color: $highlight;
      align-self: center;
      line-height: 0px;

    }

    &.not-events {
      display: flex;
      justify-content: center;
      align-items: center;

      &:hover {
        cursor: default;
      }
    }

    .event-score-info {
      display: flex;
      justify-content: center;
      align-items: center;
      padding-right: 0.5rem;
      font-family: "Barlow-Regular", sans-serif;

      img {
        width: 24px;
        margin-right: 0.5rem;
      }
    }
  }
}
