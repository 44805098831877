@import '../../../assets/scss/variables.scss';

.user-card{
    width: 100%;
    height: 40px;
    border-radius: 50px $radiusMedium $radiusMedium 50px;
    position: relative;
    margin: 1.5rem 0 0;

    .avatar{
        width: 48px;
        height: 48px;
        border-radius: 100%;
        position: absolute;
        left: 0px;
        top: -6px;
        cursor: pointer;
    }

    &.colored{
        background: $secondaryColor;
        height: 38px;
        border-radius: 100px;
        position: relative;
        margin: .5rem 0 0 0;

        *{
            color: $white;
        }
        .avatar{
            top: 2px;
            width: 34px;
            height: 34px;
            left: 2px;
        }
        .user-card-content{
            width: calc(100% - 50px - 30px) !important;
            left: 50px;
            margin-top: 5px;
            .name{
                font-size: 14px;
                width: 100% !important;
                text-overflow: ellipsis !important;
                white-space: nowrap !important;
                overflow: hidden !important;
                width: -webkit-fill-available;
            }
        }
        .close-icon{
            font-size: 18px;
            position: absolute;
            right: .7rem;
            top: .67rem;
            color: $white !important;
            cursor: pointer;
        }
    }

    .user-card-content{
        width: calc(100% - 22% - (60px));
        position: absolute;
        top: 0;
        left: 60px;
        height: 40px;

        .name{
            margin: 0;
            text-transform: capitalize;
            font-size: 12px;
            font-family: "Barlow-SemiBold", sans-serif;
            text-overflow: ellipsis !important;
            white-space: nowrap !important;
            overflow: hidden !important;
            width: 100%;
            width: -webkit-fill-available;
        }
        .else{
            margin: 0;
            text-transform: capitalize;
            font-size: 11px;
            font-family: "Barlow-Regular", sans-serif;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            .num {
                font-size: 12px;
                color: $highlight;
            }
        }
        .else + .else {
            margin-left: 0.50em;
        }
        .user-int{
            text-transform: uppercase;
            margin: 0 .2rem 0 0 ;
            font-size: 11px;
            width: fit-content;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
        }
    }

    &.is-box-recommendations {
        .user-card-content {
            width: calc(100% - 22% - (70px));
        }
    }


    .user-card-action{
        width: 22%;
        position: absolute;
        top: 7px;
        right: 7px;

        .follow-btn, .unfollow-btn{
            font-size: 11px;
            background: $secondaryColor;
            border-radius: $radiusLarge;
            text-transform: capitalize;
            padding: 0 !important;
            color: $white;
            min-width: 60px !important;
            height: 26px !important;
            font-family: 'Barlow-Medium', sans-serif !important;
            float: right;
        }
        .unfollow-btn{
            background: $gridContentLight;
            color: $red;
        }
        .leave-event-guest-btn {
            font-size: 11px;

            &.is-light {
                color: $highlight;
                background-color: $bgColor;
                &:hover {
                    background-color: rgba(darken($secondaryColor, 10), 0.1);
                }
            }

            &.is-dark {
                background: transparent;
                color: $white;
            }

            border: 1px solid $secondaryColor;
            float: right;
            padding: 0 !important;
            min-width: 80px !important;
            height: 26px !important;
            &:hover {
                background-color: rgba(darken($secondaryColor, 10), .5);
            }
        }
    }
}
