@import "../../assets/scss/variables";

.scroller{
    width: 100%;
    height: 95%;
    & > .ScrollbarsCustom {
        position: relative;
        width: 100%;

        &:not(.native) {
            width: 100%;
            height: 100%;

            & > .ScrollbarsCustom-Wrapper {
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;

                & > .ScrollbarsCustom-Scroller {
                    display: block;

                    & > .ScrollbarsCustom-Content {
                        padding: 0.05px;
                        width: 100%;
                        height: 100%;
                    }
                }
            }

            & > .ScrollbarsCustom-Track {
                position: absolute;
                border-radius: 0.25rem;

                & > .ScrollbarsCustom-Thumb {
                    background: rgba(255, 255, 255, 0.12);
                    cursor: pointer;
                    border-radius: 0.25rem;

                    &:hover,
                    &.dragging {
                        background: $highlight;
                    }
                }
            }

            & > .ScrollbarsCustom-TrackY {
                width: 0.25rem;
                height: 100%;
                right: 0.15rem;
                background: rgba(255, 255, 255, 0.04);

                & > .ScrollbarsCustom-ThumbY {
                    width: 100%;
                }
            }

            &.trackYVisible {
                & > .ScrollbarsCustom-Wrapper {
                    right: 0.75rem;
                }
            }
        }
    }

    &.light-theme {
        & > .ScrollbarsCustom {
            & > .ScrollbarsCustom-Track {
                & > .ScrollbarsCustom-Thumb {
                    background: rgba(0, 0, 0, 0.12);
                }
            }

            & > .ScrollbarsCustom-TrackY {
                background: rgba(0, 0, 0, 0.04);
            }

        }
    }
}
