@import '../../../assets/scss/variables.scss';

.category-interest-form {
  position: relative;
  min-height: 400px;

  &.is-mobile {
    min-height: 100%;
    .categories--main--grid {
      grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
    }
  }

  .scroller {
    padding-bottom: 1rem;
  }

  .interests-info {
    width: fit-content;
    margin: 0 auto;
    margin-bottom: 0.5rem;
    border-radius: 12px;
    background-color: $gridContentLight;
    color: $primaryColor;
    text-align: center;
    padding: 0.5rem 2rem;
    text-transform: uppercase;
    font-size: 1rem;
    font-family: Barlow-Light, sans-serif;
    &.is-dark {
      color: white;
      background-color: $gridContentDark;
    }
    strong {
      font-size: 1.25rem;
    }
  }

  .categories--main--grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-column-gap: 0.75rem;
    grid-row-gap: 0.75rem;
    justify-content: center;

    .categories--main-item {
      cursor: pointer;
      padding: 0.20rem;
      border-radius: 12px;
      border: 0px solid transparent;
      -webkit-transition: background-color 200ms linear;
      -ms-transition: background-color 200ms linear;
      transition: background-color 200ms linear;

      &:hover {
        .event-category_image-box {
          img {
            opacity: 1;
          }
        }
      }

      &.is-selected {
        opacity: 1;
        border-color: $highlight;
        background-color: $highlight;
        color: $gridContentLight;
        .event-category_image-box {
          img {
            opacity: 1;
          }
        }
      }

      .event-category_image-box {
        img {
          border-radius: 10px;
          width: 100%;
          opacity: .5;
          transition: opacity .1s ease-in-out;
          -moz-transition: opacity .1s ease-in-out;
          -webkit-transition: opacity .1s ease-in-out;
        }
      }

      .event-category_title {
        text-shadow: 1px 1px 2px $primaryColor;
        margin-top: 0;
        margin-bottom: 0;
        padding: 0 .5rem;
        padding-top: 0rem;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        font-size: 1rem;
        font-weight: initial;
        font-family: "Barlow-Medium", sans-serif;
      }

      .event-category_subtitle {
        padding: 0 .5rem;
        padding-bottom: 0.25rem;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        font-size: 0.75rem;
        font-family: "Barlow-Light", sans-serif;
        text-transform: lowercase;
      }

    }

  }

  .categories--main--handling-err {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, .5);
    border-radius: 24px;

    span {
      font-weight: bold;
      font-size: 1.15rem;
      position: absolute;
      bottom: 24px;
    }
  }
}
