.cookies-policy-page{
    min-height: calc(100vh - 60px) !important;
    .static-content {
        margin: 0 auto;
        max-width: 960px;
        text-align: justify;
        h2 {
            font-family: "Barlow-Bold", sans-serif;
        }

        table {
            width: 100%;
            max-width: 100%;
            border-radius: 6px;
            border-collapse: separate;
            border-spacing: 0;
            margin-bottom: 3rem!important;
            border: 1px solid #dee2e6;
        }

        table thead th {
            font-weight: 800;
            font-size: 12px;
            border-bottom-width: 0;
            border-right: 0;
            border-top: 0;
        }

        table thead th, table tbody td {
            padding: .625rem;
        }

        table td, table th {
            border: 1px solid #dee2e6;
        }

        table tbody td {
            font-weight: 300;
            font-size: 12px;
            border-right: 0;
            border-bottom: 0;
        }

        table tbody tr:nth-of-type(odd) {
            background-color: rgba(0,0,0,.25);
        }

    }
}
