.sub-navbar {
    display: flex;
    overflow: hidden;
    height: 32px;
    -webkit-overflow-scrolling: touch;
    padding: 0.1rem 1.25rem;
    overflow-x: auto;
    scrollbar-width: none;

    &.is-mobile {
        justify-content: center;
        align-items: center;

        padding: 0rem 0.5rem;
        .sub-navbar-heading {
            display: none;
        }
        .link {
            overflow: hidden;
            min-width: 48px;
            height: 32px;
            border-radius: 6px;
            display: inline-flex;
            span {
                max-width: 64px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }
    }

    .sub-navbar-scrollable {
        flex: 1 1 auto;
        display: inline-block;
        position: relative;
        white-space: nowrap;
    }


    .sub-navbar-heading {
        color: white;
        font-size: 0.75rem;
        font-weight: bold;
        margin-right: 0.5rem;
        text-transform: uppercase;
    }

    .link + .link {
        margin-left: 0.5rem;
    }

    .light-link{
        font-family: Barlow-Bold, sans-serif !important;
        color: white !important;
        padding: 2px 6px;

        font-size: 0.75rem !important;
        letter-spacing: 1px;

        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;

        background: #192330;
        border-radius: 12px;
        border: 1px solid #44516c;

        svg {
            margin-right: 0.25rem;
        }

        &.active{
            border-bottom-color: #44516c;
        }
    }
}
