.embed-next-events-component {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 1rem;
  column-gap: 2rem;
  padding: 2rem;
  background-color: #131314;
  position: relative;

  &.is-tablet {
    grid-template-columns: 1fr;
  }

  &.is-mobile {
    padding: 0;
    grid-template-columns: 1fr;
  }

  .bg-ss {
    top: 32px;
    left: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    background-image: url("https://cdn0.gamesports.net/content_teasers/100000/100116.jpg?1612884493");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    filter: blur(1px);
    -webkit-filter: blur(1px);
    opacity: .15;
    -webkit-transition: opacity 0.15s ease-in-out;
    -moz-transition: opacity 0.15s ease-in-out;
    transition: opacity 0.15s ease-in-out;
  }

  .embed-next-events-item {
    transition: all .35s ease-in-out;
    filter: grayscale(60%);

    &:hover {
      filter: grayscale(0%);
      transform: scale(1.02);
      cursor: pointer;
      .bg-ss {
        opacity: 0.10;
      }
    }

    background-color: rgba(19, 19, 20, 1);
    display: grid;
    grid-template-columns: 0.8fr 0.4fr 0.8fr;
    align-items: center;
    position: relative;
    overflow: hidden;
    border: 2px solid rgba(19, 19, 20, .8);

    .event-score--team {
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      position: relative;
      height: 100%;
      background-color: #2B2B2B;

      &.is-home {
        background: rgb(38,178,152);
        background: linear-gradient(-90deg, rgba(38, 178, 152, .25) 10%, rgba(19, 19, 20, 1) 80%)
      }

      &.is-guest {
        background: rgb(255,70,84);
        background: linear-gradient(90deg, rgba(255,70,84,.25) 10%, rgba(19, 19, 20, 1) 80%);
      }

      .event-score--team-image {
        text-align: center;

        img {
          width: 72px;
          height: 72px;
        }
      }

      .event-score--team-name {
        font-family: Barlow-SemiBold, sans-serif;
        font-size: 1rem;
        width: 100%;
        text-align: center;
      }
    }

    .event-score--versus {
      background: rgb(38,178,152);
      background: linear-gradient(90deg, rgba(38,178,152,.3) -10%, #131314 40%, #131314 60%, rgba(255,70,84,.3) 110%);
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      font-size: 2rem;
      font-family: Barlow-Bold, sans-serif;


      .timezones {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 0.25rem;
      }

      .event-score--versus-timezone {
        font-size: 0.8rem;
        width: 90px;
        padding: 0.2rem;
        background-color: #222222;
        flex: 0.5;
        span {
          margin-left: 0.5rem;
        }
        span.dayplus {
          opacity: .8;
          font-family: Barlow-Light, sans-serif;
          font-size: 0.65rem;
          position: absolute;
          margin-left: 0.1rem;
        }
      }
    }

    .event-score--tournament {
      height: 32px;
      background-color: #28282880;
      border-bottom: 2px solid #1f1f1f;
      padding-top: 0.25rem;
      padding-bottom: 0.25rem;
      display: flex;
      justify-content: center;
      align-items: center;
      grid-row-start: 1;
      grid-column: span 3;
      font-size: 1rem;
      font-family: Barlow-SemiBold, sans-serif;

      .top-bar {
        padding-left: 0.5rem;
        padding-right: 0.5rem;
        width: 100%;
        display: grid;
        grid-template-columns: 0.25fr 1fr 0.25fr;
        align-items: center;

        .event-score--tournament-start {
          color: rgba(255, 255,255,0.5);
          font-size: 0.8rem;
          font-family: Barlow-Light, sans-serif;
          text-transform: uppercase;
        }

        .event-score--tournament-name {
          display: flex;
          justify-content: center;
          img {
            width: 24px;
            height: 24px;
            object-fit: contain;
            margin-right: 0.5rem;
            filter: drop-shadow( 0px 1px 2px rgba(255, 255,255, .25) );
          }
        }

        .event-score--tournament-actions {
          display: flex;
          justify-self: flex-end;
          img {
            width: 20px;
            height: 20px;
          }
        }

      }

    }
  }
}
