@import '../../../../assets/scss/variables.scss';

.group-form-card{
    border-radius: $radiusRegular;
    width: 100%;
    height: 70px;
    cursor: pointer;
    padding-left: 1.5rem;
    box-sizing: border-box !important;

    img{
        width: 36px;
    }

    .group-card-content{
        margin-left: 1rem;
        width: 70%;

        .color-box{
            width: 15%;
            height: 30px;
            .group-color{
                width: 10px;
                height: 10px;
                margin: 0 .5rem 0 0;
                border-radius: 100%;
            }
        }
        .text-data{
            width: 85%;
            .group-name{
                width: fit-content;
                max-width: 80%;
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
                &.subtitle{
                    width: fit-content !important;
                }
            }
            .group-users{
                font-family: 'Barlow-Light';
                font-size: 14px;
                text-transform: capitalize;
                margin: 0;
            }
        }
    }
    .delete-btn{
        color: $red;
        font-size: 20px;
        opacity: 0;
        transition: opacity .35s;
        cursor: pointer;
    }
    &:hover{
        .delete-btn{
            opacity: 1;
        }
    }
}