@import '../../../assets/scss/variables.scss';

.profile-tabs{
    border-radius: 0 $radiusMedium $radiusMedium $radiusMedium;
    overflow: visible;

    .menu{
        width: 100%;
        min-height: 50px;
        max-height: 50px;
        margin-left: 2rem;
        button{
            padding: 0;
            min-width: 14.6%;
            max-width: 14.6%;
            min-height: 50px;
            max-height: 50px;
            font-size: 14px;
            border-radius: $radiusMedium $radiusMedium 0 0;
            font-family: 'Barlow-Regular' !important;
            &.select-dark{
                background: $gridMainDark;
                color: $white;
                font-size: 14px;
                font-family: 'Barlow-Medium' !important;
            }
            &.select-light{
                background: $gridMainLight;
                color: $eventCount;
                font-size: 14px;
                font-family: 'Barlow-Medium' !important;
            }
            .MuiTab-wrapper {
                flex-direction: row;
                img {
                    margin-bottom: 0 !important;
                    margin-right: 0.25rem;
                }
            }
        }
    }
    .indicator{
        display: none !important;
    }
    .section{
        border-radius: $radiusMedium $radiusMedium $radiusMedium $radiusMedium;
        height: 675px;
        width: 100%;
        padding: .25rem;
        justify-content: center;

        .pipe{
            height: 620px;
        }
        .box-title{
            font-family: 'Barlow-Regular' !important;
            text-transform: capitalize;
        }

        .events, .followers, .following, .recommended{
            height: 630px;
            border-radius: $radiusMedium;
            margin: 0 .25rem;
            padding: .2rem .5rem
        }

        .followers, .following {
            .scroller {
                overflow: auto;
            }
        }

        .events-pipe{
            width: 20%;
            min-width: 300px;
        }
        .following-pipe{
            width: 15%;
            min-width: 200px;
        }
        .followers-pipe{
            width: 15%;
            min-width: 200px;
        }
        .event-pipe-empty--text, .user-pipe-empty--text {
            padding: 1rem;
            text-align: center;
        }
        .users-pipe{
            width: 24%;
        }
    }
}

@media only screen and (min-width: 601px) and (max-width: 1080px){
    .profile-tabs{
        .section{
            .events-pipe{
                width: 40% !important;
            }
            .following-pipe{
                width: 30% !important;
            }
            .followers-pipe{
                width: 30% !important;
            }
            .users-pipe{
                display: none !important;
            }
        }
    }
}
