@import '../../../assets/scss/variables.scss';

.group-card{
    border-radius: $radiusRegular;
    width: 200px;
    height: 240px;
    margin: 0 .5rem 0 0;
    cursor: pointer;
    opacity: 0.5;

    &.is-selected {
        opacity: 1;
    }

    img{
        width: 70px;
        max-height: 50px;
        display: block;
    }

    .group-card-content{
        width: 60%;
        margin-top: .7rem;
        .group-name{
            width: fit-content;
            max-width: 80%;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            &.subtitle{
                width: fit-content !important;
            }
        }

        .group-color{
            width: 10px;
            height: 10px;
            margin: 0 .5rem 0 0;
            border-radius: 100%;
        }
        .group-users{
            font-family: 'Barlow-Light';
            font-size: 14px;
            text-transform: capitalize;
            margin: 0;
        }
    }
    &.add{
        border: 2px dotted $secondaryColor;
    }
    .card-members{
        width: fit-content;
        height: 30px;
        position: relative;

        .member-avatar{
            background-color: $gridMainDark;
            width: 34px;
            height: 34px;
            border-radius: 100%;
            border: 2px solid $gridContentLight;
            margin-left: -8px;

            &.green{border-color: #84D754;}
            &.sky{border-color: #54D7D3;}
            &.blue{border-color: #5491D7;}
            &.purple{border-color: #9654D7;}
            &.violet{border-color: #D754B4;}
            &.red{border-color: #D75454;}
            &.orange{border-color: #D79E54;}
            &.yellow{border-color: #C6D754;}

            &:first-child{
                margin-left: 0;
            }
        }
    }
}

@media only screen and (min-width: 601px) and (max-width: 1080px){
    .group-card{
        border-radius: $radiusRegular;
        width: 165px;
        height: 220px;
    }
}

@media only screen and (min-width: 1081px) and (max-width: 1366px){
    .group-card{
        border-radius: $radiusRegular;
        width: 140px;
        height: 220px;
    }
}

@media only screen and (min-width: 1367px) and (max-width: 1440px){
    .group-card{
        border-radius: $radiusRegular;
        width: 155px;
        height: 220px;
    }
}

@media only screen and (min-width: 1441px){
    .group-card{
        border-radius: $radiusRegular;
        width: 225px;
    }
}
