@import '../../assets/scss/variables.scss';

.profile-page{
    flex: 1;
    padding: 1rem;
    *{box-sizing: border-box !important;}

    .profile-calendar, .profile-agenda{
        min-height: 365px;
        border-radius: $radiusLarge;
        box-sizing: border-box;
        box-shadow: none;
        padding: 0;
        h2{ margin: 0;}
    }
    .profile-calendar{
        width: 360px;
        height: 350px;
        float: left;
    }

    .bio-box{
        width: 100%;
    }

    .tabs-box{
        width: 100%;
        .profile-agenda{
            min-height: 365px;
            border-radius: $radiusLarge;
            box-sizing: border-box;
            box-shadow: none;
            padding: 0;
            h2{ margin: 0;}
        }
        .section{
            height: 100%;
            .pipeline {
                min-height: 620px;
                .pipe {
                    height: auto !important;
                    .pipe-content {
                        min-height: 40px;
                    }
                }

            }
        }
    }
}

@media only screen and (max-width: 600px){
    .profile-page{
        padding: .5rem;
        *{box-sizing: border-box !important;}

        .profile-header{
            width: 100%;
        }

        .profile-calendar{
            width: 100%;
            height: 350px;
            float: left;
            margin-bottom: 1rem;
        }
        .bio-box{
            width: 100%;
        }
        .tabs-box{
            .profile-agenda{
                width: 100%;
            }
            .section {
                height: 100%;

                .pipeline {
                    min-height: auto;
                }
            }
        }
    }
}


@media only screen and (min-width: 600px) and (max-width: 1080px){
    .profile-page{
        .profile-header{
            width: 100%;
        }

        .profile-calendar{
            width: 350px;
            height: 350px;
            float: left;
        }

        .bio-box{
            width: calc(100% - 350px);
        }

        .tabs-box{
            .profile-agenda{
                width: 100%;
            }
            .section {
                height: 100%;

                .pipeline {
                    min-height: auto;
                }
            }
        }
    }
}
