@import '../../../assets/scss/variables.scss';

.group-search-form {
    padding: 0 .5rem;
    border-radius: $radiusLarge;
    width: 250px;
    height: 32px;

    &.wide{
        width: 100% !important;
    }
  
    .search-input {
        width: 100%;
        margin: 0;
        padding: .1rem;
        div{
            &:before, &:after{
                display: none !important;
            }
        }
    }
}