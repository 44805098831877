@import '../../assets/scss/variables.scss';

.calendar{
    *{
        margin: 0;
        font-family: 'Barlow-Regular';
    }
    padding: .5rem 0;

    .c-header{

        .icon{
            cursor: pointer;
            font-size: 28px;
        }
        .today{
            font-size: 14px;
            border-radius: $radiusLarge;
            width: fit-content;
            padding: .2rem .5rem;
            font-size: 10px;
            text-transform: capitalize;
            cursor: pointer;
        }
    }
    .c-days{
        margin: .5rem 0 0 0;
        height: 30px;
        .day{
            width: 14.28%;
            text-align: center;
            padding: .3rem 0;
            text-transform: capitalize;
        }
    }
    .c-cells{
        padding: .6rem;
        .week{
            margin: 0 0 .8rem 0;
            height: 28px;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: flex-start;
        }
        .selectedWeek{
            background: $calendarRow;
            border-radius: 50px;
            .day-cell{
                font-weight: 600;
                .day-count{
                    background: $eventCount !important;
                }
            }
        }
        .day-cell{
            width: 28px;
            height: 28px;
            text-align: center;
            padding: .35rem 0;
            cursor: pointer;
            position: relative;
            font-size: 13px;

            &.disabled{
                color: #999999;
            }
            &.selected{
                color: $white;
                font-weight: 600;
                background: $secondaryColor !important;
                opacity: .80;
                border-radius: 100%;
                transform: scale(1.35, 1.3);
                padding-top: .3rem;
            }
            &:not(.selected) {
                &.isCurrentDay {
                    &.dark {
                        background: rgba(255,255,255,0.4) !important;
                    }
                    &.light {
                        background: rgba(0,0,0,0.2) !important;
                    }
                    border-radius: 100%;
                    padding-top: .3rem;
                }
            }
            .day-count{
                width: 11px;
                height: 11px;
                font-size: 8px;
                border-radius: 100%;
                position: absolute;
                right: 7%;
                bottom: -15%;
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                color: $white;
                font-weight: 600;
                &.dark{background: $eventCount;}
                &.light{background: #777777;}
            }
        }
    }
}

@media only screen and (max-width: 600px){
    .calendar{
        .c-cells{
            .selectedWeek{
                background: transparent;
            }
        }
    }
}
