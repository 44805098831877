@import '../../../assets/scss/variables.scss';

.followers-info{
    width: 500px;
    height: 50px;
    border-radius: $radiusLarge;
    padding: .5rem 0 .5rem 3rem;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    flex-wrap: wrap;

    &.small{
        width: 400px;
        .count-box{
            width: 100%;
        }
    }

    .count-box{
        width: 70%;
        .count{
            font-size: 14px;
            width: fit-content;
            margin: 0 .5rem;
            font-family: 'Barlow-Regular';
            cursor: pointer;
            span{
                font-weight: 600 !important;
                font-family: 'Barlow-Medium';
            }
        }
    }
    .actions{
        width: 30%;
        .bell-icon{
            width: 18px;
            cursor: pointer;
        }
        .follow-btn, .unfollow-btn{
            font-size: 12px;
            background: $secondaryColor;
            border-radius: $radiusLarge;
            text-transform: capitalize;
            padding: 0 !important;
            color: $white;
            min-width: 60px !important;
            height: 26px;
            font-family: 'Barlow-Medium' !important;
            float: right;
            margin-left: .75rem;
        }
        .unfollow-btn{
            background: $gridContentLight;
            color: $red;
        }
        .subscribe-btn {
            font-size: 12px;
            border: 2px solid transparent;
            background: $secondaryColor;
            border-radius: $radiusLarge;
            text-transform: capitalize;
            padding: 0 !important;
            color: $white;
            min-width: 48px !important;
            height: 26px;
            float: right;
            svg {
                fill: white;
            }
            &:hover {
                border: 2px solid $secondaryColor;
                background: transparent;
                svg {
                    fill: $secondaryColor;
                }
            }
        }
    }
}

@media only screen and (max-width: 600px){
    .followers-info{
        width: 100%;
        min-height: 50px;
        height: auto;
        border-radius: $radiusLarge;
        padding: .5rem .5rem 0 .5rem;
        align-items: space-between;

        &.small{
            width: 100%;
            .count-box{
                width: 100%;
            }
        }

        .count-box{
            width: 100%;
            .count{
                font-size: 14px;
                width: fit-content;
                margin: 0 .5rem;
                font-family: 'Barlow-Regular';
                span{
                    font-weight: 600 !important;
                    font-family: 'Barlow-Medium';
                }
            }
        }
        .actions{
            width: 100%;
            margin-bottom: .5rem;
        }
    }
}

@media only screen and (min-width: 601px) and (max-width: 1080px) {
    .followers-info{
        width: 400px;
        min-height: 50px;
        border-radius: $radiusLarge;
        padding: 0 .5rem;
        justify-content: flex-end;

        &.small{
            width: 320px;
            .count-box{
                width: 100%;
            }
        }

        .count-box{
            width: 65%;
            .count{
                font-size: 12px;
                width: fit-content;
                margin: 0 .5rem;
                font-family: 'Barlow-Regular';
                span{
                    font-weight: 600 !important;
                    font-family: 'Barlow-Medium';
                }
            }
        }
        .actions{
            width: 30%;
            height: 50px;
        }
    }
}
