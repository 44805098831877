@import '../../assets/scss/variables.scss';

.light-link{
    text-decoration: none;
    margin: 0;
    color: $white;
    
    .light{
        margin-right: 5px;
    }
    .bold{
        font-weight: 600;
    }
}
.active{
    border-bottom: 2px solid $secondaryColor;
}