@import '../../assets/scss/variables.scss';

.register-page{
    width: 100%;
    position: relative;
    .logo-box{
        height: 110px;
    }

    .heading{
        text-transform: capitalize;
        margin: .5rem 0;
        color: $white;
    }

    .wrap-tos {
        display: flex;
        align-items: center;
        margin-bottom: 2em;
        font-size: 0.75rem;
        color: $textSecondaryColor;
        a {
            color: $secondaryColor;
        }
        &.is-error {
            color: $red;
        }
        .error-div {
            color: $red;
            margin-left: 6px;
            font-size: 0.75rem;
        }
    }

}
