@import './../../assets/scss/variables.scss';

.navbar{
    width: 100%;
    height: 48px;

    .nav-logo-box{
        .burger-btn{
            margin-right: 1rem;
            color: $white;
        }
    }
    .menu-box{
        .master_challenge-custom-btn {
            font-family: Barlow-Bold, sans-serif !important;
            background: radial-gradient(circle, rgba(1,201,197,1) 20%, rgba(103,1,231,1) 80%);
            background-size: 600% 600%;
            //border-radius: $radiusSmallRegular;
            color: white !important;
            //border: 2px dotted #fffc90 !important;
            padding: 2px 6px;


            /*
            color: #000;
            background-clip: text;
            text-fill-color: transparent;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
             */


            -webkit-animation: MasterChallengeGradient 5s alternate infinite;
            -moz-animation: MasterChallengeGradient 5s alternate infinite;
            -o-animation: MasterChallengeGradient 5s alternate infinite;
            animation: MasterChallengeGradient 5s alternate infinite;


            @-webkit-keyframes MasterChallengeGradient {
                0%{background-position:0% 100%;}
                100%{background-position:100% 0%;}
            }
            @-moz-keyframes MasterChallengeGradient {
                0%{background-position:0% 100%;}
                100%{background-position:100% 0%;}
            }
            @-o-keyframes MasterChallengeGradient {
                0%{background-position:0% 100%;}
                100%{background-position:100% 0%;}
            }
            @keyframes MasterChallengeGradient {
                0%{background-position:0% 50%; transform: scale(1);}
                100%{background-position:100% 0%; transform: scale(1);}
            }

        }
        .marbellavice-custom-btn {
            font-family: Barlow-Bold, sans-serif !important;
            background: radial-gradient(circle, rgba(249,87,136,1) 20%, rgba(249,136,116,1) 80%);
            background-size: 600% 600%;
            //border-radius: $radiusSmallRegular;
            color: white !important;
            //border: 2px dotted #fffc90 !important;
            padding: 2px 6px;


            /*
            color: #000;
            background-clip: text;
            text-fill-color: transparent;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
             */


            -webkit-animation: MarbellaGradient 2s linear infinite;
            -moz-animation: MarbellaGradient 2s linear infinite;
            -o-animation: MarbellaGradient 2s linear infinite;
            animation: MarbellaGradient 2s linear infinite;


            @-webkit-keyframes MarbellaGradient {
                0%{background-position:0% 50%; transform: scale(1);}
                50%{background-position:100% 50%; transform: scale(1.05);}
                100%{background-position:0% 50%; transform: scale(1);}
            }
            @-moz-keyframes MarbellaGradient {
                0%{background-position:0% 50%; transform: scale(1);}
                50%{background-position:100% 50%; transform: scale(1.05);}
                100%{background-position:0% 50%; transform: scale(1);}
            }
            @-o-keyframes MarbellaGradient {
                0%{background-position:0% 50%; transform: scale(1);}
                50%{background-position:100% 50%; transform: scale(1.05);}
                100%{background-position:0% 50%; transform: scale(1);}
            }
            @keyframes MarbellaGradient {
                0%{background-position:0% 50%; transform: scale(1);}
                50%{background-position:100% 50%; transform: scale(1.05);}
                100%{background-position:0% 50%; transform: scale(1);}
            }

        }
        .light-link{
            margin-right: 1.5rem;
            font-size: 1rem;
            letter-spacing: 1px;
            border-bottom: 2px solid transparent;

            &.active{
                border-bottom: 2px solid $secondaryColor;
            }
        }
        .event-link{
            margin-right: 1rem;
        }
        .add-icon, .bell-icon{
            font-size: 24px;
            color: $white;
            margin-right: 1rem;
            cursor: pointer;
        }
        .explore-link {
            .badge {
                font-family: 'Barlow-Regular';
                font-size: 0.5rem;
                padding: 0px 3px;
                color: white;
                background-color: #00D1BFFF;
            }
        }
        #menu-place{
            position: absolute;
            right: 50px;
            top: 80px;
        }
        .profile-menu-btn{
            width: 42px;
            height: 42px;
            border-radius: 100%;
            cursor: pointer;
            object-fit: cover;
        }
        .switch-mode-wrapper{
            margin: 0 1.5rem;
        }
        .highlightAddEvent {
            background-color: $gridContentAltLight;
            &.is-dark {
                background-color: $gridContentDark;
            }
            &:hover {
                background-color: $secondaryColor;
            }
            &.iconized {
                margin-right: 1rem;
                border-radius: 4px;
                padding: 6px 8px;
                .add-icon {
                    margin-right: 0px;
                }
            }
        }
    }
}

.drawer-menu{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    .switch-mode-wrapper{
        margin: .5rem 0 0 1rem;
    }
    .close-icon{
        font-size: 24px;
        color: $secondaryColor;
    }
    .logo-box{
        height: 60px;
    }

    .link{
        width: fit-content;
        margin-bottom: .5rem;
        padding: 0 1rem;

        &.active{
            border: none;
            border-left: 2px solid $secondaryColor;
        }

        &.marbellavice-custom-btn {
            font-family: Barlow-Bold, sans-serif !important;
            background: radial-gradient(circle, rgba(249,87,136,1) 20%, rgba(249,136,116,1) 80%);
            background-size: 600% 600%;
            //border-radius: $radiusSmallRegular;
            color: white !important;
            //border: 2px dotted #fffc90 !important;
            padding: 2px 6px;


            /*
            color: #000;
            background-clip: text;
            text-fill-color: transparent;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
             */


            -webkit-animation: MarbellaGradient 2s linear infinite;
            -moz-animation: MarbellaGradient 2s linear infinite;
            -o-animation: MarbellaGradient 2s linear infinite;
            animation: MarbellaGradient 2s linear infinite;


            @-webkit-keyframes MarbellaGradient {
                0%{background-position:0% 50%; transform: scale(1);}
                50%{background-position:100% 50%; transform: scale(1.05);}
                100%{background-position:0% 50%; transform: scale(1);}
            }
            @-moz-keyframes MarbellaGradient {
                0%{background-position:0% 50%; transform: scale(1);}
                50%{background-position:100% 50%; transform: scale(1.05);}
                100%{background-position:0% 50%; transform: scale(1);}
            }
            @-o-keyframes MarbellaGradient {
                0%{background-position:0% 50%; transform: scale(1);}
                50%{background-position:100% 50%; transform: scale(1.05);}
                100%{background-position:0% 50%; transform: scale(1);}
            }
            @keyframes MarbellaGradient {
                0%{background-position:0% 50%; transform: scale(1);}
                50%{background-position:100% 50%; transform: scale(1.05);}
                100%{background-position:0% 50%; transform: scale(1);}
            }

        }

        &.master_challenge-custom-btn {
            font-family: Barlow-Bold, sans-serif !important;
            background: radial-gradient(circle, rgba(1,201,197,1) 20%, rgba(103,1,231,1) 80%);
            background-size: 600% 600%;
            //border-radius: $radiusSmallRegular;
            color: white !important;
            //border: 2px dotted #fffc90 !important;
            padding: 2px 6px;


            /*
            color: #000;
            background-clip: text;
            text-fill-color: transparent;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
             */


            -webkit-animation: MasterChallengeGradient 5s alternate infinite;
            -moz-animation: MasterChallengeGradient 5s alternate infinite;
            -o-animation: MasterChallengeGradient 5s alternate infinite;
            animation: MasterChallengeGradient 5s alternate infinite;


            @-webkit-keyframes MasterChallengeGradient {
                0%{background-position:0% 100%;}
                100%{background-position:100% 0%;}
            }
            @-moz-keyframes MasterChallengeGradient {
                0%{background-position:0% 100%;}
                100%{background-position:100% 0%;}
            }
            @-o-keyframes MasterChallengeGradient {
                0%{background-position:0% 100%;}
                100%{background-position:100% 0%;}
            }
            @keyframes MasterChallengeGradient {
                0%{background-position:0% 50%; transform: scale(1);}
                100%{background-position:100% 0%; transform: scale(1);}
            }

        }
    }

    &.dark{background: $appBgDark;}
    &.light{background: $theme-color;}
}

@media only screen and (max-width: 600px){
    .navbar{
        padding: 0 1rem;

        .nav-logo-box{
            width: 50%;
            .burger-btn{
                margin-right: 1rem;
                color: $white;
            }
        }
        .badge{
            font-size: 12px;
        }
        .menu-box{
            width: 50%;
            .add-icon{
                margin-right: .8rem;
            }
            .bell-icon{
                margin-right: 0;
            }
            .profile-menu-btn{
                margin-left: 1.5rem;
            }
            .link{
                margin-right: 0;
                &:first-child{
                    margin-right: 1rem;
                }
            }
        }
    }

    .user-dropdown{
        ul{
            padding-top: 8px;
            padding-bottom: 8px;
            li{
                min-height: 30px;
                padding-top: 0;
                padding-bottom: 0;
                font-size: 14px;
            }
        }
    }
    .drawer-menu{
        min-width: 250px !important;
        padding: 1rem 1rem;
    }
}

@media only screen and (min-width: 601px) and (max-width: 1080px){
    .navbar{
        padding: 0 1.5rem;

        .nav-logo-box{
            width: calc(140px + 24px + 2.5rem);
            .burger-btn{
                margin-right: 1.5rem;
                color: $white;
            }
        }
        .search-box{
            width: calc(100% - (calc(140px + 24px + 2.5rem) + calc(150px + 1.5rem)) );
        }

        .menu-box{
            width: calc(150px + 1.5rem);
            .add-icon{
                margin-right: 1.5rem;
            }
            .bell-icon{
                margin-right: 0;
            }
            .profile-menu-btn{
                margin-left: 2rem;
            }
        }
    }
    .user-dropdown{
        ul{
            padding-top: 8px;
            padding-bottom: 8px;
            li{
                min-height: 40px;
                padding-top: 0;
                padding-bottom: 0;
                font-size: 14px;
            }
        }
    }
    .drawer-menu{
        min-width: 300px !important;
        padding: 2rem 1rem;
    }
}

@media only screen and (min-width: 1081px) and (max-width: 1440px){
    .navbar{
        min-height: 48px;
        padding: 0 1.5rem;

        .nav-logo-box{
            width: calc(140px + 1rem);
            .burger-btn{
                margin-right: 1rem;
                color: $white;
            }
        }
        .search-box{
            width: calc(100% - (calc(140px + 1rem) + calc(630px + 0.5rem)) );
        }

        .menu-box{
            width: calc(630px + 0.5rem);
        }
    }
    .drawer-menu{
        min-width: 300px !important;
        padding: 2rem 1rem;
    }
}

@media only screen and (min-width: 1441px){
    .navbar{
        min-height: 48px;
        padding: 0 1.5rem;

        .nav-logo-box{
            width: calc(140px + 1rem);
            .burger-btn{
                margin-right: 1rem;
                color: $white;
            }
        }
        .search-box{
            width: calc(100% - (calc(140px + 1rem) + calc(630px + 1rem)) );
        }

        .menu-box{
            width: calc(630px + 1rem);
        }
    }
}
