@import '../../../assets/scss/variables.scss';

.quick-event-form{
    width: 100% !important;
    position: relative;
    min-height: 100px;
    padding: .5rem;
    box-sizing: border-box !important;

    .close-btn{
        position: absolute;
        right: .5rem;
        top: .5rem;
        color: inherit;
        font-size: 16px;
        cursor: pointer;
    }

    .header{
        .subtitle{
            font-size: 12px;
            margin: 0 0 .5rem;
            font-family: 'Barlow-Medium';
        }
    }

    .title-time-box{
        border-radius: $radiusSmallRegular;
        height: 28px;
        margin-bottom: .3rem;
        *{font-size: 12px !important;}
        &.error{border: 1px solid red;}
        border: 1px solid transparent;

        &:hover {
            &.is-input-dark {
                &:not(.is-focus) {
                    border-color: $white;
                }
                &.is-focus {
                    border-width: 2px;
                    border-color: $highlight;
                }
            }
            &.is-input-light {
                &:not(.is-focus) {
                    border-color: $textColor;
                }
                &.is-focus {
                    border-width: 2px;
                    border-color: $highlight;
                }
            }
        }

        input[type="time"]::-webkit-calendar-picker-indicator {
            background: none;
        }

        .quick-input{
            height: 28px;
            width: calc(100% - 47% - 15px);
            margin: 0;
            fieldset{
                border: none !important;
            }
            input{padding: .2rem 0 !important;}
            div{
                height: 28px;
                &:before, &:after{
                    display: none !important;
                }
            }
        }

        .quick-time{
            height: 28px;
            width: 40%;
            margin: .1rem 0 0 .2rem;
            div{
                height: 28px;
                width: fit-content !important;
                button{display: none;}
                &:before, &:after{
                    display: none !important;
                }
            }
            p{display: none;}
            &.error{
                input{
                    color: red;
                }
            }
        }
        .quick-time-icon{
            width: 15px;
            height: 15px;
            color: $secondaryColor;
        }
    }
    .tag-select{
        min-height: 28px;
        margin-bottom: .3rem;
        box-sizing: border-box !important;
        border-radius: $radiusSmallRegular !important;
        *{font-size: 12px !important;}
        input{
            padding: 5px !important;
        }
        &>div{
            border-radius: $radiusRegular;
            min-height: 28px;
            box-sizing: border-box !important;

            div{
                padding: 0 0 2px 0 !important;
                border-radius: $radiusRegular;
                min-height: 28px;
                box-sizing: border-box !important;

            }
        }
        fieldset{
            border-color: transparent !important;
        }
        svg{color: $secondaryColor;}
        .chip{
            padding: 0 !important;
            margin: 3px 0 0 3px !important;
            font-size: 12px;
            height: 20px !important;
            min-height: 0 !important;
            background: $secondaryColor;
            color: $white;
            svg{
                color: $eventCount !important;
                width: 12px;
                color: $white !important;
            }
            .MuiChip-avatar {
                width: 12px;
                height: 12px;
                padding: 0!important;
                img {
                    border-radius: 100%;
                    width: 12px;
                    height: 12px;
                }
            }
        }
        .clear-icon{
            display: none;
        }
        &.tag-select-light {
            &.owner {
                background-color: white !important;
            }
        }
        &.tag-select-dark {
            &.owner {
                background-color: $backgroundDark !important;
            }
        }
    }
    .switch-icon{
        margin-right: .5rem;
        width: 16px;
        &.green{
            color: $secondaryColor;
            background: transparent;
        }
        &.grey{
            color: $gridContentLight;
            background: transparent;
        }
        &+span{
            font-size: 12px !important;
            margin-right: .5rem;
        }
    }
    .switch{
        color: $secondaryColor;
        font-size: 16px;
    }
    .all-day-box{margin-bottom: 4px;}
    .private-box{margin-bottom: 6px;}
    .submit-btn{
        min-width: 100% !important;
        height: 26px !important;
        border-radius: $radiusSmallRegular !important;
        margin-top: 6px !important;
        font-size: 12px !important;
        padding: .1rem !important;
    }
}

ul li div.quick{
    picture{
        display: none !important;
    }
    span{
        font-size: 12px !important;
    }
}
