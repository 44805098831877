@import '../../../assets/scss/variables.scss';

.event-user-info {
  display: flex;
  flex-direction: row;
  width: 100%;

  .event-picture-wrap {
    padding: 5px;
    border-radius: 50px 50px 15px 15px;

    img.profile-picture {
      width: 80px;
      height: 80px;
      object-fit: cover;
      border-radius: 100%;
      margin-bottom: 0.5rem;
      cursor: pointer;
    }

    .follow-btn {
      background: $secondaryColor;
      border-radius: $radiusLarge;
      text-transform: capitalize;
      padding: 0 !important;
      color: $white;
    }

    .unfollow-btn {
      background: #e7e7e7;
      color: #d75454;
    }
  }

  .event-picture-wrap, .event-extra-data {
    //background-color: $gridMainLight;
  }

  .event-extra-data {
    .userextra-button.is-you {
      color: $gridMainLight;
      background-color: rgba($gridMainDark, 0.7);
    }
  }

  .event-extra-data {
    padding: 0.25rem;
    border-radius: 0px 15px 15px 0px;
    overflow: hidden;

    .check-icon {
      color: #03cdbf;
      margin-left: .25rem;
      fill: #03cdbf;
      right: 0;
    }

    .social-icons {
      display: grid;
      height: 24px;
      grid-template-columns: repeat(6, 12px);
      grid-column-gap: .4em;
      align-content: center;
      justify-content: center;

      .icon {
        margin: 0;
        width: 12px;
        height: 12px;
        display: flex;

        &.iconGrey {
          path {
            fill: rgba(0, 0, 0, .3);
          }
        }
      }
    }

    .box-title {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-size: 0.9rem;
      font-family: "Barlow-Medium", sans-serif;
      text-transform: none;
      text-align: center;
      display: block;
      clear: both;
      cursor: pointer;
    }
  }

  &.is-dark {
    .event-extra-data {
      .userextra-button.is-you {
        color: $gridMainDark;
        background-color: rgba($gridMainLight, 0.7);
      }

    }
  }
}
