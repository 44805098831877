@import '../../../assets/scss/variables.scss';

.pinging-social-network-buttons-login {
  width: 100%;

  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
  grid-row-gap: 1em;
  grid-column-gap: 0.5em;
  grid-template-areas: "facebook facebook" "twitter apple" "twitch google";
  padding-bottom: 0.25em;


  .social-network-button {
    position: relative;
    text-align: center;
    height: 48px;
    display: flex;
    justify-content: center;
    place-items: center;

    border-radius: $radiusRegular;
    border: 1px solid $highlight;

    font-size: 1rem;
    font-family: "Barlow-Medium", sans-serif;
    color: $highlight;

    &:hover {
      background-color: rgba(white, 0.1);
    }

    .icon {
      position: absolute;
      left: 1.25em;
      display: flex;

      img {
        width: 16px;
        height: 16px;
      }
    }

    .text {

    }

    &.social-network-facebook {
      grid-area: facebook;
    }

    &.social-network-twitter {
      grid-area: twitter;
    }

    &.social-network-google {
      grid-area: google;
    }

    &.social-network-twitch {
      grid-area: twitch;
    }

    &.social-network-apple {
      grid-area: apple;
    }

  }

}
