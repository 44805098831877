$white: #ffffff;
$theme-color: #23304B;
$theme-dark: #0a0a0a;
$primaryColor: #012537;
$secondaryColor: #03CDBF;
$bgColor: #fff;
$gridMainLight: #F6F5F5;
$gridContentLight: #E7E7E7;
$borderLight: rgb(148, 147, 147);
$textColor: #2B2B2B;
$textSecondaryColor: #fff;
$highlight: #00D1BF;
$gridMainDark: #0b0b0b; //#283654;
$gridContentAltLight: #31425f;
$gridContentDark: #282828;//#31425F;
$background: #23304B;
$backgroundDark: #0a0a0a;
$calendarRow: #00D5BF60;
$radiusFull: 40px;
$radiusLarge: 33px;
$radiusMedium: 23px;
$radiusRegular: 17px;
$radiusSmallRegular: 10px;
$radiusSmall: 5px;
$eventCount: #18243D;
$red: #D75454;
$orange: #D79E54;
$darkHover: #000; //#283654;
$lightHover: #dddddd;
$pingLight: #B8B8B8;
$pingDark: rgb(77, 96, 139);
$modalDark: #131314;
$contrastActionDark: #c7c7c7;
$appBgLight: #fff;
$appBgDark: #0a0909;

$eventHoverLight: #e9e9e9;
$eventHoverDark: #222222;

$paperDark: #131314;

$fontXLarge: 1.75rem;
$fontLarge: 1.5rem;
$fontMedium: 1.25rem;
$fontSmall: 1.0rem;
$fontXsmall: 0.85rem;
$fontXXsmall: 0.70rem;
$fontSmaller: 0.50rem;

@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

@-webkit-keyframes smoking-gun {
  50% {
    opacity: .25;
  }
  100% {
    opacity: 0;
    -webkit-transform: translateX(-2px);
  }
}
@keyframes smoking-gun {
  50% {
    opacity: .25;
  }
  100% {
    opacity: 0;
    transform: translateX(-2px);
  }

}


@-webkit-keyframes bounceIn {
  0% {
    transform: scale(0.1);
    opacity: 0;
  }
  60% {
    transform: scale(1.2);
    opacity: 1;
  }
  100% {
    transform: scale(1);
  }
}

@keyframes bounceIn {
  0% {
    transform: scale(0.1);
    opacity: 0;
  }
  60% {
    transform: scale(1.2);
    opacity: 1;
  }
  100% {
    transform: scale(1);
  }
}
