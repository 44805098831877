@import '../../../assets/scss/variables.scss';

.landing-upcoming-events-container {
  display: grid;
  grid-template-columns: repeat(1, minmax(100px, 1fr));
  grid-column-gap: 1rem;
  grid-row-gap: 1rem;

  .event-line-sm-card {
    width: 100%;

    &.destacado {
      border-color: $highlight;

      &:after {
        content: "";
        position: absolute;
        //left: 1em;
        width: 100%;
        height: 100%;
        background-color: rgba(#00D1BF, 0.1);
        animation: animateBorder 4s linear infinite;
        z-index: -55;
      }
    }

    position: relative;
    border-radius: 8px;
    display: grid;
    grid-template-columns: 1fr;
    padding: 0.5rem 0.75rem;
    align-content: center;
    border: 2px solid transparent;

    &:hover {
      cursor: pointer;
    }

    &.is-dark-theme {
      background-color: $gridContentDark;
      background-repeat: repeat;

      &:hover {
        background-color: rgba($gridContentDark, 0.5);
      }
    }

    &.is-light-theme {
      background-color: $gridContentLight;

      &:hover {
        background-color: rgba($gridContentLight, 0.5);
      }
    }

    .event-line-sm-title {
      display: inline-flex;
      justify-content: flex-start;
      align-items: center;

      span {
        font-weight: bold;
        flex: 1;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      font-family: Barlow-Regular, sans-serif;
      font-size: 1rem;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &.not-events {
      display: flex;
      justify-content: center;
      align-items: center;

      &:hover {
        cursor: default;
      }
    }

    .event-line-sm-info {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding-right: 0.5rem;
      font-family: "Barlow-Regular", sans-serif;

      img {
        width: 24px;
        margin-right: 0.5rem;
      }
    }
  }
}
