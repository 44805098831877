@import '../../assets/scss/variables.scss';

.notification{
    z-index: 1000;
    background: transparent;
    position: relative;

    .anchor{
        position: absolute;
        top: 44px;
        right: 100px !important;
    }

    .noti-dropdown {
        &.is-empty {
            height: auto;
            min-height: 120px;
            max-height: 120px;
            .body {
                height: auto;
                padding-bottom: 0.10rem;
            }
        }
        height: 100%;
        max-height: 400px;
    }

    .content{
        width: 400px;
        margin-top: .5rem;
        border-radius: $radiusMedium;

        .MuiAppBar-root {
            border-top-left-radius: $radiusMedium;
            border-top-right-radius: $radiusMedium;
        }

        .header{
            height: 50px;
            margin: 0;
            .subtitle{
                margin: .5rem;
            }
        }
        .body{
            display: flex;
            flex-direction: column;
            height: 400px;
            padding: 0;
            width: 100%;
            .show-more-btn{
                display: block;
                margin: .5rem auto 0;
                width: fit-content;
                padding: .2rem 1rem;
                background: transparent;
                color: inherit;
                text-transform: capitalize;
                font-weight: 600;
            }
            .follow-notification, .invite-notification, .eventmessage-notification {
                height: auto !important;
                padding: .2rem .5rem;

                &.dark{
                    border-bottom: 2px solid $gridMainDark;
                    .accepted-btn, .rejected-btn {
                        color: $white !important;
                    }
                }
                &.light{border-bottom: 2px solid #dddddd;}

                .image-box{
                    width: 50px;
                    .image{
                        width: 50px;
                        height: 50px;
                        border-radius: 100%;
                    }
                }
                .noti-content{
                    width: calc(100% - 50px);
                    padding: 0 .5rem 0 0;
                    .text-content{
                        width: 100% !important;
                        margin: 0 0 0 1rem;
                        font-size: 14px;
                        font-family: 'Barlow-Regular';

                        a{
                            text-decoration: none;
                            color: $secondaryColor;
                            font-family: 'Barlow-SemiBold';
                        }
                    }
                    .noti-actions{
                        padding: .2rem 0 .2rem 1rem;
                        button{
                            width: fit-content;
                            height: 20px;
                            padding: 0 .5rem;
                            color: $white;
                            border-radius: $radiusMedium;
                            text-transform: capitalize;
                            font-size: 11px;
                            margin-right: .5rem;
                        }
                        .accepted-btn{
                            text-transform: unset !important;
                            color: $textColor;
                            background-color: transparent;
                        }
                        .rejected-btn{
                            text-transform: unset !important;
                            color: $textColor;
                            background-color: transparent;
                        }
                        .accept-btn {
                            opacity: 0.8;
                            color: $white;
                            border: 1px solid $secondaryColor;
                            background: $secondaryColor;
                            &:hover {
                                opacity: 1;
                            }
                            .MuiButton-label {
                                margin-top: -2px;
                            }
                        }
                        .reject-btn {
                            opacity: 0.8;
                            color: $white;
                            border: 1px solid $red;
                            background: $red;
                            &:hover {
                                opacity: 1;
                            }
                            .MuiButton-label {
                                margin-top: -2px;
                            }
                        }
                    }
                    .time{
                        margin: 0 0 0 1rem;
                        font-size: 10px;
                        color: #aaaaaa !important;
                    }
                    .read-icon{
                        font-size: 16px;
                        cursor: pointer;
                        background: transparent;

                        &.grey{
                            color: #aaaaaa;
                        }
                        &.green{
                            color: $secondaryColor;
                        }
                    }
                }
            }

            .empty-notifications {
                width: 100%;
                display: flex;
                flex-direction: column;
                align-items: center;
                margin-top: 2rem;
                margin-bottom: 1rem;
                p {
                    opacity: .8;
                    font-style: italic;
                    margin: 0;
                }
            }

        }
    }

    .badge {
        margin-top: 0.3rem;
        font-size: 12px;
        padding: 0px;
    }
}

@media only screen and (max-width: 600px){
    .notification{
        z-index: 1000;
        position: relative;

        .anchor{
            position: absolute;
            top: 40px;
            right: 100px !important;
        }
        .content{
            width: 300px;
            height: 350px;

            .body{
                height: 300px;
                .follow-notification, .invite-notification, .eventmessage-notification {

                    .image{
                        width: 40px;
                        height: 40px;
                    }
                    .noti-content{
                        width: 100%;
                        padding: 0 .5rem 0 0;
                        .text-content{
                            font-size: 12px;
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (min-width: 600px) and (max-width: 1080px) {
    .notification{
        z-index: 1000;
        position: relative;

        .anchor{
            position: absolute;
            top: 40px;
            right: 100px !important;
        }
        .content{
            width: 350px;
            height: 350px;

            .body{
                height: 300px;
                .follow-notification, .invite-notification, .eventmessage-notification {

                    .image{
                        width: 40px;
                        height: 40px;
                    }
                    .noti-content{
                        width: 100%;
                        padding: 0 .5rem 0 0;
                        .text-content{
                            font-size: 12px;
                        }
                    }
                }
            }
        }
    }
}
