@import '../../../assets/scss/variables.scss';

.discover-form{
    width: 1080px;
    min-height: 600px;
    padding: 1rem;

    .discover-info {
        width: 100%;
        margin: 0rem 0.5rem 1rem;
        border-radius: 12px;
        background-color: $gridContentLight;
        color: $primaryColor;
        text-align: center;
        padding: 0.5rem;
        text-transform: uppercase;
        font-size: 1.25rem;
        font-family: Barlow-Light, sans-serif;
        &.is-dark {
            color: white;
            background-color: $gridContentDark;
        }
        strong {
            font-size: 1.5rem;
        }
    }

    .back-btn{
        position: absolute;
        top: 1rem;
        left: 1rem;
        background: transparent;
        text-transform: capitalize;
        font-size: 14px;

        .icon{
            margin-right: .5rem;
            font-size: 16px;
        }
    }

    .box-title{
        margin: 0 0 1rem;
    }

    .discover-menu{
        width: 100%;
        margin: 0 0 1rem;
        overflow: hidden;

        .menu-chip{
            width: fit-content;
        }
    }

    &.is-mobile {
        .discover-form-actions {
            z-index: 900;
            background-color: $gridMainDark;
            padding-top: 1rem;
            padding-bottom: 1rem;
            bottom: 0;
            position: fixed;
        }
    }


    .discover-users{
        width: 100%;
        min-height: 420px;
        margin: 1rem 0 0;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-column-gap: 1rem;
        grid-row-gap: 1rem;

        &.is-mobile {
            grid-template-columns: 1fr;
        }

        .explore-featured-users--main--item {
            .explore-featured-users--main--item-actions {
                z-index: 800!important;
                position: absolute;
                top: 40%;
                left: 50%;
                transform: translate(-50%, -40%);
                width: fit-content !important;
                .custom-button {
                    padding: 1.25rem 1.5rem !important;
                    font-size: 1.25rem !important;
                }
            }
            .explore-featured-users--main--item-content {
                height: 200px !important;
                .explore-featured-users--main--item-background {
                    height: 200px !important;
                }
                .explore-featured-users--main--item-info-title {
                    font-size: 18px !important;
                }
            }
        }

    }
    .discover-form-actions{
        width: 100%;
        margin: 1rem 0 0;
    }
}

@media only screen and (max-width: 600px){
    .discover-form{
        width: 100%;
        min-height: 300px;
        padding: 3.5rem .5rem;
    }
}

@media only screen and (min-width: 601px) and (max-width: 1080px){
    .discover-form{
        width: 700px;
        min-height: 500px;
        padding: 1rem;
    }
}
