@import '../../../assets/scss/variables.scss';

.profile-tabs{
    border-radius: 0 $radiusMedium $radiusMedium $radiusMedium;
    overflow: visible;

    .menu{
        width: 100%;
        min-height: 50px;
        max-height: 50px;
        margin-left: 2rem;
        button{
            padding: 0;
            min-width: 14.6%;
            max-width: 14.6%;
            min-height: 50px;
            max-height: 50px;
            font-size: 14px;
            border-radius: $radiusMedium $radiusMedium 0 0;
            font-family: 'Barlow-Regular' !important;
            &.select-dark{
                background: $gridMainDark;
                color: $white;
                font-size: 14px;
                font-family: 'Barlow-Medium' !important;
            }
            &.select-light{
                background: $gridMainLight;
                color: $eventCount;
                font-size: 14px;
                font-family: 'Barlow-Medium' !important;
            }
        }
    }
    .indicator{
        display: none !important;
    }
    .section{
        border-radius: $radiusMedium $radiusMedium $radiusMedium $radiusMedium;
        height: 675px;
        width: 100%;
        padding: .25rem;

        .pipe{
            height: 620px;
        }
        .box-title{
            font-family: 'Barlow-Regular' !important;
            text-transform: capitalize;
        }

        .events, .followers, .following, .recommended{
            height: 630px;
            border-radius: $radiusMedium;
            margin: 0 .25rem;
            padding: .2rem .5rem
        }

        .events-pipe{
            width: 32%;
        }
        .following-pipe{
            width: 22%;
        }
        .followers-pipe{
            width: 22%;
        }
        .users-pipe{
            width: 24%;
        }
    }
}

@media only screen and (max-width: 600px){
    .profile-tabs{
        margin-top: 1rem;
        .menu{
            width: calc(100% - 1rem);
            margin-left: 1rem;
            button{
                padding: 0;
                min-width: 30%;
                max-width: 30%;
                font-size: 16px;
                &.select-dark{
                    font-size: 13px;
                }
                &.select-light{
                    font-size: 13px;
                }
            }
        }
        .section{
            .box-title{
                display: none;
            }
            .following-pipe{
                width: 100%;
            }
            .followers-pipe{
                width: 100%;
            }
        }
    }
}

@media only screen and (min-width: 601px) and (max-width: 1080px) {
    .profile-tabs{
        margin-top: 1rem;
        .menu{
            margin-left: 1rem;
            button{
                padding: 0;
                min-width: 20%;
                max-width: 20%;
                font-size: 16px;
                &.select-dark{
                    font-size: 13px;
                }
                &.select-light{
                    font-size: 13px;
                }
            }
        }
        .section{
            .following-pipe{
                width: 100%;
            }
            .followers-pipe{
                width: 100%;
            }
        }
    }
}
