@import '../../../assets/scss/variables.scss';

.new-event-info-wrapper {
  width: 960px;
  height: 680px;

  .event-info {
    display: grid;
    grid-template-columns: auto 380px;
    height: inherit;

    .info {
      position: relative;
      padding: 1em;
      display: grid;
      grid-template-rows: .25fr minmax(185px, 300px) 1fr;
      grid-row-gap: 1em;
      justify-items: stretch;

      .info-header {
        display: grid;
        grid-template-columns: auto 9fr;
        align-items: flex-start;

        .info-middle {
          margin: 0 10px;
          display: flex;
          flex-direction: column;

          .info-middle-title-wrap {
            display: grid;
            grid-template-columns: auto 30px 1fr;
            grid-gap: 1rem;
          }

          .event-title-container {
            text-transform: uppercase;
            font-size: 1.4rem;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }


          .share-icon {
            margin: 0 10px;

            svg {
              width: 18px;
              color: $highlight;

              & > g {
                & > * {
                  fill: $highlight !important;
                }
              }
            }
          }

          .archivement-icon {
            justify-self: flex-end;

            &.is-active {
              .MuiButtonBase-root {
                background: #DAA520;
              }
            }

            .MuiButtonBase-root {
              width: 24px;
              height: 24px;
              padding: 16px;
              border: 0px solid white;
              background: #a1a1a1;
              color: white;

              &:hover {
                background-color: $highlight;
              }

              svg {
                width: 18px;
                color: white;

                & > g {
                  & > * {
                    fill: white !important;
                  }
                }
              }
            }
          }

          .time.subtitle {
            font-size: .8rem;
            text-align: left;
            text-transform: none;
            font-family: "Barlow-Light", sans-serif !important;

            strong {
              font-size: .7rem;
            }
          }

          .tz-switcher-button {
            margin-left: .25rem;
            position: relative;
            cursor: pointer;
            background: #e4e4e4;
            border-radius: 20px;
            font-size: .7rem;
            padding: 0px 0px 0px 0.5rem;
            opacity: .5;
            display: inline-flex;
            align-items: center;
            justify-content: space-evenly;

            svg {
              width: 18px;
              height: 16px;
            }

            &.isDarkMode {
              color: black;
            }
          }

          .custom-date-countdown {
            border-radius: 0px 12px 12px 12px;
            padding: 0.15rem 0.5rem;
            margin-right: auto;
            margin-top: 0.20rem;
            font-size: 0.65rem;
            font-family: Barlow-Light, sans-serif;

            .countdown {
              font-weight: 600;
            }

            &.is-light {
              background-color: #f1f1f1;
            }

            &.is-dark {
              color: black;
              background-color: #838997;
            }
          }
        }
      }

      .event-btn-actions {
        margin: 0.45rem 0px;
        display: flex;
        zoom: 0.85;
        justify-content: flex-start;
        align-items: center;
        position: relative;

        .suscriptor-tutorial-actionable {
          z-index: 86;
          & > button {
            box-shadow: 0px 0px 5px 5px #00d1bf40;
          }
        }



        .advanced-button {
          margin-right: 0.7rem;

          &.is-status-on .label {
            font-size: 0.9rem;
            margin-top: 0px;
          }
        }

      }

      .is-private {
        display: inline-flex;
        align-items: center;
        padding: 0.25rem 0.5rem;
        border: 1px solid transparent;
        border-radius: 33px;

        .text {
          font-size: 0.8rem;
        }

        .is-private-icon {
          height: 12px;
          margin-left: 0.25rem;
        }
      }

      .event-info-website-wrapper {
        display: flex;
        align-items: center;
        font-size: 0.8rem;
        margin-right: 0.8rem;

        .icon {
          width: 14px;
          margin-left: 0.25rem;
        }
      }

      .event-info-center {
        display: grid;
        grid-template-columns: 45% 55%;
        align-items: start;
        justify-content: start;
        margin: 1.5rem 1rem;

        > div {
          width: 100%;
          height: 100%;
        }

        div > div.title {
          font-family: "Barlow-Regular", sans-serif;
          font-size: 1rem;
          margin-bottom: 5px;

          span.count {
            color: $highlight;
          }
        }

        .see-more-wrapper {
          text-align: right;

          span.see-more {
            color: $highlight;
            cursor: pointer;

            &:hover {
              text-decoration: underline;
            }
          }
        }

        .guests-wrapper-parent {

          .guests-wrapper {
            display: inline-flex;
            justify-content: space-evenly;
            align-items: center;
            width: 100%;

            .guest-icon-wrapper {
              padding: 5px;
              border-radius: 50px 50px 15px 15px;
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;

              .guest-icon {
                width: 65px;
                border-radius: 100%;
                margin-bottom: 0.5rem;
                cursor: pointer;
              }

              .follow-btn {
                background: $secondaryColor;
                border-radius: $radiusLarge;
                text-transform: capitalize;
                padding: 0 !important;
                color: $white;
              }

              .unfollow-btn {
                background: #e7e7e7;
                color: #d75454;
              }
            }
          }
        }

        .no-guests-wrapper {
          border-radius: $radiusMedium;

          > div > span {
            font-size: 0.8rem;
          }

          &.is-light {
            background-color: $gridMainLight;
          }

          &.is-dark {
            background-color: $gridMainDark;
          }
        }

        .event-info-highlight-link {
          grid-column: 1 / 3;
          display: flex;
          justify-content: center;
          align-items: center;

          .event-info-website-wrapper {
            font-size: 1.10rem;
            border-radius: 24px;
            justify-content: center;
            margin-right: 0;
            padding: 0.75rem 1.5rem;

            &.is-twitch {
              background-color: rgba(100, 65, 165, 1);
              &.is-light-theme {
                color: white;
              }
            }

            &:hover {
              background-color: #03cdbf;
              &.is-twitch {
                background-color: rgba(185, 163, 227, 1);
                color: rgb(38, 38, 38);
                img {
                  filter: brightness(0%);
                }
              }
              img {
                filter: brightness(0%) invert(1);
              }
            }

            img {
              margin-right: 0.35rem;
            }
            span {
              margin-left: 0.25rem;
            }
            div {
              font-family: "Barlow-Medium", serif;
              text-decoration: underline;
            }
          }
        }

      }

      .tabs {
        min-width: 0px;

        .menu {
          min-height: 40px;
          max-height: 40px;

          .MuiTabs-centered {
            display: grid;
            grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));

            button {
              padding: 0;
              min-height: 40px;
              max-height: 40px;
              text-transform: capitalize;
              border-radius: 23px 23px 0 0;
              font-size: 1em !important;
              width: 100%;
              min-width: 100%;
              font-family: "Barlow-Regular", sans-serif !important;

              &.none {
                opacity: 0.7;
              }

              &.select-dark {
                background: $paperDark;
                color: $white;
              }

              &.select-light {
                background: $gridMainLight;
                color: $eventCount;
              }
            }

          }

          .indicator {
            display: none !important;
          }
        }

        .section {
          font-family: 'Barlow-Regular', sans-serif !important;
          border-radius: 0 0 $radiusMedium $radiusMedium;
          height: calc(100% - 40px);
          padding: 1rem;

          .user-card {
            &:first-child {
              margin: .5rem 0 0 !important;
            }
          }

          .scroller-action {
            height: 34px;
          }

          .tab-empty-content {
            opacity: .5;
            -webkit-touch-callout: none;
            -webkit-user-select: none;
            user-select: none;
          }


          .wrap-users-guest {
            display: flex;
            flex-direction: column;
            margin-bottom: 1em;

            .invitation-status {
              margin: 0 auto;
              text-align: center;
              font-size: 0.75rem;
              padding: 0.25rem 0.75rem;
              border-bottom-left-radius: $radiusSmall;
              border-bottom-right-radius: $radiusSmall;

              .status-ok {
                font-size: 0.80rem;
                color: $highlight;
              }

              .status-pending {
                font-size: 0.80rem;
                color: $red;
              }
            }
          }
        }
      }

      .input-box {
        max-width: 100%;
        overflow: hidden;

        .comment-form {
          border-radius: $radiusMedium;
          justify-content: space-between;

          .comment-input {
            width: 100%;
            letter-spacing: normal;
            font-size: 0.8rem !important;
            font-family: 'Barlow-Regular', sans-serif;
            padding: .5rem 0 .5rem 1rem;

            .comment-input__suggestions {
              background-color: red !important;
              padding: 0.25rem;
            }

            .comment-input__highlighter {
              &.comment-input__highlighter--singleLine {
                top: -1px !important;
              }
            }


            input {
              padding: .5rem 0 .5rem 1rem;
              width: 100% !important;
              margin: 0;
              background: transparent !important;
              font-size: 0.8rem !important;
              border: none !important;
              border-radius: $radiusMedium;

              &:focus {
                outline: none;
              }
            }

            &.isDark {
              input {
                color: $white !important;
              }
            }

            &.isLight {
              input {
                color: $gridMainDark !important;
              }
            }

          }

          .send-btn {
            font-size: 12px;
            margin-right: .25rem;
            cursor: pointer;
            background: $secondaryColor;
            color: $white;
            padding: .35rem .7rem;
            height: 30px;
            border-radius: $radiusMedium;
            text-transform: capitalize;
          }
        }

        .refElSuggestions {
          @keyframes lds-dual-ring {
            0% {
              transform: rotate(0deg);
            }
            100% {
              transform: rotate(360deg);
            }
          }

          .comment-input__suggestions__loadingIndicator {
            margin-top: 4px;
            margin-bottom: 4px;

            width: 100%;
            height: 8px;

            text-align: center;
            font-size: 11px;

            .comment-input__suggestions__loadingIndicator__spinner {
              display: inline-block;

              background-color: #999;

              height: 100%;
              width: 2px;

              margin-left: 3px;
              margin-right: 3px;

              animation: lds-dual-ring 1.2s linear infinite;
            }

          }
        }
      }

      .invite-btn {
        font-size: 0.9rem;
        border: 1px solid #00D1BF;
        zoom: 0.85;
      }
    }

    .poster {
      position: relative;
      overflow: hidden;

      .background-poster {
        -webkit-filter: blur(5px) contrast(125%) brightness(75%);
        filter: blur(5px) contrast(125%) brightness(75%);
        height: 100%;
        position: relative;
        background-repeat: no-repeat;
        background-position: 50%;
        background-size: 110% 110%;
      }

      .poster-overlay {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;

        .img-poster, .img-poster > img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }

        .floating-action-buttons-2 {
          width: 100%;
          position: absolute;
          display: flex;
          flex-direction: row-reverse;
          justify-content: space-between;
          padding-right: 0.15em;

          .upload-btn, .submit-btn {
            margin-top: 1em;
            margin-right: 1em;
            height: fit-content;
            color: $white;
            line-height: 0;

            &.label {
              padding: .35rem .5rem .24rem;
            }
          }

          .poster-input {
            display: none !important;
          }

          .cancel-btn {
            margin-left: 1em;
            margin-top: 1em;
            line-height: 0;
          }

        }

        .floating-action-buttons {
          min-width: 30%;
          position: absolute;
          top: 3em;
          right: 1em;
          display: flex;
          flex-direction: column;
          justify-content: space-between;

          button {
            font-family: "Barlow-Light", sans-serif !important;
            display: flex;
            flex-direction: column;
            padding-right: 0.35em !important;
            margin-bottom: 0.5em;

            .MuiButton-Label {
              justify-content: flex-start;
            }

            &.delete-btn {
              background-color: $red;
            }

            .icon {
              width: 14px;
              height: 14px;
              margin-right: 0.2em;
            }
          }

        }

        .trailer-btn {
          zoom: 1.8;
          min-width: auto;
          height: 27px;
          padding: 0 0.5em;
          border-radius: $radiusLarge;
          border: 2px solid #03cdbfb3;
          line-height: 1.5;
          animation: wiggle 4s linear infinite;
          animation-delay: 1s;

          &.is-dark{
            box-shadow: 0px 0px 10px 10px $gridContentDark;
            background-color: $gridContentDark;
          }
          &.is-light{
            box-shadow: 0px 0px 10px 10px $gridContentLight;
            background-color: $gridContentLight;
          }

          &:hover {
            border-color: $secondaryColor;
          }

          .label {
            text-transform: uppercase;
            font-family: "Barlow-SemiBold", sans-serif;
            font-size: 0.60rem;
            letter-spacing: 0;
            padding: 0 0.50em;
            color: $secondaryColor;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }

          .icon {
            line-height: 0;

            svg{
              color: $secondaryColor;
            }
          }
        }
      }
    }
  }

}

#nested-list-subheader {
  color: $highlight !important;
}

@media only screen and (max-width: 768px) {
  .new-event-info-wrapper {
    width: 100%;

    .event-info {
      grid-template-columns: minmax(0, 1fr);

      .poster {
        display: none;
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  .new-event-info-wrapper {
    width: 100%;
    height: 100%;
  }
}

.video_modal {
  display: flex;
  align-items: center;
  justify-content: center;

  .video_wrapper {
    width: 60%;
    margin: auto;
    color: $white;

    &.mobile {
      width: 100%;

      .close-icon {
        left: calc(100% - 2rem);

        svg {
          font-size: 2rem;
        }
      }
    }

    .close-icon {
      position: relative;
      left: 100%;

      svg {
        font-size: 2rem;
      }
    }

    .iframe_wrapper {
      position: relative;
      padding-bottom: 56.25%;
      height: 0;
      display: flex;
      justify-content: center;
      align-items: center;

      iframe {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
      }
    }
  }
}

.participants-modal {
  width: 100%;

  .tag-select {
    border: 0;

    svg {
      color: $secondaryColor;
    }

    fieldset {
      border-color: transparent;
    }

    .chip {
      padding: 0rem !important;
      font-size: 12px;
      height: 30px;
      background: $secondaryColor;

      svg {
        color: $eventCount;
        width: 18px;
      }

      .MuiChip-avatar {
        width: 24px;
        height: 24px;
        padding: 0 !important;

        img {
          border-radius: 100%;
          width: 24px;
          height: 24px;
        }
      }

    }
  }
}

@keyframes wiggle {
  0%, 7% {
    transform: rotateZ(0);
  }
  15% {
    transform: rotateZ(-15deg);
  }
  20% {
    transform: rotateZ(10deg);
  }
  25% {
    transform: rotateZ(-10deg);
  }
  30% {
    transform: rotateZ(6deg);
  }
  35% {
    transform: rotateZ(-4deg);
  }
  40%, 100% {
    transform: rotateZ(0);
  }
}

@keyframes flip {
  from {
    transform: perspective(400px) scale3d(1, 1, 1) translate3d(0, 0, 0) rotate3d(0, 1, 0, -360deg);
    animation-timing-function: ease-out;
  }

  40% {
    transform: perspective(400px) scale3d(1, 1, 1) translate3d(0, 0, 150px) rotate3d(0, 1, 0, -190deg);
    animation-timing-function: ease-out;
  }

  50% {
    transform: perspective(400px) scale3d(1, 1, 1) translate3d(0, 0, 150px) rotate3d(0, 1, 0, -170deg);
    animation-timing-function: ease-in;
  }

  80% {
    transform: perspective(400px) scale3d(0.95, 0.95, 0.95) translate3d(0, 0, 0) rotate3d(0, 1, 0, 0deg);
    animation-timing-function: ease-in;
  }

  to {
    transform: perspective(400px) scale3d(1, 1, 1) translate3d(0, 0, 0) rotate3d(0, 1, 0, 0deg);
    animation-timing-function: ease-in;
  }
}

@keyframes bounceIn {
  from,
  20%,
  40%,
  60%,
  80%,
  to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }

  0% {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3);
  }

  20% {
    transform: scale3d(1.1, 1.1, 1.1);
  }

  40% {
    transform: scale3d(0.9, 0.9, 0.9);
  }

  60% {
    opacity: 1;
    transform: scale3d(1.03, 1.03, 1.03);
  }

  80% {
    transform: scale3d(0.97, 0.97, 0.97);
  }

  to {
    opacity: 1;
    transform: scale3d(1, 1, 1);
  }
}
