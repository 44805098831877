@import '../../assets/scss/variables.scss';

.groups{
    height: 350px;
    position: relative;
    border-radius: $radiusLarge;

    &.is-dark-theme {
        .head {
            .btn-box {
                color: $gridMainDark;
                &:hover {
                    color: white;
                }
            }
        }
    }

    .head{
        height: 50px;
        width: 100%;
        .box-title{
            margin: 0;
            width: fit-content !important;
        }
        .icon-box{
            width: 36px;
            height: 36px;
            background: $secondaryColor;
            padding: 6px;
            margin-left: 1rem;
            cursor: pointer;
            border-radius: 100%;

            .add-group-icon{
                width: 24px;
                height: 24px;
            }
        }
        .btn-box {
            font-family: "Barlow-Light", sans-serif;
            background-color: $gridContentLight;
            padding: 2px 12px;
            margin-left: 1em;
            border-radius: $radiusRegular;
            &:hover {
                cursor: pointer;
                background-color: $secondaryColor;
            }
        }

        .groups-placeholder-help {
            margin-left: 0.75em;
        }

    }
    .related-actions{
        height: 35px;
        width: 100%;
        .action-menu{
            width: fit-content;
            min-height: 25px;
            max-height: 25px;
            button{
                min-width: 55px;
                min-height: 25px;
                max-height: 25px;
                padding: 0;
                text-transform: capitalize;
                font-family: 'Barlow-Medium';
                background: $gridContentLight;
                color: $gridMainDark;
                border-radius: $radiusLarge;
                margin-right: 1rem;
                &.selected{
                    background: $secondaryColor;
                    color: $white;
                }
            }
        }
        .indicator{
            display: none !important;
        }
        .hidden{display: none !important;}
    }
    .groups-data{
        height: 270px;
        width: 80%;
        padding: 1rem 0;

        .isUnassignedGroup {
            order: 9999;
        }

        .scroll-icon{
            cursor: pointer;
            width: 36px;
            height: 36px;
        }
    }
}

@media only screen and (min-width: 601px) and (max-width: 1080px){
    .groups{
        .groups-data{
            width: 700px;
            height: 250px;
        }
    }
}

@media only screen and (min-width: 1081px) and (max-width: 1440px){
    .groups{
        .groups-data{
            width: 90%;
        }
    }
}
