@import '../../../assets/scss/variables.scss';

.event-card-simple {
    width: 100%;
    height: 55px;
    border-radius: 50px 0 0 50px;
    margin: 1rem 0 0;
    position: relative;
    border: 2px solid transparent;

    &:hover {
        &.is-dark-theme {
            background-color: $eventHoverDark;
        }
        &.is-light-theme {
            background-color: #f6f5f5;
        }
    }

    &.is-dark-theme {
        .avatar {
            background-color: $gridMainDark;
        }
    }

    &.is-light-theme {
        .avatar {
            background-color: $gridMainLight;
        }
    }

    .avatar {
        width: 55px;
        height: 55px;
        border-radius: 100%;
        position: absolute;
        left: -2px;
        top: -2px;
        z-index: 3;
        border: 2px solid transparent;

        &.border {
            width: 52px;
            height: 52px;
            left: 0px;
            top: -8px;
        }

        &.green {
            border-color: #84D754;
        }

        &.sky {
            border-color: #54D7D3;
        }

        &.blue {
            border-color: #5491D7;
        }

        &.purple {
            border-color: #9654D7;
        }

        &.violet {
            border-color: #D754B4;
        }

        &.red {
            border-color: #D75454;
        }

        &.orange {
            border-color: #D79E54;
        }

        &.yellow {
            border-color: #C6D754;
        }
    }


    .poster-wrap {
        background-color: transparent;
        width: 42px;
        height: calc(100% + 4px);
        position: absolute;
        right: -3px;
        top: -2px;
        display: flex;
        justify-content: flex-end;
        .poster, .poster > img {
            background-color: transparent;
            width: 42px;
            height: 100%;
            object-fit: cover;
            border-radius: 0;
        }

        /*
        &:after {
            content: "";
            position: absolute;
            display: block;
            right: 0;
            width: 21px;
            height: 100%;
        }
        */

    }

    .overlapGuests {
        width: calc(100% - 44px);
        position: absolute;
        bottom: -13px;
        left: 35px;
        padding-left: 0em;
        color: #23304B;
        border-top: 0px solid #03CDBF;
        font-size: .6rem;
        z-index: 10;
        font-family: 'Barlow-Regular', sans-serif;



        &.isDarkMode {
            color: $white;
        }
        .tmpAvatar {
            background-color: $gridMainDark;
            width: 24px;
            height: 24px;
            border-radius: 100%;
        }
    }

    .event-card-content{
        width: calc(100% - 68px);
        padding: 4px 28px 4px .2rem;
        height: 100%;
        border-radius: 50px $radiusRegular $radiusRegular 50px;
        position: absolute;
        top: 0;

        left: 54px;
        justify-content: center;

        &.is-score-content {
            left: 12px !important;
            .title-w-line-ellipsis {
                display: flex;
                font-size: 0.9rem;
                .title-score-team {
                    width: 24px;
                    font-weight: bold;
                    margin-right: 0.5rem;
                }
            }
        }

        .title, .date{
            margin: 0;
            text-transform: capitalize;
            font-size: 11px;
            font-family: 'Barlow-Regular', sans-serif;
            overflow: hidden;
            white-space: nowrap;
            &.date {
                text-transform: initial;
            }
        }
        .title{
            font-family: 'Barlow-Medium', sans-serif;
            width: 100%;
            text-overflow: ellipsis !important;
            white-space: nowrap !important;
            overflow: hidden !important;
            min-height: 14px;
            line-height: 1;
            font-weight: 700;
            font-size: 0.9em;
        }
        .title-w-line-ellipsis {
            font-family: "Barlow-SemiBold",sans-serif;
            font-size: 0.9em;
            line-height: 1.1;
            overflow: hidden;
            display: flex;
            flex-direction: row;
            h3 {
                font-size: inherit;
                margin-top: 0;
                margin-bottom: 0;
            }
        }
        .chat{
            padding-top: .15rem;
            margin-right: .2rem;
            .chat-count{
                margin-right: .2rem;
                color: $secondaryColor;
                font-size: 8px;
            }
            .chat-icon{
                width: 12px;
                float: right;
                color: $secondaryColor;
            }
        }
        .is-private {
            margin-left: 0.2rem;
            margin-right: .1rem;
            img {
                width: 14px;
            }
        }
        .is-notifiable {
            position: absolute;
            right: .2rem;
            top: .5rem;
            width: 18px;
            img {
                width: 100%;
            }
        }
    }
    .ping-tag{
        border-radius: $radiusLarge;
        width: 80%;
        height: 12px;
        position: absolute;
        top: -12px;
        right: 0px;
        padding: 0 .3rem;

        &.dark{background: $pingDark;}
        &.light{background: $pingLight;}

        .ping-user{
            margin-right: 3px;
            text-decoration: none;
            text-transform: capitalize;
            overflow: visible;

            &.active {
                border-bottom: 0;
            }
        }
        *{
            font-size: 9px;
            color: $white;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
        }
    }

    &.is-notifiable {
        .event-card-content {
            .title {
                padding-right: 1.5rem;
            }
        }
        &.is-premium-poster {
            .event-card-content {
                .title-w-line-ellipsis {
                    padding-right: 1.25em !important;
                }
            }
            .is-notifiable {
                right: 1.65rem !important;
            }
        }
    }

}

@media only screen and (max-width: 600px){
    .event-card{
        width: 100%;
        height: 55px;
        margin: 1rem 0 0;
        position: relative;
        border-radius: 50px 0 0 50px;

        &.ping{
            border: 1px solid $secondaryColor;
            .avatar{
                background-color: $secondaryColor;
            }
        }

        &.is-premium-poster {
            .event-card-content {
                width: calc(100% - 54px) !important;
                padding-right: 42px;
            }
        }
        .poster-wrap {
            width: 42px;
            .poster, .poster > img {
                width: 42px;
                border-radius: 0;
            }
        }

            .avatar{
                width: 55px;
                height: 55px;
                border-radius: 100%;
                position: absolute;
                left: -2px;
                top: -2px;
                border: 2px solid transparent;

                &.border{
                    width: 65px;
                    height: 65px;
                    left: 0px;
                    top: -8px;
                }
            }

        .event-card-content{
            width: calc(100% - 54px);
            left: 54px;
            justify-content: center;
            .title{
                font-size: 13px;
                font-family: 'Barlow-Medium', sans-serif;
            }
            .title-w-line-ellipsis {
                font-family: "Barlow-Medium",sans-serif;
                font-size: 0.9em;
                font-weight: 700;
                line-height: 1.1;
                overflow: hidden;
                display: flex;
                flex-direction: row;
                h3 {
                    margin-top: 0;
                    margin-bottom: 0;
                }
            }
            .chat{
                padding-top: .15rem;
                .chat-count{
                    font-size: 11px;
                }
            }
        }
    }
}
