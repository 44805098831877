@import '../../../assets/scss/variables.scss';

.password-recovery-form{
    font-family: "Barlow-Medium", sans-serif;
    width: 400px !important;
    height: 400px;
    padding: 1rem;
    position: relative;
    .close-icon{
        color: $secondaryColor;
        font-size: 24px;
        position: absolute;
        top: 1rem;
        right: 1rem;
    }
    .email, .password{
        border-radius: $radiusMedium !important;
        margin: .7rem 0;
        border-color: $secondaryColor;
        width: 100%;
        height: 50px;
        label{
            color: $secondaryColor;
            letter-spacing: 1.3px;
            font-size: 14px !important;
        }
        label+div{
            border-radius: $radiusMedium;
            height: 50px;
        }
        fieldset{
            border-color: $secondaryColor !important;
        }
        input{
            color: $secondaryColor;
            font-size: 14px !important;
        }
    }
    .error{
        border-top: 5px solid darken(crimson, 10);
        border-bottom: 5px solid darken(crimson, 10);
        padding: .5rem 1rem;
        text-align: center;
        width: fit-content;
        border-radius: 4px;
        background: crimson;
        color: white;
        font-size: 14px;
        margin: 1rem 0 0;
        font-family: "Barlow-Light", sans-serif;
        .resend-button {
            font-family: "Barlow-Medium", sans-serif;
            color: white;
            margin-top: 0.5rem;
            background: darken(crimson, 10);
        }
    }
    .info {
        border-top: 5px solid darken(whitesmoke, 10);
        border-bottom: 5px solid darken(whitesmoke, 10);
        padding: .5rem 1rem;
        text-align: center;
        width: fit-content;
        border-radius: 4px;
        background: rgba(#f5f5f5, 0.9);
        font-size: 14px;
        margin: 1rem 0 0;
        font-family: "Barlow-Light", sans-serif;
        color: $background;
    }
    .submit-btn{
        margin: 1.5rem 0 !important;
        color: $theme-dark !important;
        font-size: 14px !important;
        height: 36px !important;
        min-width: 150px;
    }
}

@media only screen and (max-width: 600px){
    .password-recovery-form{
        width: 100% !important;
        min-height: 400px;
        padding: 1rem;
    }
}
