@import '../../../assets/scss/variables.scss';

.search{
  width: 100%;
  max-width: 100%;
  /*
  &.dark{
    background-color: $background;
  }
  &.light{
    background-color: $theme-color;
  }
   */
  .navbar-search-form {
    padding: .1rem .5rem .1rem 1rem;
    z-index: 1000 !important;
    height: 32px;
    border-radius: $radiusLarge;
    width: 100%;

    .search-input {
      width: 100%;
      margin: 0;
      div{
          &:before, &:after{
              display: none !important;
          }
      }
      input{
          font-size: 14px;
      }
    }

    .icon-btn {
      font-size: 24px;
      padding: 0;
      margin: 0;
      cursor: pointer;
      color: $secondaryColor;
    }
  }
  .result{
    *{
      z-index: 1000 !important;
    }
    .content{
      width: 300px;
      height: 350px;
      margin-top: 1rem;
      z-index: 1000 !important;
      padding: .1rem 0 0;
      border-radius: $radiusMedium;
      box-sizing: border-box !important;

      h3{
        text-align: center;
        margin: .5rem 0;
      }
      .scroller{
        .scroller-content{
          height: calc(100% - 40px);
        }
        .scroller-action{
          height: 40px;
        }
      }

      .user-result, .event-result{
        height: auto !important;
        cursor: pointer;
        padding: .15rem .5rem;

        &.dark{
          border-bottom: 1px solid $gridMainDark;
        }
        &.light{
          border-bottom: 1px solid #cccccc;
        }

        .check-icon{
          color: $secondaryColor;
          margin-left: .25rem;
          vertical-align: bottom;
          fill: $secondaryColor;
        }

        .image{
          width: 40px;
          height: 40px;
          border-radius: 100%;
        }
        .user-name, .event-name, .time{
          margin: 0 0 0 1rem;
          text-transform: capitalize;
          font-size: 12px;
        }
        .time{
          font-size: 10px;
          font-weight: 500;
        }
      }
    }
  }
}

@media only screen and (max-width: 767.98px){
  .search{
    width: 100%;
    max-width: 100%;
    //padding: 1rem 1rem .5rem;
    .navbar-search-form {
      padding: .1rem .5rem .1rem 1rem;
    }

    .result{
      .content{
        width: 100% !important;
        height: 300px;
        margin-top: .2rem;
        z-index: 1000 !important;
        padding: .1rem 0 0;
        border-radius: $radiusMedium;
        box-sizing: border-box !important;

        h3{
          text-align: center;
          margin: .5rem 0;
        }
      }
    }
  }
  .holder{
    width: 100% !important;
    padding: 0 1rem;
  }
}
