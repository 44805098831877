.profile-archivements-component {
  display: grid;
  align-self: stretch;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px;

  &.is-mobile, &.is-tablet {
    padding: 0 !important;
    grid-template-columns: 1fr;

    .profile-archivements-list-component {
      padding: 0 !important;
      padding-bottom: 1rem !important;

      .profile-archivements-list-component--heading {
        h2 {
          font-size: 1.10rem;
        }
      }
    }

    .profile-archivements-list-component-items-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;

      .profile-archivements-list-component-items {
        padding: 0;
        max-width: 100%;
        width: 100%;
        //grid-template-columns: 0.5fr 0.5fr 1fr !important;
        grid-template-columns: repeat(auto-fit, minmax(148px, 160px));
        justify-content: center !important;

        .profile-archivements-list-component-item--new-image_box {
          height: 180px !important;
          img {
            height: 180px !important;
          }
        }


      }
    }

  }

  &.is-fullwidth {
    grid-template-columns: 1fr;
    grid-auto-rows: auto !important;

    .profile-archivements-list-component-items {
      justify-content: flex-start !important;
      //grid-template-columns: repeat(auto-fit, minmax(240px, 360px)) !important;
    }
  }

  .profile-archivements-list-component {
    padding: 1rem;
    border-radius: 12px;

    .profile-archivements-list-component--heading {
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-radius: 12px;

      h2 {
        flex: 1;
        text-align: center;
        text-transform: uppercase;
        font-family: "Barlow-SemiBold", sans-serif;
        font-size: 1.5rem;
        padding: 0.25rem 0.5rem;
        margin-top: 0;
        margin-bottom: 0;
      }

    }

    .profile-archivements-list-component-items {
      display: grid;
      //grid-template-columns: repeat(auto-fit, minmax(240px, 360px));
      //grid-template-columns: 1fr 1fr 1fr;
      grid-template-columns: repeat(auto-fit, minmax(148px, 200px));
      grid-column-gap: 1rem;
      grid-row-gap: 1rem;
      justify-content: flex-start;

      .MuiSkeleton-root {
        width: 100%;
        height: 100%;
        min-height: 300px;
        border-radius: 18px;
      }

      .profile-archivements-list-component-item {
        width: 100%;
        height: 100%;
        min-height: 300px;
        border-radius: 18px;
        background-color: black;
        position: relative;

        &.is-placeholder {
          background-color: rgba(0, 0, 0, .5);
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;

          font-family: Barlow-SemiBold, sans-serif;
          font-size: 1.8rem;

          .num {
            font-size: 2.5rem;
          }

          &:hover {
            cursor: pointer;
            background-color: rgba(0, 0, 0, .25);
          }
        }

        .profile-archivements-list-component-item--trophy-badge {
          position: absolute;
          top: 0.5rem;
          right: 0.75rem;
          width: 32px;
          height: 32px;
          background-color: #DAA520;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          color: white;
        }

        .profile-archivements-list-component-item--background {
          width: 100%;
          height: 100%;
          border-radius: 18px;
          opacity: 1;
          background-origin: border-box;
          background-clip: padding-box;
        }

        .profile-archivements-list-component-item--info {
          width: 100%;
          position: absolute;
          left: 0;
          bottom: 0;
          padding: 1rem;
          display: flex;
          flex-direction: row;
          align-items: center;
          background-color: rgba(0, 0, 0, .90);
          border-bottom-left-radius: 18px;
          border-bottom-right-radius: 18px;

          .profile-archivements-list-component-item--info-avatar {
            img {
              width: 32px;
              height: 32px;
              border-radius: 50%;
            }
          }

          .profile-archivements-list-component-item--info-inline {
            flex: 1;
            line-height: 1.2;
            margin-left: 0.5rem;
            color: white !important;
            width: calc(100% - 2rem - 32px - 0.5rem);

            .profile-archivements-list-component-item--info-inline-title {
              text-transform: uppercase;
              font-size: 1.25rem;
              font-family: Barlow-Bold, sans-serif;
              margin-bottom: 0.25rem;
              white-space: nowrap;
              overflow: hidden;
              display: block;
              text-overflow: ellipsis;
            }

            .profile-archivements-list-component-item--info-inline-archivement-wrap {
              display: flex;
              flex-direction: row;
              justify-content: space-between;
            }

            .profile-archivements-list-component-item--info-inline-archivement {
              flex: 1;
              display: flex;
              align-items: center;
              color: #00d1bf;
              text-transform: uppercase;
              font-size: 1.05rem;
              font-family: Barlow-Medium, sans-serif;

              &.is-pending {
                color: #d79e54;
              }

              .MuiSvgIcon-root {
                margin-right: 0.25rem;
                width: 18px;
                height: 18px;
              }

              svg.completed-icon {
                margin-top: 2px;
                margin-right: 0.25rem;
                width: 18px;
                height: 18px;
                fill: #00d1bf;
              }
            }

            .profile-archivements-list-component-item--info-inline-achieved {
              opacity: .5;
              display: flex;
              align-items: center;

              text-transform: uppercase;
              font-size: 1rem;
              font-family: Barlow-Light, sans-serif;

              .MuiSvgIcon-root {
                margin-right: 0.25rem;
                width: 18px;
                height: 18px;
              }

            }
          }
        }
      }

      .profile-archivements-list-component-item--new {
        padding: 0.25rem;
        border-radius: 12px;
        position: relative;

        .profile-archivements-list-component-item--trophy-badge {
          z-index: 123;
          position: absolute;
          top: 0.75rem;
          right: 0.75rem;
          width: 32px;
          height: 32px;
          background-color: #DAA520;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          color: white;

          img {

          }
        }

        .profile-archivements-list-component-item--new-image_box {
          height: 240px;
          border-radius: 12px;
          overflow: hidden;
          position: relative;

          .background-poster {
            -webkit-filter: blur(5px) contrast(125%) brightness(75%);
            filter: blur(5px) contrast(125%) brightness(75%);
            height: 100%;
            position: relative;
            background-repeat: no-repeat;
            background-position: 50%;
            background-size: 110% 110%;
          }

          img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 240px;
            object-fit: cover;
            z-index: 122;
          }
        }

        .profile-archivements-list-component-item--new-title {
          margin-top: 0;
          margin-bottom: 0;
          padding: 0 0.5rem;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
          font-size: 1.10rem;
          font-weight: initial;
          font-family: "Barlow-Medium", sans-serif;
        }

        .profile-archivements-list-component-item--info-inline {
          flex: 1;
          line-height: 1.2;
          color: white !important;
          padding: 0 0.5rem;
          padding-bottom: 0.5rem;

          .profile-archivements-list-component-item--info-inline-title {
            text-transform: uppercase;
            font-size: 1.10rem;
            font-family: Barlow-Light, sans-serif;
            margin-bottom: 0.25rem;
            white-space: nowrap;
            overflow: hidden;
            display: block;
            text-overflow: ellipsis;
          }

          .profile-archivements-list-component-item--info-inline-archivement-wrap {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
          }

          .profile-archivements-list-component-item--info-inline-archivement {
            flex: 1;
            display: flex;
            align-items: center;
            color: #00d1bf;
            text-transform: uppercase;
            font-size: 1rem;
            font-family: Barlow-Light, sans-serif;

            &.is-pending {
              color: #d79e54;
            }

            .MuiSvgIcon-root {
              margin-right: 0.25rem;
              width: 18px;
              height: 18px;
            }

            svg.completed-icon {
              margin-top: 2px;
              margin-right: 0.25rem;
              width: 18px;
              height: 18px;
              fill: #00d1bf;
            }
          }

          .profile-archivements-list-component-item--info-inline-achieved {
            opacity: .5;
            display: flex;
            align-items: center;

            text-transform: uppercase;
            font-size: 1rem;
            font-family: Barlow-Light, sans-serif;

            .MuiSvgIcon-root {
              margin-right: 0.25rem;
              width: 18px;
              height: 18px;
            }

          }
        }


      }

    }

  }
}

