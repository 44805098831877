@import '../../assets/scss/variables.scss';

.logo-box{
    img {
        image-rendering: -webkit-optimize-contrast;
    }
    .login-logo, .register-logo {
        width: 180px;
        height: auto;
        margin: 0 0 1.5rem;
    }

    .navbar-logo{
        width: 140px;
        height: auto;
    }

    .mobile-logo{
        width: 80%;
        max-width: 140px;
        height: auto;
    }

    .loader-logo{
        width: 54px;
        height: 54px;
    }

    .slogan-box{
        border-left: 1px solid #666666;
        height: 20px;
        padding-left: .5rem;
        margin-left: .5rem;

        .slogan{
            color: $gridContentLight !important;
            font-size: 12px;
            font-family: 'Barlow-Italic';
        }
        .version{
            font-size: 9px;
            font-family: 'Barlow-Italic';
            color: $secondaryColor !important;
        }
    }
}
