@import './../../../assets/scss/variables.scss';

#agenda-calendar-popup-root {
  .agenda-popup-cal {
    width: 400px;
    padding: 0.5rem;

    border-top-right-radius: 24px;
    border-top-left-radius: 24px;
    border: 1px solid #283654;
    border-bottom-color: transparent;

    z-index: 999;



    &.is-dark {
      border-color: $gridContentDark;
      background-color: $gridMainDark;
      -webkit-box-shadow: 0px -1px 20px 0px rgba(255, 255, 255, 0.25);
      -moz-box-shadow:    0px -1px 20px 0px rgba(255, 255, 255, 0.25);
      box-shadow:         0px -1px 20px 0px rgba(255, 255, 255, 0.25);
      .agenda-popup-cal--button {
        svg {
          path {
            stroke: white !important;
          }
        }
      }
    }

    &.is-light {
      background-color: #e7e7e7;
      -webkit-box-shadow: 0px -1px 20px 0px rgba(0, 0, 0, 0.25);
      -moz-box-shadow:    0px -1px 20px 0px rgba(0, 0, 0, 0.25);
      box-shadow:         0px -1px 20px 0px rgba(0, 0, 0, 0.25);
      .agenda-popup-cal--button {
        svg {
          path {
            stroke: #23304B !important;
          }
        }
      }
    }

    &.is-bottom {
      position: fixed;
      right: 3rem;
      bottom: 0;
    }

    .agenda-popup-cal--wrapper {
      .agenda-popup-cal--button {
        cursor: pointer;
        padding-top: 0.10rem;
        padding-bottom: 0.10rem;
        padding-left: 1rem;
        padding-right: 1rem;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .caption-label {
          font-family: Barlow-Medium, sans-serif;
          font-size: 1.5rem;
        }
      }
      .agenda-calendar {
        background-color: transparent !important;
        height: 0;

        &.is-visible {
          margin-top: 0.5rem;
          height: auto;
        }

      }
    }

  }
}
