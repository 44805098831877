@import '../../../assets/scss/variables.scss';

.register-form{
    width: 300px;
    .nick, .email, .password{
        margin: .7rem 0;
        border-color: $secondaryColor;
        border-radius: $radiusLarge;
        width: 100%;
        height: 50px;
        label{
            color: $secondaryColor !important;
            letter-spacing: 1.3px;
            font-size: 14px !important;
            &.Mui-error {
                color: $red !important;
            }
        }
        label+div{
            border-radius: $radiusMedium;
            height: 50px;
        }
        fieldset{
            border-color: $secondaryColor !important;
        }
        input{
            color: $secondaryColor;
            font-size: 14px !important;
        }
        .Mui-error {
            fieldset {
                border-color: $red !important;
            }
            input {
                color: $red;
            }
        }
    }
    .error{
        padding: .5rem;
        text-align: center;
        width: fit-content;
        border-radius: 4px;
        background: crimson;
        font-size: 14px;
        margin: 1rem 0 0;
    }
    .error-line {
        width: 100%;
        color: $red;
        font-size: 14px;
    }
    .submit-btn{
        margin: 1.5rem 0 !important;
        color: $theme-dark !important;
    }
}

@media only screen and (max-width: 600px){
    .register-form{
        width: 80%;
        max-width: 300px;
    }
}
