@import '../../assets/scss/variables.scss';

.not-found{
    flex: 1;
    height: 600px;
    *{ color: $secondaryColor;}
    .error-code{
        font-size: 120px;
        font-weight: 500;
        margin: 0;
    }
    .error-message{
        font-size: 36px;
        text-transform: capitalize;
        margin: 0;
        .icon{
            font-size: 36px;
            margin: -.2rem;
        }
    }
}
