@import '../../../assets/scss/variables.scss';

.latency-valorant-embed {
  flex: 1;
  align-content: flex-start;
  padding: 1rem;

  &.is-mobile {
    padding: 0;
  }

  @import "../LandingMain/fragments/Header/header";
  @import "./landing_valorant_menu";

  .latency-landing-container {
    flex: 1;
    flex-wrap: nowrap;
    overflow: hidden;


    .landing-cover-vlr-matches--subnav {
      justify-content: center;

      .MuiChip-avatarColorSecondary {
        background-color: transparent !important;
      }
    }


    .section {
      &.main {
        margin-top: 2rem;
      }

      &.is-top {
        display: grid;
        grid-template-columns: 1fr 0.4fr;
        gap: 1rem;
        margin-bottom: 1rem;
        &.is-mobile {
          grid-template-columns: 1fr;
        }
      }

      .embed-section {
        .embed-section-heading {
          color: white;
          font-size: 1.5rem;
          font-family: Barlow-Bold, sans-serif;
          text-transform: uppercase;
          padding: 0.25rem 0.5rem;
          border-bottom-color: #d75454;
          border-bottom-width: 4px;
          border-bottom-style: solid;
        }

        .embed-section-content {
          padding: 0.5rem 1rem;
        }

        &.is-regions {
          .embed-section-content {
            display: grid;
            grid-template-columns: repeat(5, 244px);
            gap: 1rem;

            .embed-region-box {
              height: 320px;
              position: relative;
              background-color: black;

              img {
                z-index: 300;
                width: 100%;
                height: 320px;
                object-fit: contain;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
              }

              .overlay-image {
                background-size: 100%;
                width: 100%;
                height: 320px;
                filter: blur(.0rem);
                opacity: .5;
              }

              .embed-region-name {
                z-index: 400;
                position: absolute;
                width: 100%;
                padding: 1rem;
                text-align: center;
                left: 0;
                bottom: 0;
                background-color: rgba(0,0,0,.5);
                font-size: 1.5rem;
                font-family: Barlow-Bold, sans-serif;
                text-transform: uppercase;
              }
            }
          }

          &.is-mobile {
            .embed-section-content {
              grid-template-columns: 1fr 1fr;
              .embed-region-box {
                height: 128px;
                .overlay-image {
                  height: 128px;
                }
                img {
                  height: 128px;
                }
              }
            }
          }

        }

        &.is-teams {
          .embed-section-heading {
            background-color: transparent;
          }
          .embed-section-content {
            padding-top: 1rem;
            padding-bottom: 1rem;
          }
        }

        &.is-leagues {
          .embed-section-content {
            .embed-leagues-component {
              display: grid;
              grid-template-columns: repeat( auto-fit, minmax(64px, 1fr) );
              gap: 0.5rem;
              .embed-league-item {
                background-color: $appBgDark;
                padding: 0.5rem;
                display: flex;
                flex-direction: row;
                align-items: center;
                &:hover {
                  background-color: $eventHoverDark;
                  cursor: pointer;
                }
                img {
                  width: 32px;
                  height: 32px;
                  margin-right: 0.5rem;
                }
                font-size: 1rem;
                font-family: Barlow-SemiBold, sans-serif;
              }
            }
          }

          &.is-mobile {
            .embed-section-content {
              
            }
          }

        }


      }


    }

  }

}
