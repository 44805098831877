@import '../../assets/scss/variables.scss';

.modal{
    border-radius: 0 !important;

    &.dark{background-color: $modalDark !important;}
    &.light{background-color: $white !important;}
    &.radius{border-radius: $radiusFull !important;}
    &.padd{padding: 1rem !important;}
}

@media only screen and (max-width: 600px){
    .modal{
        border-radius: 0 !important;

        &.dark{background-color: $modalDark !important;}
        &.light{background-color: $white !important;}
        &.radius{border-radius: 0 !important;}
        &.padd{padding: 1rem !important;}
    }
}
